import React, {useState, useEffect, Fragment} from 'react'
import {useDispatch, useSelector} from "react-redux"

// Material-UI Core
// import {makeStyles} from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'

// Material-UI Icon
import FolderIcon from '@mui/icons-material/Folder'

// Custom Component
import FileChip from './FileChip'

// Custom Style
import dropzoneContentStyle from "./styles/dropzoneContentStyle"
// import cubeTestReportDropzoneContentStyle from "../../styles/jss/components/plantMain/cubeTestReportDropzoneContentStyle"


// const useStyles = makeStyles(theme => cubeTestReportDropzoneContentStyle(theme))

const DropzoneContentEdit = props => {
	// const {disableUpload, open, downloadIcon, deleteIcon} = props
	const {open, enablePreview, enableUpload, enableDownload, enableDelete, onPreviewAction, onDownloadAction, onRemoveAction} = props

	// const classes = useStyles()
	const dispatch = useDispatch()
	
	// Redux Data
	// const noticeboardEditAttachmentRedux = useSelector(state => state.noticeboardEditAttachmentRedux)
	const existUploadAttachmentRedux = useSelector(state => state.existUploadAttachmentRedux)


	useEffect(() => {
	})


	return (

		// console.log("---------------------------------------------------------------------------------"),
		// console.log("DropzoneContentEdit.js"),
		// console.log("existUploadAttachmentRedux : ", existUploadAttachmentRedux),
		// console.log("---------------------------------------------------------------------------------"),

		<Fragment>
			<Box sx={dropzoneContentStyle.dropzoneArea}>

				<Grid container spacing={1}>
					{
						existUploadAttachmentRedux !== null && existUploadAttachmentRedux.length > 0?
							existUploadAttachmentRedux.map((file, index) => {
								// console.log("*** file.atth_seq : ", file.atth_seq)

								return (
									<Grid item xs={12} sm={12} lg={6}>
										<FileChip
											key={index}
											filename={file.filename}
											fileSize={file.file_size}
											fileExtension={file.extension}

											enablePreview={enablePreview}
											enableDownload={enableDownload}
											enableDelete={enableDelete}

											onPreviewAction={onPreviewAction}
											onDownloadAction={onDownloadAction(file.atth_seq)}
											onRemoveAction={onRemoveAction}
										/>
									</Grid>
								)
							})
						: <Box sx={dropzoneContentStyle.infoMessage}>No Attachment</Box>
					}
				</Grid>

				{
					enableUpload ? 
						<Box sx={dropzoneContentStyle.buttonArea}>
							<Tooltip title="Choose File">
								<Fab color="primary" size="small" aria-label="add_recipient" sx={dropzoneContentStyle.icon} onClick={open}>
									<FolderIcon />
								</Fab>
							</Tooltip>
						</Box>
					: ""
				}
				
			</Box>
		</Fragment>
	)
}

export default DropzoneContentEdit
