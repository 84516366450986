import axios from "axios"

const getLatestEformList = async () => {
	const getLatestEformList_api = "/form/top10"

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
			// "content-type": "multipart/form-data",
		}

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_EFORM_API_URL + getLatestEformList_api,
		})

		return result.data
	}
	catch (error) {
		if (error.response.status === 401) {
			window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
		}
		else {
			throw new Error(error)
		}
	}
}


export {
	getLatestEformList,
}