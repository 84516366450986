import React, {Fragment, useEffect, useState} from "react"

// MUI
import Grid from '@mui/material/Grid'

// Custom Components
import AppsCard from "./AppsCard"

// Custom Style
import systemAppsStyle from "./styles/systemAppsStyle"

// Fetch Data
import {getApplication} from "../../data_source/apps"


const SystemApps = () => {
	// Define State
	const [systemAppsList, setSystemAppsList] = useState(null)


	useEffect(() => {
		getApplication("SYSAPP").then(res => {
			setSystemAppsList(res.data)
		})
	}, [])

	return (
		// console.log("---------------------------------------------------------------------------------"),
		// console.log("SystemApps.js"),
		// console.log("System Apps List : ", systemAppsList),
		// console.log("---------------------------------------------------------------------------------"),

		<Fragment>
			<Grid container spacing={2}>
				{
					systemAppsList ?
						systemAppsList.map(list => {
							return (
								<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
									<AppsCard
										appId={list.appl_seq}
										icon={list.logo_path}
										appFullName={list.appl_name}
										appShortName={list.appl_short_name}
										networkType={list.network_type}
										browserType={list.browser_type}
									/>
								</Grid>
							)
						})
					: ""
				}
			</Grid>
		</Fragment>
	)
}

export default SystemApps
