import React, {Fragment} from "react"

// MUI
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'

// MUI Icon
import AppsIcon from '@mui/icons-material/Apps';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// Custom Icon
import ieIcon from "../../img/browser/ie.png"
import chromeIcon from "../../img/browser/chrome.png"
import internalIcon from "../../img/network/internal.png"
import externalIcon from "../../img/network/external.png"

// Custom Component
import LaunchApps from "../Public/LaunchApps"

// Custom Style
import appsCardStyle from './styles/appsCardStyle'

// Fetch Data
import {getApplicationProxy} from "../../data_source/apps"


const AppsCard = props => {
	const {appId, icon, appFullName, appShortName, networkType, browserType} = props

	const launchAppAction = appId => () => {
		LaunchApps(appId)
	}


	return (
		<Fragment>
			<List sx={appsCardStyle.card}>
				<ListItem>
					<ListItemAvatar>
						<Avatar sx={appsCardStyle.avatar}>
							<img src={icon} width="20" />
						</Avatar>
					</ListItemAvatar>

					<ListItemText primary={appShortName} secondary={appFullName} />

					<Tooltip title="Launch Apps">
						<OpenInNewIcon onClick={launchAppAction(appId)} sx={appsCardStyle.launchIcon} />
					</Tooltip>
				</ListItem>

				<Divider  sx={appsCardStyle.line} />

				<li>
					<Typography
						color="text.secondary"
						display="block"
						variant="caption"
						sx={appsCardStyle.icon}
					>
						<Stack direction="row" spacing={1}>
							{
								networkType === "ALL" ?
									<Tooltip title="Internet Access">
										<img src={externalIcon} height="22" width="22"/>
									</Tooltip>
								: networkType === "INTERNAL" ?
									<Tooltip title="Internal Only">
										<img src={internalIcon} height="22" width="22"/>
									</Tooltip>
								: ""
							}
	
							{
								browserType ===	"ALL" ? 
									<Stack direction="row" spacing={1}>
										<Tooltip title="Support IE">
											<img src={ieIcon} height="22" width="22"/>
										</Tooltip>
										<Tooltip title="Support Chrome">
											<img src={chromeIcon} height="22" width="22"/>
										</Tooltip>
									</Stack>
								: browserType === "IE_UNSUPPORTED" ?
									<Tooltip title="Support Chrome">
										<img src={chromeIcon} height="22" width="22"/>
									</Tooltip>
								: browserType === "IE_ONLY" ? 
									<Tooltip title="Support IE Only">
										<img src={ieIcon} height="22" width="22"/>
									</Tooltip>
								: ""
							}
						</Stack>
					</Typography>
				</li>
			</List>
		</Fragment>
	)
}

export default AppsCard
