import globalStyle from "../../../styles/globalStyle"

const myNoticeListStyle = {
	title: {
		display: "flex",
		alignItems: "center",
		// flex: '1 1 100%', 
		color: globalStyle.grayColor[10],
		fontSize: 20,
		// paddingTop: 3
		
	},

	titleImage: {
		display: "flex",
		alignItems: "center",
		paddingRight: 1
	},

	container: {
		height: "73vh"
	},

	// content: {
		
	// 	width: '100%', 
	// 	height: "83vh",
	// 	mb: 2 
	// },

	toolIcon: {
		// paddingTop: 3
	},

	icon: {
		background: globalStyle.oceanBlueColor[8],

		'&:hover': {
			backgroundColor: globalStyle.oceanBlueColor[12],
		},
	},


	pagination: {
		// display: "flex",
		// alignSelf: "flex-end",

		paddingTop: 1,
		paddingBottom: 1,
		paddingLeft: 1,
		paddingRight: 1
	},



	
	
	

}


export default myNoticeListStyle  