import React, {Fragment, useState} from "react"

// Material UI
import TextField from '@mui/material/TextField'

// Custom Components

// Custom Style
import createMyListFormStyle from './styles/createMyListFormStyle'


const CreateMyListForm = props => {
	const {myListName, setMyListName} = props

	// Define State
	// const [myListName, setMyListName] = useState("")

	// Handle Form Input
	const myListNameHandleChange = event => {
		const {value} = event.target

		setMyListName(value)

		// dispatch({
		// 	type: "NEW_NOTICE_CONTENT",
		// 	data: res
		// })

		// validate({...submissionCreateValue, values: res})
	}


	return (
		<Fragment>
			<TextField 
				id="name"
				name="name"
				label="My List Name"
				variant="outlined"
				required
				fullWidth={true}
				size="small"

				onChange={myListNameHandleChange}

				value={myListName || ""}

				// onChange={handleSubjectChange(
				// 	validationErrors.circ_subject &&
				// 	  validationErrors.circ_subject !== ""
				// 	  ? true
				// 	  : false
				//   )}

				//   error={
				// 	validationErrors.circ_subject &&
				// 	validationErrors.circ_subject !== ""
				// 	  ? true
				// 	  : false
				//   }

				//   helperText={validationErrors.circ_subject}
			/>	
		</Fragment>
	)
}

export default CreateMyListForm
