import React, { useState, useContext, useEffect } from "react";
import {
  AppBar,
  Box,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import RoomBookingContext from "../RoomBookingContext";
import CustomBackdrop from "../CustomBackdrop";
import BookingView from "../BookingView";
import Room from "./Room";
import { getRoomListDetails } from "../../../data_source/roombook";
import Alert from "../../Alert/Alert";

const theme = createTheme({
  typography: {
    fontFamily: "Open Sans",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Overview = () => {
  const mobileView = useMediaQuery("(max-width:600px)");
  const { bookingDialog, bookingRoom, bookingAll } =
    useContext(RoomBookingContext);
  const { dialogOpen, setDialogOpen } = bookingDialog;
  const { setSelectedRoom } = bookingRoom;
  const { availableRoom, setAvailableRoom } = bookingAll;
  // Define Alert dialog state
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    title: "",
    content: "",
  });

  const groupByFloor = (data) => {
    const distinctFloors = data
      .map((room) => room.floor)
      .filter((obj, index, self) => self.indexOf(obj) === index);

    return distinctFloors.map((f) => ({
      floor: f,
      rooms: data.filter((room) => room.floor === f),
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      await getRoomListDetails()
        .then((u) => {
          setAvailableRoom(groupByFloor(u.data));
        })
        .catch((error) => {
          console.log(error);
          buildAlert({
            // Alert Type: success, info, warning, error
            type: "error",
            title: "Error Alert",
            content: `${error.message}`,
          });
        });
    };
    fetchData();
  }, []);

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedRoom(null);
  };

  // Build Alert
  const buildAlert = (data) => {
    setAlertData({
      type: data.type,
      title: data.title,
      content: data.content,
    });
    setAlertOpen(true);
  };

  const alertHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  if (availableRoom === null) {
    return <CustomBackdrop text="Fetching" />;
  }
  return (
    <ThemeProvider theme={theme}>
      <Alert
        open={alertOpen}
        alertType={alertData.type}
        alertTitle={alertData.title}
        alertContent={alertData.content}
        handleClose={alertHandleClose}
      />

      <Box
        sx={{
          position: "fixed",
          width: "100%",
          zIndex: 20,
          display: "flex",
          alignItems: "center",
          height: 64,
          backgroundColor: "#20cec0",
          boxShadow:
            "rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px",
        }}
      >
        <Container maxWidth="xl">
          <Typography
            sx={{
              letterSpacing: 1,
              fontSize: "20px",
              color: "white",
              textAlign: `${mobileView ? "center" : "left"}`,
            }}
          >
            Room Booking System
          </Typography>
        </Container>
      </Box>
      <Box sx={{ pt: 8 }}></Box>
      <Container maxWidth="xl" sx={{ p: 2, overflowY: "auto" }}>
        {availableRoom.map((grouped, index) => (
          <Box key={index}>
            <Divider>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  letterSpacing: "8px",
                  color: "#646868",
                }}
              >
                {grouped.floor}
              </Typography>
            </Divider>
            <Grid container key={index}>
              {grouped.rooms.map((room, i) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={i}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    p: 1,
                  }}
                >
                  <Room room={room} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Container>

      {dialogOpen && (
        <Dialog
          fullScreen
          open={true}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative", backgroundColor: "#20cec0" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Room Booking
              </Typography>
            </Toolbar>
          </AppBar>
          <BookingView />
        </Dialog>
      )}
    </ThemeProvider>
  );
};

export default Overview;
