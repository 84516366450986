import React, {Fragment, useState} from "react"

// Material UI
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Collapse from "@mui/material/Collapse"
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemButton from '@mui/material/ListItemButton'

// Material Icon
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"

// Custom Components
import NoticeboardList from "./NoticeboardList"
import MyNoticeList from "./MyNoticeList"
import MyListList from "./MyListList"

// Custom Style
import noticeboardStyle from './styles/noticeboardStyle'


const Noticeboard = () => {
	// Define State
	const [myNoticeListItemOpen, setMyNoticeListItemOpen] = useState(true)
	const [DisplayList, setDisplayList] = useState(<NoticeboardList />)


	const myNoticeListItemHandleClick = () => {
		setMyNoticeListItemOpen(!myNoticeListItemOpen)
	}


	const listItemHandleClick = contentElement  => {
		setDisplayList(contentElement)
	}


	return (
		<Fragment>
			<Grid container spacing={1}>

				<Grid item xs={12} sm={4} lg={2}>
					<Paper elevation={2} square sx={noticeboardStyle.explorer}>
						
						<List>
							<ListItem disablePadding>
								<ListItemButton onClick={() => listItemHandleClick(<NoticeboardList />)}>
										<ListItemIcon>
											<AssignmentOutlinedIcon />
										</ListItemIcon>
									<ListItemText primary="Notice Board" />
								</ListItemButton>
							</ListItem>

							<ListItem disablePadding>
								<ListItemButton onClick={myNoticeListItemHandleClick}>
									<ListItemIcon>
										<AssignmentOutlinedIcon />
									</ListItemIcon>
									<ListItemText primary="My Notice" />
								</ListItemButton>
								{myNoticeListItemOpen ? <ExpandLess /> : <ExpandMore />}
							</ListItem>

							<Collapse in={myNoticeListItemOpen} timeout="auto" unmountOnExit>
								<List component="div" disablePadding onClick={() => listItemHandleClick(<MyNoticeList />)}>
									<ListItemButton sx={{pl: 4}}>
										<ListItemIcon>
											<AssignmentTurnedInOutlinedIcon />
										</ListItemIcon>
										<ListItemText primary="My Notice" />
									</ListItemButton>
								</List>

								<List component="div" disablePadding>
									<ListItemButton sx={{pl: 4}} onClick={() => listItemHandleClick(<MyListList />)}>
										<ListItemIcon>
											<AssignmentIndOutlinedIcon />
										</ListItemIcon>
										<ListItemText primary="My List" />
									</ListItemButton>
								</List>
							</Collapse>
						</List>

					</Paper>
				</Grid>

				<Grid item xs={12} sm={8} lg={10}>
					<Paper square sx={noticeboardStyle.content}>
						{DisplayList ? DisplayList : ""}
					</Paper>
				</Grid>

			</Grid>
		</Fragment>
	)
}

export default Noticeboard
