import React, { Fragment, useState, useEffect} from "react"
import {useNavigate} from "react-router-dom"

// Custom Component
import Alert from '../Alert/Alert'
import Signout from './Signout'

// Fetch Data
import {oidcSignIn, getUserData} from "../../data_source/signIn"


const Callback = props => {
	const {code, state, error} = props
	const navigate = useNavigate()

	// Alert Define
	const [alertOpen, setAlertOpen] = useState(false)
	const [alertData, setAlertData] = useState({
		type: "",
		title: "",
		content: ""
	})

	// Build Alert 
	const buildAlert = (data) => {
		setAlertData({
			type: data.type,
			title: data.title,
			content: data.content
		})
		setAlertOpen(true)
	}

	const saveLoginInfo = async (oidcResult, userData) => {
		localStorage.setItem("LOGGED_IN", true)
		localStorage.setItem("TOKEN_TYPE", oidcResult.data.token_type)
		localStorage.setItem("ACCESS_TOKEN", oidcResult.data.access_token)
		localStorage.setItem("REFRESH_TOKEN", oidcResult.data.refresh_token)
		localStorage.setItem("USER_SEQ", userData.data.user_seq)
		localStorage.setItem("USERNAME", userData.data.username)
		localStorage.setItem("EMPLOYEE_ID", userData.data.employee_id)
		localStorage.setItem("FULLNAME_ENG", userData.data.fullname_eng)
		localStorage.setItem("MENU", userData.data.menu)
		localStorage.setItem("ROLE", userData.data.role)
	}

	const navigateMain = () => {
		navigate({
			pathname: "/",
		})
	}

	const navigateRoot = () => {
		navigate({
			pathname: "/signin",
		})
	}
	

	useEffect(() => {
		if (code) {
			oidcSignIn(code, state).then(oidcResult => {
				if (oidcResult.status) {
					getUserData(oidcResult.data.token_type + " " + oidcResult.data.access_token).then(userData => {
						saveLoginInfo(oidcResult, userData).then(() => {
							navigateMain()
						})
					})
				}
				else if (oidcResult.status === "error") {
					buildAlert({
						// Alert Type: success, info, warning, error
						type: "error",
						title: "Error Alert",
						content: oidcResult.message
					})
				}
			})
		}
		else if (error) {
			setTimeout(() => {
				Signout()
				navigateRoot()
			}, 2000)

			buildAlert({
				// Alert Type: success, info, warning, error
				type: "error",
				title: "Error Alert",
				content: error
			})
		}

	}, [])


	return (
		<Fragment>
			<div>
				<Alert
					open={alertOpen}
					alertType={alertData.type}
					alertTitle={alertData.title}
					alertContent={alertData.content}
					handleClose={() => setAlertOpen(false)}
				/>
			</div>
		</Fragment>
	)
}


export default Callback
