import React, { useContext, useState } from "react";
import { Box, Grid } from "@mui/material";
import RoomBookingContext from "../RoomBookingContext";
import RoomSelection from "./RoomSelection";
import BookingInfo from "./BookingInfo";
import DateSelect from "./DateSelect";
import TimePeriod from "./TimePeriod";
import Alert from "../../Alert/Alert";
import CustomBackdrop from "../CustomBackdrop";
import {
  insertRoomBookingRecord,
  deleteBookedRoom,
} from "../../../data_source/roombook";
import { dateToDisplay } from "../utilFunc";
import ActionResponse from "../ActionResponse";

const BookingView = () => {
  const { bookingRoom } = useContext(RoomBookingContext);
  const { selectedRoom } = bookingRoom;
  const [dateValue, setDateValue] = useState(new Date());
  const [selectedTimePeriod, setSelectedTimePeriod] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  // Define Alert dialog state
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    title: "",
    content: "",
  });
  const [responseOpen, setResponseOpen] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const handleSave = async (extension, remark) => {
    setLoading(true);
    const data = {
      roombook_seq: selectedRoom.roombook_seq,
      records: selectedTimePeriod.map((tp) => ({
        config_seq: tp.config_seq,
        time_from: tp.time_from,
        time_to: tp.time_to,
        book_date: dateToDisplay(dateValue),
        extension: extension,
        remark: remark,
        english_name: localStorage.getItem("FULLNAME_ENG"),
        chinese_name: "",
      })),
    };

    await insertRoomBookingRecord(data)
      .then((res) => {
        setResponseOpen(true);
        setResponseData(res.data);
      })
      .catch((error) => {
        // buildAlert({
        //   // Alert Type: success, info, warning, error
        //   type: "error",
        //   title: "Error Alert",
        //   content: `${error.message}`,
        // });
        setResponseOpen(true);
        setResponseData({
          success: [],
          fail: [
            {
              message:
                "Some selected time periods have been reserved. Please try again.",
            },
          ],
        });
      });

    setLoading(false);
    setFlag((prev) => !prev);
  };

  const handleDelete = async () => {
    setLoading(true);
    const data = selectedTimePeriod.map((tp) => ({
      roombook_seq: selectedRoom.roombook_seq,
      config_seq: tp.config_seq,
      time_from: tp.time_from,
      time_to: tp.time_to,
      book_date: dateToDisplay(dateValue),
    }));

    await deleteBookedRoom(data)
      .then((res) => {
        setResponseOpen(true);
        setResponseData(res.data);
      })
      .catch((error) => {
        buildAlert({
          // Alert Type: success, info, warning, error
          type: "error",
          title: "Error Alert",
          content: `${error.message}`,
        });
      });

    setLoading(false);
    setFlag((prev) => !prev);
  };

  // Build Alert
  const buildAlert = (data) => {
    setAlertData({
      type: data.type,
      title: data.title,
      content: data.content,
    });
    setAlertOpen(true);
  };

  const alertHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  if (loading) {
    return <CustomBackdrop text="Processing" />;
  }
  return (
    <>
      <Alert
        open={alertOpen}
        alertType={alertData.type}
        alertTitle={alertData.title}
        alertContent={alertData.content}
        handleClose={alertHandleClose}
      />
      <ActionResponse
        responseOpen={responseOpen}
        setResponseOpen={setResponseOpen}
        responseData={responseData}
        setResponseData={setResponseData}
      />
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ flexBasis: "auto" }}>
          <RoomSelection />
        </Box>
        <Box
          sx={{
            flex: 1,
            p: 3,
            pb: 6,
            height: "calc(100vh - 64px - (3 + 6) * 8px)",
            overflowY: "auto",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} lg={4}>
              <DateSelect dateValue={dateValue} setDateValue={setDateValue} />
            </Grid>

            <Grid item xs={12} md={7} lg={8}>
              <TimePeriod
                dateValue={dateValue}
                setSelectedTimePeriod={setSelectedTimePeriod}
                flag={flag}
              />
            </Grid>

            <Grid item xs={12}>
              <BookingInfo
                dateValue={dateValue}
                handleSave={handleSave}
                handleDelete={handleDelete}
                setFlag={setFlag}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default BookingView;
