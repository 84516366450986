import axios from "axios"


const getUserOptionList = async () => {
	const getUserOptionList_api = "/employee/filtering_attributes"

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_USERSERVICE_API_URL + getUserOptionList_api,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


const getMyList = async () => {
	const getMyList_api = "/mylist"
	let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		// params.append("user_seq", localStorage.getItem("USER_SEQ"))

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + getMyList_api,
			params: params,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


const getMyListDetail = async listSeq => {
	const getMyListDetail_api = "/list/details"
	let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		params.append("list_seq", listSeq)

		// if (Array.isArray(listSeq)) {
		// 	listSeq.map(res => {
		// 		params.append("list_seq", res)
		// 	})
		// }
		// else {
		// 	params.append("list_seq", listSeq)
		// }

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + getMyListDetail_api,
			params: params,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


const getSearchUserList = async parameters => {
	const getSearchUserList_api = "/user/search_users"
	let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		parameters.company_code.map(res => {
			params.append("company_code", res)
		})

		parameters.site_id.map(res => {
			params.append("site_id", res)
		})

		parameters.section_id.map(res => {
			params.append("section_id", res)
		})

		parameters.grade_code.map(res => {
			params.append("grade_code", res)
		})

		params.append("fullname", parameters.fullname)
		params.append("username", parameters.username)
		params.append("email", parameters.email)

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_USERSERVICE_API_URL + getSearchUserList_api,
			params: params,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


const createMyList = async (params) => {
	const createMyList_api = "/list"
	// const formData = new FormData()

	// formData.append("json", JSON.stringify(params))

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
			// "content-type": "multipart/form-data",
		}

		const result = await axios({
			headers: headers,
			method: "post",
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + createMyList_api,
			data: params
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


const updateMyList = async (listSeq, body) => {
	const createMyList_api = "/list"
	let params = new URLSearchParams()
	
	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		params.append("list_seq", listSeq)

		const result = await axios({
			headers: headers,
			method: "put",
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + createMyList_api,
			params: params,
			data: body
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


export {
	getUserOptionList,
	getMyList,
	getMyListDetail,
	getSearchUserList,
	createMyList,
	updateMyList
}