import {combineReducers} from "redux"

import {
	recipientUserSeqReducer,
	recipientUserRecordReducer
} from "./userReducer"

import {
	newUploadAttachmentReducer,
	existUploadAttachmentReducer
} from "./fileAttachmentReducer"


import {
	newNoticeContentReducer
} from "./noticeboardReducer"

import {
	createInfoDataReducer,

	// infoItemResultReducer
} from "./informationReducer"


const rootReducer = combineReducers({
	// User
	recipientUserSeqRedux: recipientUserSeqReducer,
	recipientUserRecordRedux: recipientUserRecordReducer,

	// File Attachment
	newUploadAttachmentRedux: newUploadAttachmentReducer,
	existUploadAttachmentRedux: existUploadAttachmentReducer,

	// Noticeboard
	newNoticeContentRedux: newNoticeContentReducer,

	// Information
	createInfoDataRedux: createInfoDataReducer,

	// infoItemResultRedux: infoItemResultReducer
})

export default rootReducer
