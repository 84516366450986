import React, { useState, useContext } from "react";
import { Box, Grid, TextField, Stack, Button } from "@mui/material";
import DataFieldDisplay from "../../../DataFieldDisplay";
import RoomBookingContext from "../../../RoomBookingContext";
import ActionButton from "../../../ActionButton/ActionButton";
import NumberFormat from "react-number-format";

const InfoSection = ({ handleSave, period }) => {
  const { bookingRoom } = useContext(RoomBookingContext);
  const { selectedRoom } = bookingRoom;
  const [extension, setExtension] = useState("");
  const [remark, setRemark] = useState("");
  const [validateExtensionMsg, setValidateExtensionMsg] = useState("");

  const handleRemark = (e) => {
    setRemark(e.target.value);
  };

  const handleValueChange = (values) => {
    // const { floatValue } = values;
    // setExtension(floatValue === undefined ? "" : floatValue);
    const { value } = values;
    setExtension(value === undefined ? "" : value);
  };

  const validate = () => {
    if (extension === "") {
      setValidateExtensionMsg("Required");
      return false;
    }
    // else if (extension.toString().length !== 4) {
    //   setValidateExtensionMsg("Extension needs to be 4 digits");
    //   return false;
    // }
    setValidateExtensionMsg("");
    return true;
  };

  return (
    <Box sx={{ p: 3, py: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <DataFieldDisplay label={"Floor"} value={selectedRoom.floor} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <DataFieldDisplay
            label={"Venue"}
            value={`${selectedRoom.name} - (Extension: ${selectedRoom.extension})`}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <DataFieldDisplay label={"Capacity"} value={selectedRoom.capacity} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <DataFieldDisplay
            label={"Equipment"}
            value={selectedRoom.equipment.map((equip, index) => (
              <Box
                component="li"
                key={index}
                sx={{
                  textTransform: "capitalize",
                  m: 0,
                  listStyleType: "decimal",
                }}
              >
                {equip}
              </Box>
            ))}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} lg={3}>
          <DataFieldDisplay label={"Period"} value={period} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <DataFieldDisplay
            label={"Booking User"}
            value={localStorage.getItem("FULLNAME_ENG")}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6} lg={4}>
          <NumberFormat
            value={extension}
            customInput={TextField}
            onValueChange={handleValueChange}
            size="small"
            // format="# # # #"
            label="Extension"
            // mask={"_"}
            required
            error={validateExtensionMsg !== ""}
            helperText={validateExtensionMsg}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <TextField
            sx={{ width: "100%" }}
            id="standard-multiline-static"
            label="Remark"
            multiline
            rows={3}
            value={remark}
            size="small"
            onChange={handleRemark}
          />
        </Grid>
      </Grid>
      <Stack direction={"row"} spacing={1} sx={{ mt: 2 }}>
        <ActionButton
          title="Book"
          content="Are you sure to book the selected time period?"
          actionFunc={() => handleSave(extension, remark)}
          validateFunc={validate}
        />
      </Stack>
    </Box>
  );
};

export default InfoSection;
