import React, {Fragment, useState, useEffect} from "react"

// MUI
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'

// MUI Icon
import ManageSearchIcon from '@mui/icons-material/ManageSearch'

// Custom Component
import UseLoading from "../Loading/UseLoading"

// Custom Style
import userListOptionStyle from './styles/userListOptionStyle'

// Fetch Data
import {getMyList, getMyListDetail} from "../../data_source/users"


const UserListOption = props => {
	const {setUserResult} = props

	// Define State
	const [getOptionLoading, getOptionShowLoading, getOptionHiddenLoading] = UseLoading()
	const [getUserLoading, getUserShowLoading, getUserHiddenLoading] = UseLoading()

	// Define State
	const [myList, setMyList] = useState(null)
	const [listOptionSelected, setListOptionSelected] = useState("")


	// Create JSON format data for Table
	const createData = (id, user_seq, eng_name, chi_name, username, email, detail) => {
		return {id, user_seq, eng_name, chi_name, username, email, detail}
	}

	// const createData = (id, eng_name, chi_name, site, section, grade, position) => {
	// 	return {id, eng_name, chi_name, site, section, grade, position}
	// }


	const clearAllOption = () => {
		setListOptionSelected("")
	}


	// Action
	const optionSelectAction = event => {
		const {value} = event.target

		setListOptionSelected(value)
	}


	const showUserListAction = () => {
		getUserShowLoading()
		getMyListDetail(listOptionSelected).then(lists => {
			let createDataList = []

			lists.data.map(list => {
				// createDataList = [...createDataList, createData(list.user_seq, list.fullname_eng, list.fullname_chi, list.sites, list.section_name, list.grade_name, list.position_name_eng)]



				let detailString = ""

				list.employee_details.map((detail, index) => {
					detailString = detailString + `Company:  ${detail.company_name ? detail.company_name : "--"}, Site:  ${detail.sas_site_name ? detail.sas_site_name : "--"}, Section: ${detail.section_name ? detail.section_name : "--"}, Grade: ${detail.grade_name ? detail.grade_name : "--"}, Position: ${detail.position_name_eng ? detail.position_name_eng : "--"}\n`
				})

				createDataList = [...createDataList, createData(list.user_seq, list.user_seq, list.fullname_eng, list.fullname_chi, list.username, list.email, detailString)]
			})

			setUserResult(createDataList)
			getUserHiddenLoading()
		})
	}


	useEffect(() => {
		getOptionShowLoading()
		getMyList().then(lists => {
			setMyList(lists.data)
			getOptionHiddenLoading()
		})
	}, [])


	return (
		// console.log("--------------------------------------------------------"),
		// console.log("[UserListOption.js]"),
		// console.log("myList : ", myList),
		// console.log("listOptionSelected : ", listOptionSelected),
		// console.log("--------------------------------------------------------"),


		<Fragment>
			<Box sx={userListOptionStyle.optionArea}>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={12} lg={12}>
						<Box sx={userListOptionStyle.optionBtnArea}>
							<Button variant="contained" size="small" onClick={clearAllOption} sx={userListOptionStyle.normalBtnStyle}>Clear Selectioin</Button>
						</Box>

						<FormControl fullWidth>
							<InputLabel id="select-label">My List</InputLabel>
							<Select
								id="select"
								labelId="select-label"
								label="My List"
								value={listOptionSelected || ""}
								onChange={optionSelectAction}
							>
								{
									myList ?
										myList.map(list => (
											<MenuItem key={list.list_seq} value={list.list_seq}>{list.name}</MenuItem>
										))
									: ""
								}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={12} lg={12} sx={userListOptionStyle.optionToolArea}>
						<Tooltip title="Find Users">
							<Fab color="primary" size="small" aria-label="add" sx={userListOptionStyle.normalIconStyle} onClick={showUserListAction}>
								<ManageSearchIcon />
							</Fab>
						</Tooltip>
					</Grid>

					<Grid item xs={12} sm={12} lg={12} sx={userListOptionStyle.optionToolArea}>
						{getUserLoading}
					</Grid>
				</Grid>
			</Box>
		</Fragment>
	)
}

export default UserListOption
