import Overview from "./Overview";
import RoomBookingProvider from "./RoomBookingProvider";
import WebFont from "webfontloader";
import { useEffect } from "react";

const RoomBook = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Open Sans"],
      },
    });
  }, []);

  return (
    <RoomBookingProvider>
      <Overview />
    </RoomBookingProvider>
  );
};
export default RoomBook;
