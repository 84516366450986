import React, {Fragment, useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {format, parseISO} from "date-fns"
import PropTypes from 'prop-types'

// Material UI
import {styled, alpha} from "@mui/material/styles"
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Fab from '@mui/material/Fab'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'

import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'

// Material utils
import {visuallyHidden} from '@mui/utils'

// Material Icon
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

// Customer Components
import Alert from "../Alert/Alert"
import ConfirmDialog from "../Dialog/ConfirmDialog"
import FullScreenDialog from "../Dialog/FullScreenDialog"
import NormalDialog from "../Dialog/NormalDialog"
// import NoticeCreate from "./NoticeCreate"
// import NoticeDetail from "./NoticeDetail"
import InformationContent from "./InformationContent"
import InformationForm from "./InformationForm"

// Customer Icon
import noticeBoardIcon from "../../img/icon-noticeboard.png"

// Custom Style
import globalStyle from "../../styles/globalStyle"
import informationListStyle from './styles/informationListStyle'

// Fetch Data
import {createInformation, createDraftInformation, saveEditInformation, publishDraftInformation, getInfoContent, removeInformation} from "../../data_source/information"


// Define Custom TableCell Style
const StyledTableCell = styled(TableCell)(({theme}) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: globalStyle.dodgerBlueColor[8],
		color: theme.palette.common.white,
		fontSize: 16,
		fontWeight: "bold"
	},

	[`&.${tableCellClasses.body}`]: {
	  fontSize: 14
	}
}))

// Define Custom TableRow Style
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: alpha(globalStyle.dodgerBlueColor[0], 0.5)
	},
  
	"td, th": {
	  border: 0
	}
}))


const InformationList = props => {
	const {layerSelected, setRefreshLayerList} = props

	const dispatch = useDispatch()

	// Define Redux
	const createInfoDataRedux = useSelector(state => state.createInfoDataRedux)
	const newUploadAttachmentRedux = useSelector(state => state.newUploadAttachmentRedux)
	const existUploadAttachmentRedux = useSelector(state => state.existUploadAttachmentRedux)
	
	// Define State
	const [order, setOrder] = useState('desc')   // asc, desc
	const [orderBy, setOrderBy] = useState('date')
	const [page, setPage] = useState(1)
	const [rowsPerPage, setRowsPerPage] = useState(20)
	
	const [formType, setFormType] = useState("")
	const [formInfoStatus, setFormInfoStatus] = useState("")
	const [infoSeq, setInfoSeq] = useState("")
	const [tableContentList, setTableContentList] = useState(null)
	const [refreshInfoContentList, setRefreshInfoContentList] = useState("")

	const [createInfoDialogOpen, setCreateInfoDialogOpen] = useState(false)
	const [detailInfoDialogOpen, setDetailInfoDialogOpen] = useState(false)

	// Define Alert dialog state
	const [alertOpen, setAlertOpen] = useState(false)
	const [alertData, setAlertData] = useState({
		type: "",
		title: "",
		content: ""
	})

	// Define Confirm Dialog
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
	const [confirmDialogData, setConfirmDialogData] = useState({
		dialogTitle: "",
		dialogContent: "",
		confirmAction: "",
		cancelAction: ""
	})


	// Build Alert 
	const buildAlert = (data) => {
		setAlertData({
			type: data.type,
			title: data.title,
			content: data.content
		})
		setAlertOpen(true)
	}

	const alertHandleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setAlertOpen(false)
	}


	// Build Confirm dialog
	const confirmDialogHandleOpen = (data) => {
		setConfirmDialogData({
			dialogTitle: data.dialogTitle,
			dialogContent: data.dialogContent,
			confirmAction: data.confirmAction,
			cancelAction: data.cancelAction
		})

		setConfirmDialogOpen(true)
	}

	const confirmDialogHandleClose = () => {
		setConfirmDialogOpen(false)
	}



	// Define table title
	const title = "Information"

	// Define table header column
	const headCells = [
		{
			id: "toolbar",
			numeric: false,
			disablePadding: true,
			label: "",
		},
		{
			id: "title",
			numeric: false,
			disablePadding: true,
			label: "Title",
		},
		{
			id: 'sender',
			numeric: false,
			disablePadding: false,
			label: 'Sender',
		},
		{
			id: 'date',
			numeric: false,
			disablePadding: false,
			label: 'Date',
		}
	]

	// Generate table rows
	const createData = (id, type, canEdit, canDelete, infoStatus, title, content, sender, date) => {
		return {id, type, "can_edit": canEdit, "can_delete": canDelete, "info_status" : infoStatus , title, content, sender, date}
	}


	// Table Toolbar
	const EnhancedTableToolbar = props => {
		return (
			<Fragment>
				<Toolbar>
					<Box sx={{flexGrow: 1}}>
						<Typography	variant="body1"	sx={informationListStyle.title}>
							<Box component='span' sx={informationListStyle.titleImage}>
								<img src={noticeBoardIcon} height="28" width="28"/>	
							</Box>
							{title}
						</Typography>
					</Box>

					<Tooltip title="Create Notice">
						<Fab color="primary" size="small" aria-label="add" sx={informationListStyle.icon} onClick={openInformationForm("CREATE", null)}>
							<AddOutlinedIcon />
						</Fab>
					</Tooltip>
				</Toolbar>
			</Fragment>
		)
	}


	// Table Header
	const EnhancedTableHead = props => {
		const {order, orderBy, onRequestSort} = props

		const createSortHandler = property => event => {
			onRequestSort(event, property)
		}

		return (
			<TableHead>
				<TableRow>
					{
						headCells.map(headCell => (
							<StyledTableCell
								key={headCell.id}
								align={headCell.numeric ? 'right' : 'left'}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{
										orderBy === headCell.id ? (
											<Box component="span" sx={visuallyHidden}>
												{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
											</Box>
										) : null
									}
								</TableSortLabel>
							</StyledTableCell>
						))
					}
				</TableRow>
			</TableHead>
		)
	}

	EnhancedTableHead.propTypes = {
		order: PropTypes.oneOf(['asc', 'desc']).isRequired,
		orderBy: PropTypes.string.isRequired,
		onRequestSort: PropTypes.func.isRequired
	}


	const descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}

		if (b[orderBy] > a[orderBy]) {
			return 1
		}

		return 0
	}
	

	const getComparator = (order, orderBy) => {
		return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
	}


	const sortRequestHandle = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

  
	const pageChangeHandle = (event, newPage) => {
		setPage(newPage);
	}



	// Handle full screen dialog open and close
	const detailInfoDialogHandleOpen = () => {
		setDetailInfoDialogOpen(true);
	}
	
	const detailInfoDialogHandleClose = () => {
		setDetailInfoDialogOpen(false)
		
	}


	const createInfoDialogHandleOpen = () => {
		setCreateInfoDialogOpen(true);
	}
	
	const createInfoDialogHandleClose = () => {
		setCreateInfoDialogOpen(false)

		dispatch({
			type: "NEW_INFORMATION_CONTENT",
			data: null
		})

		dispatch({
			type: "NEW_UPLOAD_ATTACHMENT",
			data: []
		})

		dispatch({
			type: "EXIST_UPLOAD_ATTACHMENT",
			data: []
		})
	}



	// Handle confirm dialog open and close
	const createInfoConfirmHandleOpen = () => {
		confirmDialogHandleOpen({
			dialogTitle: "Publish Information",
			dialogContent: "Are you sure to publish the information item.",
			confirmAction: createInfoAction,
			cancelAction: createInfoConfirmHandleClose
		})
	}

	const createInfoConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}


	const createDraftInfoConfirmHandleOpen = () => {
		confirmDialogHandleOpen({
			dialogTitle: "Save Draft",
			dialogContent: "Are you sure to save the information item to draft.",
			confirmAction: createDraftInfoAction,
			cancelAction: createDraftInfoConfirmHandleClose
		})
	}

	const createDraftInfoConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}


	const saveEditInfoConfirmHandleOpen = () => {
		confirmDialogHandleOpen({
			dialogTitle: "Save Change",
			dialogContent: "Are you sure to update the change to information item.",
			confirmAction: saveEditInfoAction,
			cancelAction: saveEditInfoConfirmHandleClose
		})
	}

	const saveEditInfoConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}


	const publishDraftInfoConfirmHandleOpen = () => {
		confirmDialogHandleOpen({
			dialogTitle: "Publish Information ",
			dialogContent: "Are you sure to publish the draft information item.",
			confirmAction: publishDraftInfoAction,
			cancelAction: publishDraftInfoConfirmHandleClose
		})
	}

	const publishDraftInfoConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}

	
	const removeInfoConfirmHandleOpen = (event, infoSeq) => {
		confirmDialogHandleOpen({
			dialogTitle: "Remove Information",
			dialogContent: "Are you sure to remove the Information.",
			confirmAction: removeInformationAction(infoSeq),
			cancelAction: removeInfoConfirmHandleClose
		})
	}

	const removeInfoConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}



	const selectedHandleClick = (event, id, type, content) => {
		setInfoSeq(id)

		if (type === "ARTICLE") {
			detailInfoDialogHandleOpen()
		}
		else if (type === "URL") {
			window.open(content)
		}
	}

	const openInformationForm = (formType, infoItemResult) => () => {
		setFormType(formType)

		if (infoItemResult) {
			setFormInfoStatus(infoItemResult.info_status)
			setInfoSeq(infoItemResult.id)
		}
		
		createInfoDialogHandleOpen()
	}

	

	// Action
	const createInfoAction = () => {
		createInformation(createInfoDataRedux, newUploadAttachmentRedux).then(res => {
			if (res) {
				if (res.status) {
					setRefreshLayerList(res.code)
					setRefreshInfoContentList(res.code)
		
					buildAlert({
						// Alert Type: success, info, warning, error
						type: "success",
						title: "Success Alert",
						content: "Create new information item successful."
					})
		
					dispatch({
						type: "NEW_INFORMATION_CONTENT",
						data: null
					})
			
					dispatch({
						type: "NEW_UPLOAD_ATTACHMENT",
						data: []
					})
			
					dispatch({
						type: "EXIST_UPLOAD_ATTACHMENT",
						data: []
					})
		
					createInfoDialogHandleClose()
				}
				else {
					buildAlert({
						// Alert Type: success, info, warning, error
						type: "error",
						title: "Error Alert",
						content: res.message
					})
				}
			}
		})

		createInfoConfirmHandleClose()
	}

	
	const createDraftInfoAction = () => {
		createDraftInformation(createInfoDataRedux, newUploadAttachmentRedux).then(res => {
			if (res) {
				if (res.status) {
					setRefreshLayerList(res.code)
					setRefreshInfoContentList(res.code)
		
					buildAlert({
						// Alert Type: success, info, warning, error
						type: "success",
						title: "Success Alert",
						content: "Save information item to Draft successful."
					})
		
					dispatch({
						type: "NEW_INFORMATION_CONTENT",
						data: null
					})
			
					dispatch({
						type: "NEW_UPLOAD_ATTACHMENT",
						data: []
					})
			
					dispatch({
						type: "EXIST_UPLOAD_ATTACHMENT",
						data: []
					})
		
					createInfoDialogHandleClose()
				}
				else {
					buildAlert({
						// Alert Type: success, info, warning, error
						type: "error",
						title: "Error Alert",
						content: res.message
					})
				}
			}
		})

		createDraftInfoConfirmHandleClose()
	}


	const saveEditInfoAction = () => {
		saveEditInformation(createInfoDataRedux, newUploadAttachmentRedux, existUploadAttachmentRedux).then(res => {
			if (res) {
				if (res.status) {
					setRefreshLayerList(res.code)
					setRefreshInfoContentList(res.code)
		
					buildAlert({
						// Alert Type: success, info, warning, error
						type: "success",
						title: "Success Alert",
						content: "Information item save change successful."
					})
		
					dispatch({
						type: "NEW_INFORMATION_CONTENT",
						data: null
					})
			
					dispatch({
						type: "NEW_UPLOAD_ATTACHMENT",
						data: []
					})
			
					dispatch({
						type: "EXIST_UPLOAD_ATTACHMENT",
						data: []
					})
		
					createInfoDialogHandleClose()
				}
				else {
					buildAlert({
						// Alert Type: success, info, warning, error
						type: "error",
						title: "Error Alert",
						content: res.message
					})
				}
			}
		})

		saveEditInfoConfirmHandleClose()
	}


	const publishDraftInfoAction = () => {
		publishDraftInformation(createInfoDataRedux, newUploadAttachmentRedux, existUploadAttachmentRedux).then(res => {
			if (res) {
				if (res.status) {
					setRefreshLayerList(res.code)
					setRefreshInfoContentList(res.code)
		
					buildAlert({
						// Alert Type: success, info, warning, error
						type: "success",
						title: "Success Alert",
						content: "Information item save change successful."
					})
		
					dispatch({
						type: "NEW_INFORMATION_CONTENT",
						data: null
					})
			
					dispatch({
						type: "NEW_UPLOAD_ATTACHMENT",
						data: []
					})
			
					dispatch({
						type: "EXIST_UPLOAD_ATTACHMENT",
						data: []
					})

					createInfoDialogHandleClose()
				}
				else {
					buildAlert({
						// Alert Type: success, info, warning, error
						type: "error",
						title: "Error Alert",
						content: res.message
					})
				}
			}
		})

		publishDraftInfoConfirmHandleClose()
	}



	const removeInformationAction = infoSeq => () => {
		removeInformation({info_seq: infoSeq}).then(res => {
			setRefreshLayerList(res.code)
			setRefreshInfoContentList(res.code)

			if (res) {
				if (res.status) {
					buildAlert({
						// Alert Type: success, info, warning, error
						type: "success",
						title: "Success Alert",
						content: "The information item have been deleted successful."
					})
				}
				else {
					buildAlert({
						// Alert Type: success, info, warning, error
						type: "error",
						title: "Error Alert",
						content: res.message
					})
				}
			}
		})

		removeInfoConfirmHandleClose()
	}



	// Define full screen dialog button
	const createInfoDialogBtn = [
		{
			buttonName: "Save As Draft",
			buttonFunction: createDraftInfoConfirmHandleOpen,
		},
		{
			buttonName: "Publish",
			buttonFunction: createInfoConfirmHandleOpen,
		}
	]

	const editInfoDialogBtn = [
		{
			buttonName: "Save",
			buttonFunction: saveEditInfoConfirmHandleOpen,
		}
	]

	const editDraftDialogBtn = [
		{
			buttonName: "Save",
			buttonFunction: saveEditInfoConfirmHandleOpen,
		},
		{
			buttonName: "Publish",
			buttonFunction: publishDraftInfoConfirmHandleOpen,
		}
	]

	const detailInfoDialogBtn = []


	useEffect(() => {
		if (layerSelected) {
			getInfoContent(layerSelected).then(list => {
				if (list.data) {
					let createDataList = []
		
					list.data.map(res => {
						createDataList = [...createDataList, createData(res.info_seq, res.info_type, res.permission.can_edit, res.permission.can_delete, res.info_status, res.info_title, res.info_content, res.createuser, format(parseISO(res.createdate), "dd-MM-yyyy"))]
					})
		
					setTableContentList(createDataList)
				}
			})
		}
	}, [layerSelected, refreshInfoContentList])


	
	return (
		// console.log("---------------------------------------------------------------------------------"),
		// console.log("InformationList.js"),
		// console.log("Form Type : ", formType),
		// console.log("Form Info Status : ", formInfoStatus),
		// console.log("Table Content List : ", tableContentList),
		// console.log("createInfoDataRedux (Redux) : ", createInfoDataRedux),
		// console.log("newUploadAttachmentRedux (Redux) : ", newUploadAttachmentRedux),
		// console.log("existUploadAttachmentRedux (Redux) : ", existUploadAttachmentRedux),
		// console.log("---------------------------------------------------------------------------------"),


		<Fragment>
			<Box>
				<Alert
					open={alertOpen}
					alertType={alertData.type}
					alertTitle={alertData.title}
					alertContent={alertData.content}
					handleClose={alertHandleClose}
				/>

				<ConfirmDialog
					open={confirmDialogOpen}
					dialogTitle={confirmDialogData.dialogTitle}
					dialogContent={confirmDialogData.dialogContent}
					confirmAction={confirmDialogData.confirmAction}
					cancelAction={confirmDialogData.cancelAction}
				/>

				<FullScreenDialog
					open={createInfoDialogOpen}
					dialogTitle={formType === "CREATE" ? "Create Information Item" : formType === "EDIT" ? formInfoStatus === "DRAFT" ? "Edit Information Item (Draft)" : "Edit Information Item" : ""}
					formContent={
						<InformationForm
							formType={formType}
							layerSelected={layerSelected}
							infoSeq={infoSeq}
						/>
					}
					button={formType === "CREATE" ? createInfoDialogBtn : formType === "EDIT" ? formInfoStatus === "DRAFT" ? editDraftDialogBtn : editInfoDialogBtn : []}
					handleClose={createInfoDialogHandleClose}
				/>

				<FullScreenDialog
					open={detailInfoDialogOpen}
					dialogTitle={"Information Content"}
					formContent={
						<InformationContent
							infoSeq={infoSeq}
						/>
					}
					button={detailInfoDialogBtn}
					handleClose={detailInfoDialogHandleClose}
				/>
			</Box>

			{
				tableContentList ?
					<Box>
						<EnhancedTableToolbar />

						<TableContainer sx={informationListStyle.container}>
							<Table aria-labelledby="tableTitle"	size={"small"}>
								<colgroup>
									<col style={{width:'1%'}}/>
									<col style={{width:'69%'}}/>
									<col style={{width:'15%'}}/>
									<col style={{width:'15%'}}/>
								</colgroup>
						
								<EnhancedTableHead
									order={order}
									orderBy={orderBy}
									onRequestSort={sortRequestHandle}
								/>

								<TableBody>
								{
									tableContentList.length > 0 ?
										tableContentList.slice().sort(getComparator(order, orderBy)).slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row, index) => {
											return (
												<StyledTableRow	key={index} hover>

													<StyledTableCell align="center">
														<Stack direction="row" spacing={1}>
															{
																row.can_edit ?
																	<Tooltip title="Edit">
																		<EditOutlinedIcon onClick={openInformationForm("EDIT", row)} sx={informationListStyle.toolbarBtn} />
																	</Tooltip>
																: ""
															}

															{
																row.can_delete ?
																	<Tooltip title="Remove">
																		<DeleteOutlinedIcon onClick={(event) => removeInfoConfirmHandleOpen(event, row.id)} sx={informationListStyle.toolbarBtn} />
																	</Tooltip>
																: ""
															}
														</Stack>
													</StyledTableCell>

													<StyledTableCell align="left" onClick={(event) => selectedHandleClick(event, row.id, row.type, row.content)} sx={informationListStyle.tableRow}>
														<Stack direction="row" spacing={1}>
															<Box>
																{
																	row.type === "ARTICLE" ? 
																		<Tooltip title={row.type}><DescriptionOutlinedIcon sx={informationListStyle.infoType}/></Tooltip>
																	: row.type === "URL" ?
																		<Tooltip title={row.type}><LinkOutlinedIcon sx={informationListStyle.infoType} /></Tooltip>
																	: ""
																}
															</Box>

															<Box>
																{row.title}
															</Box>
														</Stack>

													</StyledTableCell>

													<StyledTableCell align="left" onClick={(event) => selectedHandleClick(event, row.id, row.type, row.content)} sx={informationListStyle.tableRow}>
														{row.sender}
													</StyledTableCell>

													<StyledTableCell align="left" onClick={(event) => selectedHandleClick(event, row.id, row.type, row.content)} sx={informationListStyle.tableRow}>
														{row.date}
													</StyledTableCell>
												</StyledTableRow>
											)
										})
									: <StyledTableCell align="center" colSpan={4}>No Record</StyledTableCell>
								}
								</TableBody>
							</Table>
						</TableContainer>
				
						<Pagination count={Math.ceil(tableContentList.length / rowsPerPage)} variant="outlined" shape="rounded" onChange={pageChangeHandle} page={page} sx={informationListStyle.pagination} />
					</Box>
				: ""
			}

		</Fragment>
	)

}

export default InformationList
