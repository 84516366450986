import {alpha} from "@mui/material/styles"
import globalStyle from "../../../styles/globalStyle"

const userFormStyle = {
	
	normalBtnStyle: {
		background: globalStyle.aquamarine[12],
		'&:hover': {
			backgroundColor: globalStyle.aquamarine[10],
		},
	},


	tableArea: {
		height: 500,
		overflowY: "auto",
		overflowX: "hidden",
	},

	userListArea:{
		background: alpha(globalStyle.grayColor[1], 0.5),
	},

	userListTool: {
		paddingTop: 0.5,
		paddingBottom: 0.5,
		paddingLeft: 2
	},


	resultArea: {
		background: alpha(globalStyle.aquamarine[1], 0.5),
	},
	
	resultTitle: {
		fontFamily: "Arial, Helvetica, sans-serif",
		fontWeight: "bold",

		paddingTop: 1,
		paddingBottom: 1,
		paddingLeft: 2
	},

	resultTool: {
		paddingTop: 0.5,
		paddingBottom: 0.5,
		paddingLeft: 2
	}


}


export default userFormStyle  