import React, { useContext } from "react";
import { Grid } from "@mui/material";
import EquipmentSection from "./EquipmentSection";
import RoomBookingContext from "../../RoomBookingContext";

const Room = ({ room }) => {
  const { bookingDialog, bookingRoom } = useContext(RoomBookingContext);
  const { setDialogOpen } = bookingDialog;
  const { setSelectedRoom } = bookingRoom;

  const handleOnClick = () => {
    setSelectedRoom(room);
    setDialogOpen(true);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={8}
        sx={{
          textAlign: "center",
          cursor: "pointer",
          ":hover": { opacity: 0.5 },
        }}
        onClick={handleOnClick}
      >
        <img src={room.img_path} alt={room.name} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minWidth: 200,
        }}
      >
        <EquipmentSection equipment={room.equipment} capacity={room.capacity} />
      </Grid>
    </>
  );
};

export default Room;
