import React, { Fragment, useState } from "react";

// Material UI
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Custom Component
import Alert from "../Alert/Alert";
import ConfirmDialog from "../Dialog/ConfirmDialog";

// Custom Style
import changePasswordStyle from "../ChangePassword/styles/changePasswordStyle";

// Fetch Data
import { userChangePassword } from "../../data_source/changePassword";

// Define password state
const ChangePassword = (props) => {
  const { changePasswordDialogHandleClose } = props;

  const [changePasswordFormData, setChangePasswordFormData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  //Define isShownPassword state
  const [showCurrentPassword, setShowCurrentPassword] = useState({
    isShowCurrentPassword: false,
  });

  const [showNewPassword, setShowNewPassword] = useState({
    isShowNewPassword: false,
  });

  const [showConfirmPassword, setShowConfirmPassword] = useState({
    isShowConfirmPassword: false,
  });

  //Define Alert
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    title: "",
    content: "",
  });

  // Define Confirm Dialog
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogData, setConfirmDialogData] = useState({
    dialogTitle: "",
    dialogContent: "",
    confirmAction: "",
    cancelAction: "",
  });

  //Handle isShownPassword
  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword({
      isShowCurrentPassword: !showCurrentPassword.isShowCurrentPassword,
    });
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword({
      isShowNewPassword: !showNewPassword.isShowNewPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword({
      isShowConfirmPassword: !showConfirmPassword.isShowConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Build Alert
  const buildAlert = (data) => {
    setAlertData({
      type: data.type,
      title: data.title,
      content: data.content,
    });
    setAlertOpen(true);
  };

  const alertHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  // Handle Dialog Open and Close
  const confirmDialogHandleOpen = (data) => {
    setConfirmDialogData({
      dialogTitle: data.dialogTitle,
      dialogContent: data.dialogContent,
      confirmAction: data.confirmAction,
      cancelAction: data.cancelAction,
    });

    setConfirmDialogOpen(true);
  };

  const confirmDialogHandleClose = () => {
    setConfirmDialogOpen(false);
  };

  const changePasswordConfirmHandleClose = () => {
    confirmDialogHandleClose();
  };

  // Handle Form Input
  const handleChange = (event) => {
    const { name, value } = event.target;
    const res = { ...changePasswordFormData };
    res[name] = value;

    setChangePasswordFormData(res);
  };

  //check password validate
  const isPassValid = () => {
    let validateMsg = "";
    let upperCheck = /[A-Z]/g;
    let lowerCheck = /[a-z]/g;
    let numCheck = /[0-9]/g;

    if (
      changePasswordFormData.current_password.length <= 0 ||
      changePasswordFormData.new_password.length <= 0 ||
      changePasswordFormData.confirm_password.length <= 0
    ) {
      validateMsg = "All field not allow empty.";
    } else if (changePasswordFormData.new_password.length < 8) {
      validateMsg = "Password length must have at least 8 characters.";
    } else if (
      changePasswordFormData.new_password !==
      changePasswordFormData.confirm_password
    ) {
      validateMsg = "New password and confirm password not match.";
    } else if (changePasswordFormData.new_password.match(upperCheck) === null) {
      validateMsg =
        "Password must contains at least 1 capital letter character.";
    } else if (changePasswordFormData.new_password.match(lowerCheck) === null) {
      validateMsg = "Password must contains at least 1 small letter character.";
    } else if (changePasswordFormData.new_password.match(numCheck) === null) {
      validateMsg = "Password must contains at least 1 numeric.";
    } else {
      validateMsg = "";
    }
    return validateMsg;
  };

  // Handle Form Submit
  const handleSubmit = (event) => {
    event.preventDefault();

    const msg = isPassValid();
    if (msg === "") {
      changePasswordConfirmHandleOpen();
    } else {
      buildAlert({
        // Alert Type: success, info, warning, error
        type: "error",
        title: "Error Alert",
        content: msg,
      });
    }
    return;
  };

  // Action
  const changePasswordAction = () => {
    const params = {
      current_password: changePasswordFormData.current_password,
      new_password: changePasswordFormData.new_password,
      confirm_password: changePasswordFormData.confirm_password,
    };

    userChangePassword(params).then((res) => {
      if (res) {
        if (res.status) {
          buildAlert({
            // Alert Type: success, info, warning, error
            type: "success",
            title: "Success Alert",
            content: "Change password successfully.",
          });
          setTimeout(() => {
            changePasswordDialogHandleClose();
          }, 2000);
        } else {
          buildAlert({
            // Alert Type: success, info, warning, error
            type: "error",
            title: "Error Alert",
            content: res.message,
          });
        }
      }
    });
    changePasswordConfirmHandleClose();
  };

  const changePasswordConfirmHandleOpen = () => {
    confirmDialogHandleOpen({
      dialogTitle: "Reset Password",
      dialogContent: "Are you sure to reset your password?",
      confirmAction: changePasswordAction,
      cancelAction: changePasswordConfirmHandleClose,
    });
  };

  return (
    <Fragment>
      <Box>
        <Stack
          direction="column"
          spacing={2}
          sx={changePasswordStyle.inputBoxes}
        >
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                id="current_password"
                name="current_password"
                label="Current Password"
                variant="outlined"
                required
                onChange={handleChange}
                style={{ width: "111%" }}
                value={changePasswordFormData.current_password}
                //isShownPassword
                type={
                  showCurrentPassword.isShowCurrentPassword
                    ? "text"
                    : "password"
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowCurrentPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showCurrentPassword.isShowCurrentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <TextField
                id="new_password"
                name="new_password"
                label="New Password"
                variant="outlined"
                required
                onChange={handleChange}
                value={changePasswordFormData.new_password}
                style={{ width: "100%" }}
                helperText={
                  <>
                    Password must have at least 8 characters, with at least 1
                    number, lowercase and uppercase.
                  </>
                }
                //isShownPassword
                type={showNewPassword.isShowNewPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showNewPassword.isShowNewPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <TextField
                id="confirm_password"
                name="confirm_password"
                label="Confirm Password"
                variant="outlined"
                required
                onChange={handleChange}
                style={{ width: "111%" }}
                value={changePasswordFormData.confirm_password}
                //isShownPassword
                type={
                  showConfirmPassword.isShowConfirmPassword
                    ? "text"
                    : "password"
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword.isShowConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={10} lg={11}>
              <Button
                variant="contained"
                size="smal"
                sx={changePasswordStyle.submitBtn}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Stack>

        <Alert
          open={alertOpen}
          alertType={alertData.type}
          alertTitle={alertData.title}
          alertContent={alertData.content}
          handleClose={alertHandleClose}
        />

        <ConfirmDialog
          open={confirmDialogOpen}
          dialogTitle={confirmDialogData.dialogTitle}
          dialogContent={confirmDialogData.dialogContent}
          confirmAction={confirmDialogData.confirmAction}
          cancelAction={confirmDialogData.cancelAction}
        />
      </Box>
    </Fragment>
  );
};

export default ChangePassword;
