import globalStyle from "../../../styles/globalStyle"

const appIconStyle = {
	icon: {
		paddingTop: 2,
		paddingBottom: 2,
		background: "var(--bg-color)",
		'&:hover': {
			background: "var(--hover-bg-color)",
		},
		cursor: "pointer"
	},

	name: {
		fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
		fontSize: "14px",
		color: globalStyle.whiteColor,
	}

}


export default appIconStyle  