import React, {Fragment} from "react"
import SystemApps from "../../components/SystemApps/SystemApps"

const SystemAppsView = () => {
	return (
		<Fragment>
			<SystemApps />
		</Fragment>
	)
}


export default SystemAppsView