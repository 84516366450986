import React, { useContext } from "react";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";
import RoomBookingContext from "../../RoomBookingContext";

const roomSelectionWidth = 150;

const RoomSelection = () => {
  const { bookingRoom, bookingAll } = useContext(RoomBookingContext);
  const { selectedRoom, setSelectedRoom } = bookingRoom;
  const { availableRoom } = bookingAll;

  const handleRoomSelect = (room) => {
    setSelectedRoom(room);
  };

  return (
    <Box
      sx={{
        width: roomSelectionWidth,
        textAlign: "center",
        overflowY: "scroll",
        height: "calc(100vh - 64px)",
      }}
    >
      <Typography
        sx={{
          fontSize: "0.9rem",
          height: 50,
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "sticky",
          top: 0,
          zIndex: 20,
        }}
      >
        Room Selection
      </Typography>
      {availableRoom.map((grouped, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <Box
            sx={{
              fontSize: "0.8rem",
              height: 32,
              backgroundColor: "white",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              position: "sticky",
              top: 50,
              zIndex: 10,
              px: 1,
            }}
          >
            <Divider sx={{ width: "100%" }}>
              <Typography sx={{ letterSpacing: 2 }}>{grouped.floor}</Typography>
            </Divider>
          </Box>
          <Stack spacing={1} sx={{ px: 2 }}>
            {grouped.rooms.map((room, i) => (
              <Box
                key={i}
                sx={{
                  width: "100%",
                  textAlign: "center",
                  cursor: "pointer",
                  ":hover": { opacity: 0.5 },
                  "& img": {
                    width: "inherit",
                    height: 80,
                    objectFit: "contain",
                  },
                }}
                onClick={() => handleRoomSelect(room)}
              >
                <Tooltip title={room.name} placement="right">
                  <Box
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      transition: "border-width 0.1s ease-in-out",
                      borderLeft:
                        selectedRoom.roombook_seq === room.roombook_seq
                          ? "4px solid #1cebd7"
                          : "0px solid transparent",
                    }}
                  >
                    <img src={room.img_path} alt={room.name} />
                  </Box>
                </Tooltip>
              </Box>
            ))}
          </Stack>
        </Box>
      ))}
    </Box>
  );
};

export default RoomSelection;
