import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { Typography, Chip } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ActionResponse = ({
  responseOpen,
  setResponseOpen,
  responseData,
  setResponseData,
  callbackFunc = null,
}) => {
  const handleResponseClose = () => {
    setResponseOpen(false);
    setResponseData(null);
    if (callbackFunc !== null) {
      callbackFunc();
    }
  };

  const getSuccessMessage = () => {
    if (responseData !== null) {
      if (responseData.success.length > 0) {
        return responseData.success.map((s, index) => (
          <Typography key={index} sx={{ pt: 1 }}>
            {s.message}
          </Typography>
        ));
      }
      return <Typography>---</Typography>;
    }
    return;
  };
  const getFailMessage = () => {
    if (responseData !== null) {
      if (responseData.fail.length > 0) {
        return responseData.fail.map((s, index) => (
          <Typography key={index} sx={{ pt: 1 }}>
            {s.message}
          </Typography>
        ));
      }
      return <Typography>---</Typography>;
    }
    return;
  };

  return (
    <>
      {responseOpen && (
        <Dialog
          open
          maxWidth={"sm"}
          fullWidth
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          disableEnforceFocus
        >
          <DialogTitle id="alert-dialog-slide-title">Results</DialogTitle>
          <DialogContent dividers>
            <Box sx={{ pb: 1 }}>
              <Chip label="Success" color="success" variant="outlined" />
              {getSuccessMessage()}
            </Box>
            <Box sx={{ pb: 1 }}>
              <Chip label="Fail" color="error" variant="outlined" />
              {getFailMessage()}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleResponseClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ActionResponse;
