import globalStyle from "../../../styles/globalStyle"

const noticeboardContentStyle = {
	root: {
		'& > *': {
			marginLeft: 2,
		},
	},

	appBar: {
		position: "relative",
		background: globalStyle.aquamarine[12]
	},

	title: {
		flex: 1,
		marginLeft: 2,
		
	},

	content: {
		paddingTop: 2,
		paddingBottom: 2,
		paddingLeft: 2,
		paddingRight: 2
	},

}


export default noticeboardContentStyle