import React, {Fragment, useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

// MUI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

// Custom Components
import TabMenu from "../TabMenu/TabMenu"
import UserForm from "../Users/UserForm"
import CustomListForm from "../Users/CustomListForm"


// Custom Style
import recipientFormStyle from './styles/recipientFormStyle'

// Fetch Data
import {getUserOptionList} from "../../data_source/users"



const RecipientForm = () => {

	// Define Redux
	// const noticeboardPostUserSeqRedux = useSelector(state => state.noticeboardPostUserSeqRedux)
	// const noticeboardPostUserRedux = useSelector(state => state.noticeboardPostUserRedux)

	// Define State
	
	
	// const [selectTableUserList, setSelectTableUserList] = useState([])
	

	// const [selectedUserSeq, setSelectedUserSeq] = useState([])
	// const [selectedUserRecord, setSelectedUserRecord] = useState([])

	const [selectedCustomListSeq, setSelectedCustomListSeq] = useState([])
	const [selectedCustomListRecord, setSelectedCustomListRecord] = useState([])
	
	const [postUserList, setPostUserList] = useState([])


	// const [selectedUserList, setSelectedUserList] = useState([])




	
	const tabMenuProperty = [
		{
			tabIndex: 0,
			name: "Select User",
			form: <UserForm />
		},
		{
			tabIndex: 1,
			name: "Custom List",
			form: <CustomListForm />
		}


		// {
		// 	tabIndex: 1,
		// 	name: "Custom List",
		// 	form: <CustomListForm 
		// 		selectUserSeq={selectedCustomListSeq}
		// 		selectUserSeqSetter={setSelectedCustomListSeq}

		// 		selectUserList={selectedCustomListRecord}
		// 		selectUserListSetter={setSelectedCustomListRecord}

		// 		postUserListSetter={setPostUserList}
		// 	/>
		// }
		
	]





	return (

		<Fragment>
			<Box sx={recipientFormStyle.listArea}>
				<TabMenu
					property={tabMenuProperty}
				/>
			</Box>
		</Fragment>
	)
}

export default RecipientForm
