const getToday = () => {
  let today = new Date();
  today = today.setHours(0, 0, 0, 0);

  return new Date(today);
};

const getWeekdays = () => {
  const weekdays = [
    {
      label: "Mon",
      day: "1",
    },
    {
      label: "Tue",
      day: "2",
    },
    {
      label: "Wed",
      day: "3",
    },
    {
      label: "Thu",
      day: "4",
    },
    {
      label: "Fri",
      day: "5",
    },
    {
      label: "Sat",
      day: "6",
    },
    {
      label: "Sun",
      day: "0",
    },
  ];
  return weekdays;
};

const dateToDisplay = (val) => {
  let dateTimeVal = new Date(val);
  if (dateTimeVal === "Invalid Date") {
    return "---";
  }
  // dd/MM/yyyy
  let day = dateTimeVal.getDate();
  let month = dateTimeVal.getMonth() + 1;
  let year = dateTimeVal.getFullYear();

  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;

  const dateStr = `${day}/${month}/${year}`;
  return dateStr;
};

export { getToday, getWeekdays, dateToDisplay };
