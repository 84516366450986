import React, {Fragment} from 'react'
import Apps from './components/App'
import './styles/css/main.css'

// import StyledEngineProvider from "@mui/material/StyledEngineProvider"

const App = () => {
	return (
		<Fragment>
			{/* <StyledEngineProvider injectFirst> */}
			<Apps />
			{/* </StyledEngineProvider> */}
		</Fragment>
	)
}

export default App
