import globalStyle from "../../../styles/globalStyle"

const windowsStyle = {
	windows: {
		minHeight: 270,
		padding: 0.5,
		// height: 270,
	},

	header: {
		display: "flex",
		alignItems: "center",
		height: 35,
		paddingLeft: 2,
		background: `linear-gradient(45deg, rgba(32, 206, 192, 0.1) 40%, ${globalStyle.whiteColor} 70%)`,
	},

	headerText: {
		fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
		fontWeight: "600",
	    fontSize: "16px",
		color: globalStyle.grayColor[10],
	},

	headerDivider: {
		height: 2,
		background: `linear-gradient(45deg, rgba(32, 206, 192, 0.4) 40%, ${globalStyle.whiteColor} 70%)`,
	},

	content: {
		padding: 1
	}

}


export default windowsStyle  