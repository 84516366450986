import React, {Fragment, useState, useEffect} from "react"

// Material UI
import Grid from '@mui/material/Grid'

// Custom Components
import AppIcon from "../AppIcon/AppIcon"

// Custom Style
import globalStyle from "../../styles/globalStyle"
import quickLinksContentStyle from './styles/quickLinksContentStyle'

// Fetch Data
import {getFrequentApplication} from "../../data_source/apps"


const QuickLinksContent = props => {
	const {showLoading, hiddenLoading} = props

	// Define State
	const [frequentApps, setFrequentApps] = useState(null)

	// Define Icon Color
	const iconColor = ["greenColor", "nightOrangeColor", "blueColor", "purpleColor", "oceanBlueColor", "brownColor", "darkSlateGrey", "orangeColor", "dodgerBlueColor"]


	useEffect(() => {
		showLoading()
		getFrequentApplication().then(res => {
			setFrequentApps(res.data)
			hiddenLoading()
		})
	}, [])


	return (

		// console.log("---------------------------------------------------------------------------------"),
		// console.log("QuickLinksContent.js"),
		// console.log("Frequent Apps : ", frequentApps),
		// console.log("---------------------------------------------------------------------------------"),

		<Fragment>
			<Grid container spacing={1}>
				{
					frequentApps ?
						frequentApps.map((app, index) => {
							return (
								<Grid item xs={4} sm={6} md={6} lg={6} xl={4} key={index}>
									<AppIcon
										appId={app.appl_seq}
										name={app.appl_short_name}
										icon={app.logo_path}
										background={`linear-gradient(180deg, ${globalStyle[iconColor[index % 10]][8]} 30%, ${globalStyle[iconColor[index % 10]][10]} 70%)`}
										hoverBackground={`linear-gradient(180deg, ${globalStyle[iconColor[index % 10]][10]} 30%, ${globalStyle[iconColor[index % 10]][8]} 70%)`}
									/>
								</Grid>
							)
						})
					: ""
				}
			</Grid>
		</Fragment>
	)
}

export default QuickLinksContent
