import React, { Fragment } from "react";

// MUI
import Box from "@mui/material/Box";

import { withStyles } from "@mui/styles";

// MUI Data Grid
import { DataGrid } from "@mui/x-data-grid";

// MUI Icon
// import ManageSearchIcon from '@mui/icons-material/ManageSearch'

// Custom Components

// Custom Style
import userDataGridStyle from "./styles/userDataGridStyle";

const UserDataGrid = (props) => {
  const { userList, setUserSeq, setUserRecord } = props;

  const columns = [
    {
      field: "eng_name",
      headerName: "English Name",
      width: 250,
    },

    {
      field: "chi_name",
      headerName: "Chinese Name",
      width: 250,
    },

    {
      field: "username",
      headerName: "Username",
      width: 150,
    },

    {
      field: "email",
      headerName: "Email",
      width: 200,
    },

    {
      field: "detail",
      headerName: "Detail Information",
      width: 900,
    },

    // {
    // 	field: 'site',
    // 	headerName: 'Site',
    // 	width: 250,
    // },

    // {
    // 	field: 'section',
    // 	headerName: 'Section',
    // 	width: 250,
    // },

    // {
    // 	field: 'grade',
    // 	headerName: 'Grade',
    // 	width: 150,
    // },

    // {
    // 	field: 'position',
    // 	headerName: 'position',
    // 	width: 250,
    // },
  ];

  const tableRowSelect = (userSeq) => {
    const userSeqSet = new Set(userSeq);
    const selectedRecord = userList.filter((row) => userSeqSet.has(row.id));

    setUserSeq(userSeq);
    setUserRecord(selectedRecord);
  };

  return (
    // console.log("--------------------------------------------------------"),
    // console.log("[UserDataGrid.js]"),
    // console.log("userList : ", userList),
    // console.log("--------------------------------------------------------"),

    <Fragment>
      <Box sx={{ height: 410 }}>
        <DataGrid
          rows={userList}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          density={"compact"}
          //   autoHeight
          checkboxSelection
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          disableVirtualization
          disableDensitySelector
          onSelectionModelChange={(params) => tableRowSelect(params)}
          getRowHeight={() => "auto"}
          sx={{
            "& .MuiDataGrid-cell": {
              whiteSpace: "break-spaces",
              paddingTop: 1,
              paddingBottom: 1,
            },
          }}
        />
      </Box>
    </Fragment>
  );
};

export default UserDataGrid;
