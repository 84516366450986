import globalStyle from "../../../styles/globalStyle"

const userListOptionStyle = {

	normalBtnStyle: {
		background: globalStyle.aquamarine[12],
		'&:hover': {
			backgroundColor: globalStyle.aquamarine[10],
		},
	},

	normalIconStyle: {
		background: globalStyle.aquamarine[12],
		'&:hover': {
			backgroundColor: globalStyle.aquamarine[10],
		},
	},

	selectOption: {
		paddingTop: 2,
		paddingBottom: 1
	},


	optionArea: {
		paddingBottom: 1
	},

	optionBtnArea: {
		display: "flex",
		justifyContent: "right",
		paddingBottom: 0.5,
	},

	optionToolArea: {
		display: "flex",
		justifyContent: "center",
		width: "100%",
	},

}


export default userListOptionStyle  