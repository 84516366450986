import axios from "axios";

const userForgotPassword = async (params) => {
  const userForgotPassword_api = "/password/forgot_password";

  try {
    let headers = {
      Authorization:
        localStorage.getItem("TOKEN_TYPE") +
        " " +
        localStorage.getItem("ACCESS_TOKEN"),
    };

    const result = await axios({
      //headers: headers,
      method: "post",
      url: process.env.REACT_APP_USERSERVICE_API_URL + userForgotPassword_api,
      data: params,
    });

    return result.data;
  } catch (error) {
    console.log("error : ", error);
    console.log("error : ", error.response.data.message);

    return error.response.data;
    /*
    if (error.response.status === 401) {
      window.location.href = process.env.REACT_APP_OIDC_LOGOUT_URL;
    } else {
      return error.response.data;
    }
    */
  }
};

export { userForgotPassword };
