import React, {Fragment} from "react"

// Material-UI Core
import Dialog from "@mui/material/Dialog"
import AppBar from "@mui/material//AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material//Toolbar"
import Slide from "@mui/material//Slide"
import Stack from '@mui/material/Stack'
import Button from "@mui/material//Button"
import IconButton from "@mui/material//IconButton"
import Typography from "@mui/material//Typography"

// Material-UI Icon
import CloseIcon from "@mui/icons-material/Close"

// Custom Style
import fullScreenDialogStyle from './styles/fullScreenDialogStyle'
import { height } from "@mui/system"


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const FullScreenDialog = props => {
	const {open, dialogTitle, formContent, button, handleClose} = props

	return (
		<Fragment>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar sx={fullScreenDialogStyle.appBar}>
					<Toolbar>
						<Typography variant="h6" sx={fullScreenDialogStyle.title}>
							{dialogTitle}
						</Typography>

						{/* <Box component="div" sx={fullScreenDialogStyle.root}> */}

							<Stack direction="row" spacing={2}>
							{button.map((x, key) => {
								return (

									// (x.chooseFile)
									// ?
									// <label htmlFor={"full-screen-dialog-btn-" + x.buttonName} key={key}>
									// 	<input
									// 		multiple
									// 		id={"full-screen-dialog-btn-" + x.buttonName}
									// 		type="file"
									// 		style={{display:"none"}}
									// 	/>

									// 	<Button component="span" color="inherit" variant="outlined" size="small">
									// 		{x.buttonName}
									// 	</Button>
									// </label>
									// :
									
									<Button color="inherit" variant="outlined" size="small" key={key} onClick={x.buttonFunction != null ? x.buttonFunction : null} >
										{x.buttonName}
									</Button>
								)
							})}

							
							<IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose}>
								<CloseIcon />
							</IconButton>

							</Stack>

						{/* </Box> */}
					</Toolbar>
				</AppBar>
				
				<Box component="div" sx={fullScreenDialogStyle.content}>
					{formContent}
				</Box>
			</Dialog>
		</Fragment>
	)
}


export default FullScreenDialog