import React, {Fragment, useState, useEffect} from "react"

// MUI
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

// Custom Components
import InformationGroup from "./InformationGroup"
import InformationList from "./InformationList";

// Custom Style
import informationStyle from './styles/informationStyle'

// Fetch Data
import {getInfoBoardLayer} from "../../data_source/information"


const Information = () => {
	// Define State
	const [infoBoardLayerList, setInfoboardLayerList] = useState(null)
	const [layerExpanded, setLayerExpanded] = useState([])
	const [layerSelected, setLayerSelected] = useState("")
	const [refreshLayerList, setRefreshLayerList] = useState("")


	useEffect(() => {
		getInfoBoardLayer().then(lists => {
			setInfoboardLayerList(lists.data)
		})
	}, [refreshLayerList])


	return (
		// console.log("---------------------------------------------------------------------------------"),
		// console.log("Information.js"),
		// console.log("Information Board Layer List : ", infoBoardLayerList),
		// console.log("Layer Select  : ", layerSelected),
		// console.log("Layer Seq (layerExpanded) : ", layerExpanded),
		// console.log("---------------------------------------------------------------------------------"),
		

		<Fragment>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={4} lg={3}>
					<Paper elevation={2} square sx={informationStyle.explorer}>
						<InformationGroup
							listData={infoBoardLayerList}

							layerSelected={layerSelected}
							layerSelectedSetter={setLayerSelected}

							layerExpanded={layerExpanded}
							layerExpandedSetter={setLayerExpanded}
						/>
					</Paper>
				</Grid>

				<Grid item xs={12} sm={8} lg={9}>
					<Paper square sx={informationStyle.content}>
						<InformationList
							layerSelected={layerSelected}
							setRefreshLayerList={setRefreshLayerList}
						/>
					</Paper>
				</Grid>
			</Grid>
		</Fragment>
	)
}

export default Information
