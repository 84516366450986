import globalStyle from "../../../styles/globalStyle"

const noticeboardStyle = {
	explorer: {
		height: 100 * 0.85 + "vh",
		paddingLeft: 1,
		paddingRight: 1
	},

	content: {
		height: 100 * 0.85 + "vh",
	}

}


export default noticeboardStyle  