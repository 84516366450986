import React, {Fragment, useState, useEffect} from "react"
import {useDispatch} from "react-redux"
import {format, parseISO} from "date-fns"

// Material UI
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

// Custom Components
import FileDropzone from "../FileDropzone/FileDropzone"
import DropzoneContent from "../FileDropzone/DropzoneContent"

// Custom Style
import informationContentStyle from './styles/informationContentStyle'

// Fetch Data
import {getInfoContentDetail, attachmentDownload} from "../../data_source/information"


const InformationContent = props => {
	const {infoSeq} = props
	const dispatch = useDispatch()

	// Define State
	const [contentDetail, setContentDetail] = useState(null)


	// Action
	const attachmentDownloadAction = atthSeq => async () => {
		await attachmentDownload(atthSeq)
	}


	useEffect(() => {
		getInfoContentDetail(infoSeq).then(res => {
			setContentDetail(res.data)

			dispatch({
				type: "EXIST_UPLOAD_ATTACHMENT",
				data: res.data.files
			})
		})
	}, [])


	return (
		// console.log("---------------------------------------------------------------------------------"),
		// console.log("InformationContent.js"),
		// console.log("Information Content Detail : ", contentDetail),
		// console.log("---------------------------------------------------------------------------------"),

		<Fragment>
			{
				contentDetail ?
					<Box sx={informationContentStyle.titleArea}>
						<Grid container spacing={0}>

							<Grid item xs={12} sm={4} lg={2}>
								<Box sx={informationContentStyle.title}>Form</Box>
							</Grid>
							<Grid item xs={12} sm={8} lg={10}>
								<Box sx={informationContentStyle.titleContent}>{contentDetail.createuser}</Box>
							</Grid>

							<Grid item xs={12} sm={4} lg={2}>
								<Box sx={informationContentStyle.title}>Date</Box>
							</Grid>
							<Grid item xs={12} sm={8} lg={10}>
								<Box sx={informationContentStyle.titleContent}>{format(parseISO(contentDetail.createdate), "dd-MM-yyyy")}</Box>
							</Grid>

							<Grid item xs={12} sm={4} lg={2}>
								<Box sx={informationContentStyle.title}>Subject</Box>
							</Grid>
							<Grid item xs={12} sm={8} lg={10}>
								<Box sx={informationContentStyle.titleContent}>{contentDetail.info_title}</Box>
							</Grid>

							<FormControl component="fieldset" variant="standard" sx={informationContentStyle.fieldset}>
								<FormLabel component="legend">Attachment</FormLabel>
									<FileDropzone
										acceptFileType={null}
										maxFiles={0}
										// DropzoneContent={DropzoneContentExist}
										DropzoneContent={DropzoneContent}

										enablePreview={false}
										enableUpload={false}
										enableDownload={true}
										enableDelete={false}

										onAcceptedAction={null}
										onRejectedAction={null}
										onPreviewAction={null}
										onDownloadAction={attachmentDownloadAction}
										onRemoveAction={null}
									/>
							</FormControl>
						</Grid>

						<Box sx={informationContentStyle.content}>
							<div dangerouslySetInnerHTML={{__html:contentDetail.info_content}}></div>
						</Box>
					</Box>
				: ""
			}
		</Fragment>
	)
}

export default InformationContent
