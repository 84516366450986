const DATE_SIZE = 32;

const dateStyles = {
  overflowX: "auto",
  "& > div": {
    minWidth: 256,
  },
  "& > div > div, & > div > div > div, & .MuiCalendarPicker-root": {
    width: "auto",
    minWidth: 256,
  },
  "& .MuiCalendarPicker-root > div:first-of-type": {
    px: 2,
  },
  "& .MuiCalendarPicker-root > div:last-of-type": {
    pb: 2,
  },
  "& .MuiTypography-caption": {
    width: DATE_SIZE,
    margin: 0,
  },
  "& .PrivatePickersSlideTransition-root": {
    minHeight: DATE_SIZE * 6,
  },
  '& .PrivatePickersSlideTransition-root [role="row"]': {
    margin: 0,
  },
  "& .MuiPickersDay-dayWithMargin": {
    margin: 0,
  },
  "& .MuiPickersDay-root": {
    width: DATE_SIZE,
    height: DATE_SIZE,
  },
};

export { dateStyles };
