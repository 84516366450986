import globalStyle from "../../../styles/globalStyle"

const noticeCreateStyle = {

	fieldset: {
		paddingBottom: 1,
		paddingLeft: 2,
		paddingRight: 2,
		border: 1,
		borderRadius: 1,
		borderColor: globalStyle.grayColor[4]
	},

	labelArea: {
		display: "flex",
		alignItems: "center",
	},

	label: {
	

		paddingLeft: 3
	},




	// addUserBtnArea: {
	// 	display: "flex",
	// 	justifyContent: "center",
	// 	width: "100%",
	// 	paddingTop: 1,
	// 	paddingBottom: 1,
	// },


	addUserBtn: {
		background: globalStyle.dodgerBlueColor[11],
		'&:hover': {
			background: globalStyle.dodgerBlueColor[13],
		},

		marginTop: 1,
		marginLeft: 1,
		marginRight: 1,
	},


	postUserText: {
		fontFamily: "Arial, Helvetica, sans-serif",
		color: globalStyle.grayColor[8],
		paddingTop: 2
	}


}


export default noticeCreateStyle  