import React, {Fragment, useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

// MUI
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

// MUI Icon
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

// Custom Components
import Alert from "../Alert/Alert"
import ConfirmDialog from "../Dialog/ConfirmDialog"
import UserOption from "./UserOption"
import UserDataGrid from "./UserDataGrid"
import CreateMyListForm from "./CreateMyListForm"

// Custom Style
import myListFormStyle from './styles/myListFormStyle'

// Fetch Data
import {getMyListDetail, createMyList, updateMyList} from "../../data_source/users"


const MyListForm = props => {
	const {selectedListSeq, selectedMyListName, myListDetaildialogClose, setRefresh} = props
	const dispatch = useDispatch()

	// Define Redux
	const recipientUserSeqRedux = useSelector(state => state.recipientUserSeqRedux)
	const recipientUserRecordRedux = useSelector(state => state.recipientUserRecordRedux)

	// Define State
	const [userListResult, setUserListResult] = useState(null)
	const [userSeqSelected, setUserSeqSelected] = useState(null)
	const [userRecordSelected, setUserRecordSelected] = useState(null)
	const [recipientUserSeq, setRecipientUserSeq] = useState(null)
	const [recipientUserRecord, setRecipientUserRecord] = useState(null)
	const [newMyListName, setNewMyListName] = useState("")

	const [myListName, setMyListName] = useState(selectedMyListName)

	// Define Alert
	const [alertOpen, setAlertOpen] = useState(false)
	const [alertData, setAlertData] = useState({
		type: "",
		title: "",
		content: ""
	})

	// Build Alert 
	const buildAlert = (data) => {
		setAlertData({
			type: data.type,
			title: data.title,
			content: data.content
		})
		setAlertOpen(true)
	}

	// Define Dialog Open State
	const [saveMyListDialogOpen, setSaveMyListDialogOpen] = useState(false)


	// Define Confirm Dialog
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
	const [confirmDialogData, setConfirmDialogData] = useState({
		dialogTitle: "",
		dialogContent: "",
		confirmAction: "",
		cancelAction: ""
	})



	// Handle Dialog Open and Close
	const confirmDialogHandleOpen = (data) => {
		setConfirmDialogData({
			dialogTitle: data.dialogTitle,
			dialogContent: data.dialogContent,
			confirmAction: data.confirmAction,
			cancelAction: data.cancelAction
		})

		setConfirmDialogOpen(true)
	}

	const confirmDialogHandleClose = () => {
		setConfirmDialogOpen(false)
	}

	const saveMyListConfirmHandleOpen = () => {
		confirmDialogHandleOpen({
			dialogTitle: "Save My List",
			dialogContent: "Are you sure to save change.",
			confirmAction: saveMyListAction,
			cancelAction: saveMyListConfirmHandleClose
		})
	}

	const saveMyListConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}




	const saveMyListDialogHandleOpen = () => {
		setSaveMyListDialogOpen(true)
	}

	const saveMyListDialogHandleClose = () => {
		setSaveMyListDialogOpen(false)
	}


	// Create JSON format data for Table
	const createData = (id, user_seq, eng_name, chi_name, username, email, detail) => {
		return {id, user_seq, eng_name, chi_name, username, email, detail}
	}

	// const createData = (id, eng_name, chi_name, site, section, grade, position) => {
	// 	return {id, eng_name, chi_name, site, section, grade, position}
	// }


	// Handle Form Input
	const myListNameHandleChange = event => {
		const {value} = event.target
		setMyListName(value)
	}



	// Action
	const addRecipientAction = () => {
		const addRecipient = async () => {
			if (userSeqSelected) {
				const remainingUserSeq = userSeqSelected.filter(row => 
					!recipientUserSeqRedux.includes(row)
				)

				dispatch({
					type: "RECIPIENT_USER_SEQ",
					data: [...recipientUserSeqRedux, ...remainingUserSeq]
				})
			}

			if (userRecordSelected) {
				const remainingRecord = userRecordSelected.filter(row => 
					!recipientUserSeqRedux.includes(row.id)
				)

				dispatch({
					type: "RECIPIENT_USER_RECORD",
					data: [...recipientUserRecordRedux, ...remainingRecord]
				})
			}
		}

		addRecipient().then(() => {
			const res = userListResult

			setUserListResult([])
			setUserListResult(res)
		})
	}


	const removeRecipientAction = () => {
		const remainingUserSeq = recipientUserSeqRedux.filter(row => 
			!recipientUserSeq.includes(row)
		)

		const remainingRecord = recipientUserRecordRedux.filter(row => 
			!recipientUserRecord.includes(row)
		)

		dispatch({
			type: "RECIPIENT_USER_RECORD",
			data: remainingRecord
		})

		dispatch({
			type: "RECIPIENT_USER_SEQ",
			data: remainingUserSeq
		})
	}


	const saveMyListAction = () => {
		const params = {
			"name": myListName,
			"user_seq": recipientUserSeqRedux
		}

		updateMyList(selectedListSeq, params).then(res => {
			buildAlert({
				// Alert Type: success, info, warning, error
				type: "success",
				title: "Success Alert",
				content: "My List update successfully."
			})

			myListDetaildialogClose()
			setRefresh(res.code)
		})

		saveMyListConfirmHandleClose()
	}


	// Define Dialog Button
	const saveMyListDialogBtn = [
		{
			buttonName: "Save",
			buttonFunction: saveMyListAction,
		},
		{
			buttonName: "Cancel",
			buttonFunction: saveMyListDialogHandleClose,
		}
	]


	useEffect(() => {
		getMyListDetail(selectedListSeq).then(lists => {
			let createDataList = []
			let seqList = []

			

			lists.data.map(list => {
				// createDataList = [...createDataList, createData(list.user_seq, list.fullname_eng, list.fullname_chi, list.sites, list.section_name, list.grade_name, list.position_name_eng)]
				// seqList = [...seqList, list.user_seq]


				let detailString = ""

				list.employee_details.map((detail, index) => {
					detailString = detailString + `Site:  ${detail.sas_site_name ? detail.sas_site_name : "--"}, Section: ${detail.section_name ? detail.section_name : "--"}, Grade: ${detail.grade_name ? detail.grade_name : "--"}, Position: ${detail.position_name_eng ? detail.position_name_eng : "--"}\n`
				})

				createDataList = [...createDataList, createData(list.user_seq, list.user_seq, list.fullname_eng, list.fullname_chi, list.username, list.email, detailString)]
				seqList = [...seqList, list.user_seq]
				

			})

			dispatch({
				type: "RECIPIENT_USER_RECORD",
				data: createDataList
			})
	
			dispatch({
				type: "RECIPIENT_USER_SEQ",
				data: seqList
			})
		})
	}, [])


	return (

		// console.log("--------------------------------------------------------"),
		// console.log("[UserForm.js]"),
		// console.log("userSeqSelected : ", userSeqSelected),
		// console.log("userRecordSelected : ", userRecordSelected),
		// console.log(""),
		// console.log("recipientUserSeq : ", recipientUserSeq),
		// console.log("recipientUserRecord : ", recipientUserRecord),
		// console.log(""),
		// console.log("recipientUserSeqRedux : ", recipientUserSeqRedux),
		// console.log("recipientUserRecordRedux : ", recipientUserRecordRedux),
		// console.log("--------------------------------------------------------"),


		<Fragment>
			<Box>
				<Alert
					open={alertOpen}
					alertType={alertData.type}
					alertTitle={alertData.title}
					alertContent={alertData.content}
					handleClose={() => setAlertOpen(false)}
				/>

				<ConfirmDialog
					open={confirmDialogOpen}
					dialogTitle={confirmDialogData.dialogTitle}
					dialogContent={confirmDialogData.dialogContent}
					confirmAction={confirmDialogData.confirmAction}
					cancelAction={confirmDialogData.cancelAction}
				/>
			
				{/* <NormalDialog
					open={saveMyListDialogOpen}
					dialogTitle={"Save My List"}
					formContent={
						<CreateMyListForm 
							myListName={newMyListName}
							setMyListName={setNewMyListName}
						/>
					}
					size={"sm"}   // xs = extraSmall, sm = small, md = medium, lg = large, xl = extraLarge}
					height={60}
					button={saveMyListDialogBtn}
					handleClose={saveMyListDialogHandleClose}
				/> */}
			</Box>

			<Stack spacing={2}>
				<Box sx={myListFormStyle.optionArea}>
					<UserOption 
						setUserResult={setUserListResult}
					/>
				</Box>

				<Box sx={myListFormStyle.tableArea}>
					<Stack spacing={2}>
						{
							userListResult ? 
								<Box sx={myListFormStyle.userListArea}>
									<Box sx={myListFormStyle.userListTool}>
										<Tooltip title="Add User">
											<Fab color="primary" size="small" aria-label="add" onClick={addRecipientAction} sx={myListFormStyle.normalBtnStyle}>
												<PersonAddAltOutlinedIcon />
											</Fab>
										</Tooltip>
									</Box>

									<Box>
										<UserDataGrid 
											userList={userListResult}
											setUserSeq={setUserSeqSelected}
											setUserRecord={setUserRecordSelected}
										/>
									</Box>
								</Box>
							: ""
						}

						<Box sx={myListFormStyle.resultArea}>
							<Box sx={myListFormStyle.resultTitle}>My List Information</Box>
							{
								recipientUserRecordRedux.length > 0 ? 
									<Box sx={myListFormStyle.resultTool}>
									<Stack spacing={1}>
										<Box>
											<TextField 
												id="name"
												name="name"
												label="My List Name"
												variant="outlined"
												required
												fullWidth={true}
												size="small"
												value={myListName || ""}
												onChange={myListNameHandleChange}
											/>
										</Box>

										<Box>
											<Stack direction="row" spacing={2}>
												<Tooltip title="Remove User">
													<Fab color="primary" size="small" aria-label="add" onClick={removeRecipientAction} sx={myListFormStyle.normalBtnStyle}>
														<PersonRemoveOutlinedIcon />
													</Fab>
												</Tooltip>

												<Tooltip title="Save to My List">
													<Fab color="primary" size="small" aria-label="add" onClick={saveMyListConfirmHandleOpen} sx={myListFormStyle.normalBtnStyle}>
														<SaveOutlinedIcon />
													</Fab>
												</Tooltip>
											</Stack>
										</Box>
									</Stack>
									</Box>
								: ""
							}

							<Box>
								<UserDataGrid 
									userList={recipientUserRecordRedux}
									setUserSeq={setRecipientUserSeq}
									setUserRecord={setRecipientUserRecord}
								/>
							</Box>
						</Box>
					</Stack>
				</Box>
			</Stack>
		</Fragment>
	)
}

export default MyListForm
