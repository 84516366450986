import React, { Fragment, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

// Material UI
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Custom Component
import Alert from "../Alert/Alert";

// Custom Style
import forgotPasswordStyle from "./styles/forgotPasswordStyle";

// Fetch Data
import { userForgotPassword } from "../../data_source/forgotPassword";

const ForgotPassword = (props) => {
  //Define forgotPasswordForm state
  const [forgotPasswordFormData, setForgotPasswordFormData] = useState({
    username: "",
    email: "",
  });

  //Define Alert
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    title: "",
    content: "",
  });

  // Handle Form Input
  const handleChange = (event) => {
    const { name, value } = event.target;
    const res = { ...forgotPasswordFormData };
    res[name] = value;

    setForgotPasswordFormData(res);
  };

  // Handle Form Submit
  const handleSubmit = (event) => {
    event.preventDefault();

    forgotPasswordAction();
  };

  //handle Form closure
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate({
      pathname: "/",
    });
  };

  // Build Alert
  const buildAlert = (data) => {
    setAlertData({
      type: data.type,
      title: data.title,
      content: data.content,
    });
    setAlertOpen(true);
  };

  const alertHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  // Action
  const forgotPasswordAction = () => {
    const params = {
      username: forgotPasswordFormData.username,
      email: forgotPasswordFormData.email,
    };

    userForgotPassword(params).then((res) => {
      if (res) {
        if (res.status) {
          buildAlert({
            // Alert Type: success, info, warning, error
            type: "success",
            title: "Success Alert",
            content:
              "Password reset request was sent successfully." +
              "Please check you email to reset your password.",
          });

          setTimeout(() => {
            handleCancel();
          }, 2000);
        } else {
          buildAlert({
            // Alert Type: success, info, warning, error
            type: "error",
            title: "Error Alert",
            content: res.message,
          });
        }
      }
    });
  };

  return (
    <Fragment>
      <Box sx={forgotPasswordStyle.inputBoxes}>
        <Stack direction="column" spacing={2}>
          <Grid container spacing={2}>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={forgotPasswordStyle.formTitle}
            >
              Forgot Password
            </Typography>

            <Grid item xs={12} sm={10} lg={11}>
              <TextField
                id="username"
                name="username"
                label="Enter your username"
                variant="outlined"
                required
                onChange={handleChange}
                value={forgotPasswordFormData.username}
              />
            </Grid>

            <Grid item xs={12} sm={10} lg={11}>
              <TextField
                id="email"
                name="email"
                label="Enter your email address"
                variant="outlined"
                required
                onChange={handleChange}
                value={forgotPasswordFormData.email}
              />
            </Grid>

            <Grid item xs={12} sm={10} lg={11}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  size="smal"
                  sx={forgotPasswordStyle.btn}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  size="smal"
                  sx={forgotPasswordStyle.btn}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>

        <Alert
          open={alertOpen}
          alertType={alertData.type}
          alertTitle={alertData.title}
          alertContent={alertData.content}
          handleClose={alertHandleClose}
        />
      </Box>
    </Fragment>
  );
};

export default ForgotPassword;
