// import {oceanBlueColor, grayColor, redColor} from '../../cmsMainStyle'
import globalStyle from "../../../styles/globalStyle"

const confirmDialogStyle = {
	content: {
		color: globalStyle.grayColor[12]
	},

	attentionContent: {
		color: globalStyle.redColor[10],
		fontSize: 10
	},

	actionButton: {
		color: globalStyle.oceanBlueColor[13],
		fontSize: 15,
		fontWeight: 600,
	}
}


export default confirmDialogStyle