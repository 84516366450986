import globalStyle from "../../../styles/globalStyle";

const changePasswordStyle = {
  inputBoxes: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 300,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "85vh",
  },

  submitBtn: {
    background: globalStyle.aquamarine[12],

    "&:hover": {
      background: globalStyle.aquamarine[10],
    },
  },

  passValidate: {
    color: globalStyle.grayColor[12],
    marginTop: "15%",
  },
};

export default changePasswordStyle;
