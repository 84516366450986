import axios from "axios"

const getApplication = async category => {
	const getApplication_api = "/application"
	let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		params.append("cat", category)

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_APPLICATION_API_URL + getApplication_api,
			params: params,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}

const getFrequentApplication = async () => {
	const getFrequentApplication_api = "/application/frequent_use"
	let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_APPLICATION_API_URL + getFrequentApplication_api,
			params: params,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}

const getApplicationProxy = async appsId => {
	const getApplicationProxy_api = "/application/proxy"
	let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		params.append("app_id", appsId)

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_APPLICATION_API_URL + getApplicationProxy_api,
			params: params,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}



export {
	getApplication,
	getFrequentApplication,
	getApplicationProxy
}