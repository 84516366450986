import React, { useEffect, useContext, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import SectionItem from "../../SectionItem";
import ReservedPeriod from "../ReservedPeriod";
import RoomBookingContext from "../../RoomBookingContext";
import { getRoomOccupiedStatus } from "../../../../data_source/roombook";
import Alert from "../../../Alert/Alert";
import { dateToDisplay } from "../../utilFunc";

const TimePeriod = ({ flag, dateValue, setSelectedTimePeriod }) => {
  const { bookingRoom, timePeriod } = useContext(RoomBookingContext);
  const { selectedRoom } = bookingRoom;
  const { setTimePeriodConfig } = timePeriod;
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(null);
  // Define Alert dialog state
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    title: "",
    content: "",
  });

  useEffect(() => {
    const fetchTimePeriod = async () => {
      const params = {
        roombook_seq: selectedRoom.roombook_seq,
        book_date: dateToDisplay(dateValue),
      };

      setLoading(true);
      await getRoomOccupiedStatus(params)
        .then((u) => {
          setRows(u.data.map((d, index) => ({ ...d, id: index + 1 })));
          setTimePeriodConfig(
            u.data.map((d) => ({
              config_seq: d.config_seq,
              time_from: d.time_from,
              time_to: d.time_to,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
          buildAlert({
            // Alert Type: success, info, warning, error
            type: "error",
            title: "Error Alert",
            content: `${error.message}`,
          });
        });
      setLoading(false);
    };

    fetchTimePeriod();
  }, [dateValue, selectedRoom, flag]);

  // Build Alert
  const buildAlert = (data) => {
    setAlertData({
      type: data.type,
      title: data.title,
      content: data.content,
    });
    setAlertOpen(true);
  };

  const alertHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70, hide: true },
    {
      field: "timeslot",
      headerName: "Timeslot",
      width: 150,
      sortable: false,
      valueGetter: (params) =>
        `${params.row.time_from} - ${params.row.time_to}`,
    },
    {
      field: "occupiedBy",
      headerName: "Occupied By",
      minWidth: 240,
      flex: 1,
      sortable: false,
      valueGetter: (params) => {
        const { english_name, chinese_name } = params.row;
        const eng = english_name === null ? "" : english_name;
        const chi = chinese_name === null ? "" : chinese_name;
        return `${eng} ${chi}`.trim();
      },
    },
    {
      field: "remark",
      headerName: "Remark",
      minWidth: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "reserve_info",
      headerName: "",
      width: 40,
      sortable: false,
      renderCell: (params) =>
        params.row.occupied === "Y" ? (
          <ReservedPeriod rowInfo={params.row} />
        ) : (
          <></>
        ),
    },
  ];

  return (
    <>
      <Alert
        open={alertOpen}
        alertType={alertData.type}
        alertTitle={alertData.title}
        alertContent={alertData.content}
        handleClose={alertHandleClose}
      />
      <SectionItem header="Time Period">
        <Box>
          <div style={{ height: 328, width: "100%" }}>
            <DataGrid
              sx={{ borderRadius: 0 }}
              loading={loading}
              density="compact"
              rows={loading ? [] : rows}
              columns={columns}
              hideFooter
              disableColumnMenu={true}
              onSelectionModelChange={(newSelection) => {
                setSelectedTimePeriod(
                  rows.filter((obj) => newSelection.includes(obj.id))
                );
              }}
              disableSelectionOnClick
              checkboxSelection
              isRowSelectable={(params) =>
                params.row.occupied === "N" ||
                params.row.createuser === localStorage.getItem("USERNAME")
              }
            />
          </div>
        </Box>
      </SectionItem>
    </>
  );
};

export default TimePeriod;
