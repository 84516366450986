import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, TextField, Stack, Typography } from "@mui/material";
import RoomBookingContext from "../../RoomBookingContext";
import SectionItem from "../../SectionItem";
import DataFieldDisplay from "../../DataFieldDisplay";
import BookingSchedule from "../../BookingSchedule";
import { dateToDisplay } from "../../utilFunc";
import ActionButton from "../../ActionButton/ActionButton";
import NumberFormat from "react-number-format";

const BookingInfo = ({ dateValue, handleSave, handleDelete, setFlag }) => {
  const { bookingRoom } = useContext(RoomBookingContext);
  const { selectedRoom } = bookingRoom;
  const [extension, setExtension] = useState("");
  const [remark, setRemark] = useState("");
  const [validateExtensionMsg, setValidateExtensionMsg] = useState("");

  useEffect(() => {
    const resetBookingField = () => {
      setExtension("");
      setRemark("");
    };

    resetBookingField();
  }, [selectedRoom]);

  const handleRemark = (e) => {
    setRemark(e.target.value);
  };

  const handleValueChange = (values) => {
    // const { floatValue } = values;
    // setExtension(floatValue === undefined ? "" : floatValue);
    const { value } = values;
    setExtension(value === undefined ? "" : value);
  };

  const validate = () => {
    if (extension === "") {
      setValidateExtensionMsg("Required");
      return false;
    }
    // else if (extension.toString().length !== 4) {
    //   setValidateExtensionMsg("Extension needs to be 4 digits");
    //   return false;
    // }
    setValidateExtensionMsg("");
    return true;
  };

  return (
    <SectionItem header="Booking Information">
      <Box sx={{ p: 2, pt: 1 }}>
        <Grid container>
          <Grid item xs={12} sm={6} lg={3}>
            <DataFieldDisplay label={"Floor"} value={selectedRoom.floor} />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <DataFieldDisplay
              label={"Venue"}
              value={`${selectedRoom.name} - (Extension: ${selectedRoom.extension})`}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <DataFieldDisplay
              label={"Capacity"}
              value={selectedRoom.capacity}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <DataFieldDisplay
              label={"Equipment"}
              value={selectedRoom.equipment.map((equip, index) => (
                <Box
                  component="li"
                  key={index}
                  sx={{
                    textTransform: "capitalize",
                    m: 0,
                    listStyleType: "decimal",
                  }}
                >
                  {equip}
                </Box>
              ))}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6} lg={3}>
            <DataFieldDisplay label={"Date"} value={dateToDisplay(dateValue)} />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <DataFieldDisplay
              label={"Booking User"}
              value={localStorage.getItem("FULLNAME_ENG")}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} md={6} lg={4}>
            <NumberFormat
              value={extension}
              customInput={TextField}
              onValueChange={handleValueChange}
              size="small"
              // format="# # # #"
              label="Extension"
              // mask={"_"}
              required
              error={validateExtensionMsg !== ""}
              helperText={validateExtensionMsg}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <TextField
              sx={{ width: "100%" }}
              id="standard-multiline-static"
              label="Remark"
              multiline
              rows={3}
              value={remark}
              size="small"
              onChange={handleRemark}
            />
          </Grid>
        </Grid>
        <Stack direction={"row"} spacing={1} sx={{ mt: 2 }}>
          <ActionButton
            title="Book"
            content="Are you sure to book the selected time period?"
            actionFunc={() => handleSave(extension, remark)}
            validateFunc={validate}
          />
          <ActionButton
            title="Delete"
            content="Are you sure to cancel the selected time period?"
            actionFunc={handleDelete}
            color="error"
          />
          <BookingSchedule setFlag={setFlag} />
        </Stack>
      </Box>
    </SectionItem>
  );
};

export default BookingInfo;
