import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const DataFieldDisplay = ({ label, value }) => {
  return (
    <Box sx={{ mb: 0.5 }}>
      <Typography sx={{ color: "#00000099", fontSize: "0.75rem" }}>
        {label}
      </Typography>
      <Typography
        sx={{
          color: "#00000099",
          fontSize: "1rem",
          overflowWrap: "break-word",
          whiteSpace: "pre-line",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default DataFieldDisplay;
