import globalStyle from "../../../styles/globalStyle"

const dropzoneContentStyle = {
	dropzoneArea: {
		// background: grayColor[1]
	},

	buttonArea: {
		textAlign: "center",
		paddingTop:  2,

	},

	icon: {
		background: globalStyle.dodgerBlueColor[11],
		'&:hover': {
			background: globalStyle.dodgerBlueColor[13],
		},
	},

	infoMessage: {
		fontFamily: "Arial, Helvetica, sans-serif",
		color: globalStyle.grayColor[6],
		padding: 2,
	}
}

export default dropzoneContentStyle