// Fetch Data
import { getApplicationProxy } from "../../data_source/apps";

const LaunchApps = (appId) => {
  getApplicationProxy(appId).then((app) => {
    let form = document.createElement("form");

    form.setAttribute("method", app.data.request_method);
    form.setAttribute("action", app.data.request_url);
    form.setAttribute("target", "_blank");

    const objKeyToArr = Object.keys(app.data.request_params).map((key) => {
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = app.data.request_params[key];
      form.appendChild(input);
    });

    if (objKeyToArr.length === 0) {
      // Empty object, implies no params
      window.open(app.data.request_url, "_blank");
      return;
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  });
};

export default LaunchApps;
