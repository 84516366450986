import React, { Fragment } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";

// Custom Components
import SignIn from "./SignIn/SignIn";
import Callback from "./SignIn/Callback";
import SignoutCallback from "./SignIn/SignoutCallback";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ForgotPassword/ResetPassword";

const App = () => {
  // Read URL Parameter
  let [searchParams, setSearchParams] = useSearchParams();

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route
          path="/callback"
          element={
            <Callback
              code={searchParams.get("code")}
              state={searchParams.get("state")}
              error={searchParams.get("error")}
            />
          }
        />
        <Route path="/logout" element={<SignoutCallback />} />
        <Route path="/forgotpass" element={<ForgotPassword />} />
        <Route
          path="/resetpass"
          element={
            <ResetPassword
              username={searchParams.get("username")}
              resetToken={searchParams.get("reset_token")}
            />
          }
        />
      </Routes>
    </Fragment>
  );
};

export default App;
