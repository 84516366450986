import React, { Fragment, useState, useEffect } from "react"

// MUI
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment';

// MUI Icon
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import ClearIcon from '@mui/icons-material/Clear'

// Custom Component
import UseLoading from "../Loading/UseLoading"

// Custom Style
import userOptionStyle from './styles/userOptionStyle'

// Fetch Data
import { getUserOptionList, getSearchUserList } from "../../data_source/users"


const UserOption = props => {
	const { setUserResult } = props

	// Define State
	const [getOptionLoading, getOptionShowLoading, getOptionHiddenLoading] = UseLoading()
	const [getUserLoading, getUserShowLoading, getUserHiddenLoading] = UseLoading()

	// Define State
	const [OptionList, setOptionList] = useState(null)
	const [siteOptionList, setSiteOptionList] = useState(null)
	const [sectionOptionList, setSectionOptionList] = useState(null)
	const [gradeOptionList, setGradeOptionList] = useState(null)

	const [companyOptionSelected, setCompanyOptionSelected] = useState([])
	const [siteOptionSelected, setSiteOptionSelected] = useState([])
	const [sectionOptionSelected, setSectionOptionSelected] = useState([])
	const [gradeOptionSelected, setGradeOptionSelected] = useState([])
	const [searchUserKey, setSearchUserKey] = useState({
		fullname: "",
		username: "",
		email: ""
	})


	// Create JSON format data for Table
	const createData = (id, user_seq, eng_name, chi_name, username, email, detail) => {
		return { id, user_seq, eng_name, chi_name, username, email, detail }
	}


	// Handle Form Input
	const textFieldHandleChange = event => {
		const { name, value } = event.target
		const res = { ...searchUserKey }
		res[name] = value

		setSearchUserKey(res)
	}

	const clearTextField = name => () => {
		const res = { ...searchUserKey }
		res[name] = ""

		setSearchUserKey(res)
	}


	const clearAllOption = type => () => {
		if (type === "COMPANY") {
			setCompanyOptionSelected([])
			setSiteOptionList([])
			setSectionOptionList([])
			setGradeOptionList([])
		}
		else if (type === "SITE") {
			setSiteOptionSelected([])
		}
		else if (type === "SECTION") {
			setSectionOptionSelected([])
		}
		else if (type === "GRADE") {
			setGradeOptionSelected([])
		}
	}


	// Action
	const optionSelectAction = type => event => {
		const { options } = event.target
		const value = []

		for (let i = 0, l = options.length; i < l; i += 1) {
			if (options[i].selected) {
				value.push(options[i].value)
			}
		}

		switch (type) {
			case "COMPANY":
				setCompanyOptionSelected([value[0]])

				setSiteOptionList(OptionList ?
					OptionList.find(obj => obj.company_code === value[0])?.sites
					: [])

				setSectionOptionList(OptionList ?
					OptionList.find(obj => obj.company_code === value[0])?.sections
					: [])

				setGradeOptionList(OptionList ?
					OptionList.find(obj => obj.company_code === value[0])?.grades
					: [])

				clearAllOption('SITE')
				clearAllOption('SECTION')
				clearAllOption('GRADE')

				break
			case "SITE":
				setSiteOptionSelected(value)
				break

			case "SECTION":
				setSectionOptionSelected(value)
				break

			case "GRADE":
				setGradeOptionSelected(value)
				break

			default:
				return
		}
	}


	const showUserListAction = () => {
		const parameters = {
			"company_code": companyOptionSelected,
			"site_id": siteOptionSelected,
			"section_id": sectionOptionSelected,
			"grade_code": gradeOptionSelected,
			...searchUserKey
		}


		setUserResult([])

		getUserShowLoading()
		getSearchUserList(parameters).then(lists => {
			let createDataList = []

			lists.data.map(list => {
				let detailString = ""

				list.employee_details.map((detail, index) => {
					detailString = detailString + `Company:  ${detail.company_name ? detail.company_name : "--"}, Site:  ${detail.sas_site_name ? detail.sas_site_name : "--"}, Section: ${detail.section_name ? detail.section_name : "--"}, Grade: ${detail.grade_name ? detail.grade_name : "--"}, Position: ${detail.position_name_eng ? detail.position_name_eng : "--"}\n`
				})

				createDataList = [...createDataList, createData(list.user_seq, list.user_seq, list.fullname_eng, list.fullname_chi, list.username, list.email, detailString)]
			})

			setUserResult(createDataList)
			getUserHiddenLoading()
		})
	}


	useEffect(() => {
		getOptionShowLoading()
		getUserOptionList().then(list => {
			setOptionList(list.data)
			getOptionHiddenLoading()
		})
	}, [])

	return (
		// console.log("--------------------------------------------------------"),
		// console.log("[UserOption.js]"),
		// console.log("siteOptionList : ", siteOptionList),
		// console.log("sectionOptionList : ", sectionOptionList),
		// console.log("gradeOptionList : ", gradeOptionList),
		// console.log("Search User Field : ", searchUserKey),
		// console.log(""),
		// console.log("siteOptionSelected : ", siteOptionSelected),
		// console.log("sectionOptionSelected : ", sectionOptionSelected),
		// console.log("gradeOptionSelected : ", gradeOptionSelected),
		// console.log("--------------------------------------------------------"),


		<Fragment>
			<Box sx={userOptionStyle.optionArea}>
				<Grid container spacing={2}>

					<Grid item xs={12} sm={4} lg={12}>
						<Box sx={userOptionStyle.optionBtnArea}>
							<Button variant="contained" size="small" onClick={clearAllOption("COMPANY")} sx={userOptionStyle.normalBtnStyle}>Clear Selection</Button>
						</Box>

						<FormControl fullWidth>
							<InputLabel shrink htmlFor="select-multiple-native" >Company {getOptionLoading}</InputLabel>
							<Select
								multiple
								native
								label="Company"
								value={companyOptionSelected}
								inputProps={{
									id: 'select-multiple-native',
								}}
								onChange={optionSelectAction("COMPANY")}
								sx={userOptionStyle.selectOption}
							>
								{
									OptionList ?
										OptionList.map(list => (
											<option key={list.company_code} value={list.company_code}>
												{list.company_name}
											</option>
										))
										: ""
								}
							</Select>
						</FormControl>
					</Grid>

					{
						companyOptionSelected.length ?
							<Grid item xs={12} sm={4} lg={4}>
								<Box sx={userOptionStyle.optionBtnArea}>
									<Button variant="contained" size="small" onClick={clearAllOption("SITE")} sx={userOptionStyle.normalBtnStyle}>Clear Selection</Button>
								</Box>

								<FormControl fullWidth>
									<InputLabel shrink htmlFor="select-multiple-native" >Site {getOptionLoading}</InputLabel>
									<Select
										multiple
										native
										label="Site"
										value={siteOptionSelected}
										inputProps={{
											id: 'select-multiple-native',
										}}
										onChange={optionSelectAction("SITE")}
										sx={userOptionStyle.selectOption}
									>
										{
											siteOptionList ?
												siteOptionList.map(list => (
													<option key={list.sas_site_id} value={list.sas_site_id}>
														{list.sas_site_name}
													</option>
												))
												: ""
										}
									</Select>
								</FormControl>
							</Grid>
							: ""
					}

					{
						companyOptionSelected.length ?
							<Grid item xs={12} sm={4} lg={4}>
								<Box sx={userOptionStyle.optionBtnArea}>
									<Button variant="contained" size="small" onClick={clearAllOption("SECTION")} sx={userOptionStyle.normalBtnStyle}>Clear Selection</Button>
								</Box>

								<FormControl fullWidth>
									<InputLabel shrink htmlFor="select-multiple-native">Section {getOptionLoading}</InputLabel>
									<Select
										multiple
										native
										label="Section"
										value={sectionOptionSelected}
										inputProps={{
											id: 'select-multiple-native',
										}}
										onChange={optionSelectAction("SECTION")}
										sx={userOptionStyle.selectOption}
									>
										{
											sectionOptionList ?
												sectionOptionList.map(list => (
													<option key={list.section_id} value={list.section_id}>
														{list.section_name}
													</option>
												))
												: ""
										}
									</Select>
								</FormControl>
							</Grid>
							: ""
					}

					{
						companyOptionSelected.length ?
							<Grid item xs={12} sm={4} lg={4}>
								<Box sx={userOptionStyle.optionBtnArea}>
									<Button variant="contained" size="small" onClick={clearAllOption("GRADE")} sx={userOptionStyle.normalBtnStyle}>Clear Selection</Button>
								</Box>

								<FormControl fullWidth>
									<InputLabel shrink htmlFor="select-multiple-native">Grade {getOptionLoading}</InputLabel>
									<Select
										multiple
										native
										label="Grade"
										value={gradeOptionSelected}
										inputProps={{
											id: 'select-multiple-native',
										}}
										onChange={optionSelectAction("GRADE")}
										sx={userOptionStyle.selectOption}
									>
										{
											gradeOptionList ?
												gradeOptionList.map(list => (
													<option key={list.grade_code} value={list.grade_code}>
														{list.grade_name}
													</option>
												))
												: ""
										}
									</Select>
								</FormControl>
							</Grid>
							: ""
					}

					<Grid item xs={12} sm={4} lg={4}>
						<TextField
							id="fullname"
							name="fullname"
							label="Full Name"
							variant="outlined"
							fullWidth={true}
							size="small"
							onChange={textFieldHandleChange}
							value={searchUserKey ? searchUserKey.fullname : ""}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											onClick={clearTextField("fullname")}
											edge="end"
										>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</Grid>

					<Grid item xs={12} sm={4} lg={4}>
						<TextField
							id="username"
							name="username"
							label="User Name"
							variant="outlined"
							fullWidth={true}
							size="small"
							onChange={textFieldHandleChange}
							value={searchUserKey ? searchUserKey.username : ""}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											onClick={clearTextField("username")}
											edge="end"
										>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</Grid>

					<Grid item xs={12} sm={4} lg={4}>
						<TextField
							id="email"
							name="email"
							label="Email Address"
							variant="outlined"
							fullWidth={true}
							size="small"
							onChange={textFieldHandleChange}
							value={searchUserKey ? searchUserKey.email : ""}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											onClick={clearTextField("email")}
											edge="end"
										>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</Grid>

					<Grid item xs={12} sm={12} lg={12} sx={userOptionStyle.optionToolArea}>
						<Tooltip title="Find Users">
							<Fab color="primary" size="small" aria-label="add" sx={userOptionStyle.normalIconStyle} onClick={showUserListAction}>
								<ManageSearchIcon />
							</Fab>
						</Tooltip>
					</Grid>

					<Grid item xs={12} sm={12} lg={12} sx={userOptionStyle.optionToolArea}>
						{getUserLoading}
					</Grid>
				</Grid>
			</Box>
		</Fragment>
	)
}

export default UserOption
