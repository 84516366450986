import React, {Fragment} from 'react'
import Dashboard from '../../components/Dashboard/Dashboard'

const DashboardView = () => {
	return (
		<Fragment>
			<Dashboard />
		</Fragment>
	)
}


export default DashboardView