import React, {Fragment, useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

// MUI
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import Stack from '@mui/material/Stack'

// MUI Icon
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

// Custom Components
import Alert from "../Alert/Alert"
import NormalDialog from "../Dialog/NormalDialog"
import UserOption from "./UserOption"
import UserDataGrid from "./UserDataGrid"
import CreateMyListForm from "./CreateMyListForm"

// Custom Style
import userFormStyle from './styles/userFormStyle'

// Fetch Data
import {createMyList} from "../../data_source/users"



const UserForm = () => {
	const dispatch = useDispatch()

	// Define Redux
	const recipientUserSeqRedux = useSelector(state => state.recipientUserSeqRedux)
	const recipientUserRecordRedux = useSelector(state => state.recipientUserRecordRedux)

	// Define State
	const [userListResult, setUserListResult] = useState(null)
	const [userSeqSelected, setUserSeqSelected] = useState(null)
	const [userRecordSelected, setUserRecordSelected] = useState(null)
	const [recipientUserSeq, setRecipientUserSeq] = useState(null)
	const [recipientUserRecord, setRecipientUserRecord] = useState(null)
	const [newMyListName, setNewMyListName] = useState("")

	// Handle Dialog Open
	const [saveMyListDialogOpen, setSaveMyListDialogOpen] = useState(false)


	// Define Alert
	const [alertOpen, setAlertOpen] = useState(false)
	const [alertData, setAlertData] = useState({
		type: "",
		title: "",
		content: ""
	})

	// Build Alert 
	const buildAlert = (data) => {
		setAlertData({
			type: data.type,
			title: data.title,
			content: data.content
		})
		setAlertOpen(true)
	}


	// Handle Dialog Open and Close
	const saveMyListDialogHandleOpen = () => {
		setSaveMyListDialogOpen(true)
	}

	const saveMyListDialogHandleClose = () => {
		setSaveMyListDialogOpen(false)
	}


	// Action
	const addRecipientAction = () => {
		const addRecipient = async () => {

			if (userSeqSelected) {
				const remainingUserSeq = userSeqSelected.filter(row => 
					!recipientUserSeqRedux.includes(row)
				)

				dispatch({
					type: "RECIPIENT_USER_SEQ",
					data: [...recipientUserSeqRedux, ...remainingUserSeq]
				})
			}

			if (userRecordSelected) {
				const remainingRecord = userRecordSelected.filter(row => 
					!recipientUserSeqRedux.includes(row.id)
				)

				dispatch({
					type: "RECIPIENT_USER_RECORD",
					data: [...recipientUserRecordRedux, ...remainingRecord]
				})
			}
		}

		addRecipient().then(() => {
			const res = userListResult

			setUserListResult([])
			setUserListResult(res)
		})
	}


	const removeRecipientAction = () => {
		const remainingUserSeq = recipientUserSeqRedux.filter(row => 
			!recipientUserSeq.includes(row)
		)

		const remainingRecord = recipientUserRecordRedux.filter(row => 
			!recipientUserRecord.includes(row)
		)

		dispatch({
			type: "RECIPIENT_USER_RECORD",
			data: remainingRecord
		})

		dispatch({
			type: "RECIPIENT_USER_SEQ",
			data: remainingUserSeq
		})
	}


	const saveMyListAction = () => {
		const params = {
			"name": newMyListName,
			"user_seq": recipientUserSeqRedux
		}

		createMyList(params).then(res => {
			buildAlert({
				// Alert Type: success, info, warning, error
				type: "success",
				title: "Success Alert",
				content: "Save My List successfully."
			})
		})

		saveMyListDialogHandleClose()
	}


	// Define Dialog Button
	const saveMyListDialogBtn = [
		{
			buttonName: "Cancel",
			buttonFunction: saveMyListDialogHandleClose,
		},
		{
			buttonName: "Save",
			buttonFunction: saveMyListAction,
		}
		
	]


	return (

		console.log("--------------------------------------------------------"),
		// console.log("[UserForm.js]"),
		// console.log("userSeqSelected : ", userSeqSelected),
		// console.log("userRecordSelected : ", userRecordSelected),
		// console.log(""),
		// console.log("recipientUserSeq : ", recipientUserSeq),
		// console.log("recipientUserRecord : ", recipientUserRecord),
		// console.log(""),
		// console.log("recipientUserSeqRedux : ", recipientUserSeqRedux),
		// console.log("recipientUserRecordRedux : ", recipientUserRecordRedux),
		// console.log(""),
		// console.log("userListResult : ", userListResult),
		// console.log("--------------------------------------------------------"),


		<Fragment>
			<Box>
				<Alert
					open={alertOpen}
					alertType={alertData.type}
					alertTitle={alertData.title}
					alertContent={alertData.content}
					handleClose={() => setAlertOpen(false)}
				/>
				
				<NormalDialog
					open={saveMyListDialogOpen}
					dialogTitle={"Save My List"}
					formContent={
						<CreateMyListForm 
							myListName={newMyListName}
							setMyListName={setNewMyListName}
						/>
					}
					size={"sm"}   // xs = extraSmall, sm = small, md = medium, lg = large, xl = extraLarge}
					height={60}
					button={saveMyListDialogBtn}
					handleClose={saveMyListDialogHandleClose}
				/>
			</Box>

			<Stack spacing={2}>
				<Box sx={userFormStyle.optionArea}>
					<UserOption 
						setUserResult={setUserListResult}
					/>
				</Box>

				<Box sx={userFormStyle.tableArea}>
					<Stack spacing={2}>
						{
							userListResult ? 
								<Box sx={userFormStyle.userListArea}>
									<Box sx={userFormStyle.userListTool}>
										<Tooltip title="Add User">
											<Fab color="primary" size="small" aria-label="add" onClick={addRecipientAction} sx={userFormStyle.normalBtnStyle}>
												<PersonAddAltOutlinedIcon />
											</Fab>
										</Tooltip>
									</Box>

									<Box>
										<UserDataGrid 
											userList={userListResult}
											setUserSeq={setUserSeqSelected}
											setUserRecord={setUserRecordSelected}
										/>
									</Box>
								</Box>
							: ""
						}

						<Box sx={userFormStyle.resultArea}>
							<Box sx={userFormStyle.resultTitle}>Post Target List</Box>
							{
								recipientUserRecordRedux.length > 0 ? 
									<Box sx={userFormStyle.resultTool}>
										<Stack direction="row" spacing={2}>
											<Tooltip title="Remove User">
												<Fab color="primary" size="small" aria-label="add" onClick={removeRecipientAction} sx={userFormStyle.normalBtnStyle}>
													<PersonRemoveOutlinedIcon />
												</Fab>
											</Tooltip>

											<Tooltip title="Save to My List">
												<Fab color="primary" size="small" aria-label="add" onClick={saveMyListDialogHandleOpen} sx={userFormStyle.normalBtnStyle}>
													<SaveOutlinedIcon />
												</Fab>
											</Tooltip>
										</Stack>
									</Box>
								: ""
							}

							<Box>
								<UserDataGrid 
									userList={recipientUserRecordRedux}
									setUserSeq={setRecipientUserSeq}
									setUserRecord={setRecipientUserRecord}
								/>
							</Box>
						</Box>
					</Stack>
				</Box>
			</Stack>
		</Fragment>
	)
}

export default UserForm
