import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Custom Components
import MainView from "../../views/Main/MainView";
import DashboardView from "../../views/Dashboard/DashboardView";
import NoticeboardView from "../../views/Noticeboard/NoticeboardView";
import InformationView from "../../views/Information/InformationView";
import SystemAppsView from "../../views/SystemApps/SystemAppsView";
import KnowHowView from "../../views/KnowHow/KnowHowView";
import RoomBookView from "../../views/RoomBook/RoomBookView";

const MainApp = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<MainView />}>
          <Route index element={<DashboardView />} />
          <Route path="/noticeboard" element={<NoticeboardView />} />
          <Route path="/information" element={<InformationView />} />
          <Route path="/systemapps" element={<SystemAppsView />} />
          <Route path="/knowhow" element={<KnowHowView />} />
        </Route>
        <Route path="/roombook" element={<RoomBookView />} />
      </Routes>
    </Fragment>
  );
};

export default MainApp;
