import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material UI
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Custom Component
import Alert from "../Alert/Alert";
import ConfirmDialog from "../Dialog/ConfirmDialog";

// Custom Style
import resetPasswordStyle from "./styles/resetPasswordStyle";

// Fetch Data
import { resetPassword } from "../../data_source/resetPassword";

const ResetPassword = (props) => {
  const { username, resetToken } = props;

  //Define forgotPasswordForm state
  const [resetPasswordFormData, setResetPasswordFormData] = useState({
    username: "",
    reset_token: resetToken,
    new_password: "",
    confirm_password: "",
  });

  //Define isShownPassword state
  const [showNewPassword, setShowNewPassword] = useState({
    isShowNewPassword: false,
  });

  const [showConfirmPassword, setShowConfirmPassword] = useState({
    isShowConfirmPassword: false,
  });

  //Define Alert
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    title: "",
    content: "",
  });

  // Define Confirm Dialog
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogData, setConfirmDialogData] = useState({
    dialogTitle: "",
    dialogContent: "",
    confirmAction: "",
    cancelAction: "",
  });

  //Handle isShownPassword
  const handleClickShowNewPassword = () => {
    setShowNewPassword({
      isShowNewPassword: !showNewPassword.isShowNewPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword({
      isShowConfirmPassword: !showConfirmPassword.isShowConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Build Alert
  const buildAlert = (data) => {
    setAlertData({
      type: data.type,
      title: data.title,
      content: data.content,
    });
    setAlertOpen(true);
  };

  const alertHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  // Handle Dialog Open and Close
  const confirmDialogHandleOpen = (data) => {
    setConfirmDialogData({
      dialogTitle: data.dialogTitle,
      dialogContent: data.dialogContent,
      confirmAction: data.confirmAction,
      cancelAction: data.cancelAction,
    });

    setConfirmDialogOpen(true);
  };

  const confirmDialogHandleClose = () => {
    setConfirmDialogOpen(false);
  };

  const resetPasswordConfirmHandleClose = () => {
    confirmDialogHandleClose();
  };

  // Handle Form Input
  const handleChange = (event) => {
    const { name, value } = event.target;
    const res = { ...resetPasswordFormData };
    res[name] = value;

    setResetPasswordFormData(res);
  };

  //check password validate
  const isPassValid = () => {
    let validateMsg = "";
    let upperCheck = /[A-Z]/g;
    let lowerCheck = /[a-z]/g;
    let numCheck = /[0-9]/g;

    if (
      resetPasswordFormData.username.length <= 0 ||
      resetPasswordFormData.new_password.length <= 0 ||
      resetPasswordFormData.confirm_password.length <= 0
    ) {
      validateMsg = "All field not allow empty.";
    } else if (resetPasswordFormData.new_password.length < 8) {
      validateMsg = "Password length must have at least 8 characters.";
    } else if (
      resetPasswordFormData.new_password !==
      resetPasswordFormData.confirm_password
    ) {
      validateMsg = "New password and confirm password not match.";
    } else if (resetPasswordFormData.new_password.match(upperCheck) === null) {
      validateMsg =
        "Password must contains at least 1 capital letter character.";
    } else if (resetPasswordFormData.new_password.match(lowerCheck) === null) {
      validateMsg = "Password must contains at least 1 small letter character.";
    } else if (resetPasswordFormData.new_password.match(numCheck) === null) {
      validateMsg = "Password must contains at least 1 numeric.";
    } else {
      validateMsg = "";
    }
    return validateMsg;
  };

  // Handle Form Submit
  const handleSubmit = (event) => {
    event.preventDefault();

    const msg = isPassValid();
    if (msg === "") {
      resetPasswordConfirmHandleOpen();
    } else {
      buildAlert({
        // Alert Type: success, info, warning, error
        type: "error",
        title: "Error Alert",
        content: msg,
      });
    }
    return;
  };

  //handle Form closure
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate({
      pathname: "/",
    });
  };

  // Action
  const resetPasswordAction = () => {
    const params = {
      username: resetPasswordFormData.username,
      reset_token: resetPasswordFormData.reset_token,
      new_password: resetPasswordFormData.new_password,
      confirm_password: resetPasswordFormData.confirm_password,
    };

    resetPassword(params).then((res) => {
      if (res) {
        if (res.status) {
          buildAlert({
            // Alert Type: success, info, warning, error
            type: "success",
            title: "Success Alert",
            content:
              "Your password has been reset successfully." +
              "You may now login with your new password.",
          });

          setTimeout(() => {
            handleCancel();
          }, 2000);
        } else {
          buildAlert({
            // Alert Type: success, info, warning, error
            type: "error",
            title: "Error Alert",
            content: res.message,
          });
        }
      }
    });
    resetPasswordConfirmHandleClose();
  };

  const resetPasswordConfirmHandleOpen = () => {
    confirmDialogHandleOpen({
      dialogTitle: "Reset Password",
      dialogContent: "Are you sure to reset your password?",
      confirmAction: resetPasswordAction,
      cancelAction: resetPasswordConfirmHandleClose,
    });
  };

  return (
    <Fragment>
      <Box sx={resetPasswordStyle.inputBoxes}>
        <Stack direction="column" spacing={2}>
          <Grid container spacing={2}>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={resetPasswordStyle.formTitle}
            >
              Reset Password
            </Typography>

            <Typography
              variant="h8"
              gutterBottom
              component="div"
              sx={resetPasswordStyle.passValidate}
            >
              Password Policy:
              <br />
              Password must have at least 8 characters,
              <br />
              with at least 1 number, lowercase and uppercase.
            </Typography>

            <Grid item>
              <TextField
                id="username"
                name="username"
                label="Enter your username"
                variant="outlined"
                required
                onChange={handleChange}
                style={{ width: "121%" }}
                value={resetPasswordStyle.username}
              />
            </Grid>

            <Grid item>
              <TextField
                id="new_password"
                name="new_password"
                label="Enter your new password"
                variant="outlined"
                required
                onChange={handleChange}
                style={{ width: "100%" }}
                value={resetPasswordStyle.new_password}
                //isShownPassword
                type={showNewPassword.isShowNewPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showNewPassword.isShowNewPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <TextField
                id="confirm_password"
                name="confirm_password"
                label="Confirm Password"
                variant="outlined"
                required
                onChange={handleChange}
                style={{ width: "100%" }}
                value={resetPasswordStyle.confirm_password}
                //isShownPassword
                type={
                  showConfirmPassword.isShowConfirmPassword
                    ? "text"
                    : "password"
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword.isShowConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={10} lg={11}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  size="smal"
                  sx={resetPasswordStyle.btn}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  size="smal"
                  sx={resetPasswordStyle.btn}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>

        <Alert
          open={alertOpen}
          alertType={alertData.type}
          alertTitle={alertData.title}
          alertContent={alertData.content}
          handleClose={alertHandleClose}
        />

        <ConfirmDialog
          open={confirmDialogOpen}
          dialogTitle={confirmDialogData.dialogTitle}
          dialogContent={confirmDialogData.dialogContent}
          confirmAction={confirmDialogData.confirmAction}
          cancelAction={confirmDialogData.cancelAction}
        />
      </Box>
    </Fragment>
  );
};

export default ResetPassword;
