import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Slide,
  Button,
  Typography,
} from "@mui/material";
import DataFieldDisplay from "../../DataFieldDisplay";
import InfoIcon from "@mui/icons-material/Info";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReservedPeriod = ({ rowInfo }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        onClick={handleOpen}
        aria-label="open"
        size="small"
      >
        <InfoIcon fontSize="small" color="primary" />
      </IconButton>
      {open && (
        <Dialog
          open={true}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <DialogTitle>Reserved Period</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DataFieldDisplay
                  label={"Time Period"}
                  value={`${rowInfo.time_from} - ${rowInfo.time_to}`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DataFieldDisplay
                  label={"User"}
                  value={`${rowInfo.english_name} ${rowInfo.chinese_name}`}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <DataFieldDisplay
                  label={"Section"}
                  value={rowInfo.section ? rowInfo.section : "---"}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <DataFieldDisplay
                  label={"Extension"}
                  value={rowInfo.extension}
                />
              </Grid>
              <Grid item xs={12}>
                <DataFieldDisplay
                  label={"Remark"}
                  value={rowInfo.remark ? rowInfo.remark : "---"}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ReservedPeriod;
