import globalStyle from "../../../styles/globalStyle"

const informationStyle = {
	explorer: {
		height: 100 * 0.85 + "vh",
		overflowY: "auto",
		overflowX: "hidden",

		paddingLeft: 1,
		paddingRight: 1,
	},

	content: {
		height: 100 * 0.85 + "vh",
	}

}


export default informationStyle  