import React, {Fragment} from "react"

// MUI
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"

// Custom Styles
import loadingStyle from "./Styles/loadingStyle"

const Loading = () => {
	return (
		<Fragment >
			<Box sx={loadingStyle.loading}>
				<CircularProgress size={50} thickness={5.0} />
			</Box>
		</Fragment>
	)
}

export default Loading