const globalStyle = {
	desktopDisplay : {xs: 'none', md: 'flex'},
	mobileDisplay : {xs: 'flex', md: 'none'},
	
	primaryColor : ['#ff0000', '#ff9900', '#ffff00', '#009933', '#00ff00', '#003399', '#b3b3b3', '#000000', '#ffffff'],
	infoColor : ["#00acc1", "#26c6da", "#00acc1", "#00d3ee"],
	successColor : ["#4caf50", "#66bb6a", "#43a047", "#5cb860"],
	warningColor : ["#ff9800", "#ffa726", "#fb8c00", "#ffa21a"],
	dangerColor : ["#f44336", "#ef5350", "#e53935", "#f55a4e"],
	roseColor : ["#e91e63", "#ec407a", "#d81b60", "#eb3573"],
	
	redColor : ["#ffe6e6", "#ffcccc", "#ffb3b3", "#ff9999", "#ff8080", "#ff6666", "#ff4d4d", "#ff3333", "#ff1a1a", "#ff0000", "#e60000", "#cc0000", "#b30000", "#990000", "#800000"],
	nightOrangeColor :["#fffae6", "#fff5cc", "#fff0b3", "#ffeb99", "#ffe680", "#ffe066", "#ffdb4d", "#ffd633", "#ffd11a", "#ffcc00", "#e6b800", "#cca300", "#b38f00", "#997a00", "#806600"],
	orangeColor : ["#fff5e6", "#ffebcc", "#ffe0b3", "#ffd699", "#ffcc80", "#ffc266", "#ffb84d", "#ffad33", "#ffa31a", "#ff9900", "#e68a00", "#cc7a00", "#b36b00", "#995c00", "#804d00"],
	yellowColor : ["#ffffe6", "#ffffcc", "#ffffb3", "#ffff99", "#ffff80", "#ffff66", "#ffff4d", "#ffff33", "#ffff1a", "#ffff00", "#e6e600", "#cccc00", "#b3b300", "#999900", "#808000"],
	greenColor : ["#ecf9ec", "#d9f2d9", "#c6ecc6", "#b3e6b3", "#9fdf9f", "#8cd98c", "#79d279", "#66cc66", "#53c653", "#40bf40", "#39ac39", "#339933", "#2d862d", "#267326", "#206020"],
	blueColor : ["#e6ecff", "#ccd9ff", "#b3c6ff", "#99b3ff", "#809fff", "#668cff", "#4d79ff", "#3366ff", "#1a53ff", "#0040ff", "#0039e6", "#0033cc", "#002db3", "#002699", "#002080"],
	oceanBlueColor : ["#ecf2f9", "#d9e6f2", "#c6d9ec", "#b3cce6", "#9fbfdf", "#8cb3d9", "#79a6d2", "#6699cc", "#538cc6", "#4080bf", "#3973ac", "#336699", "#2d5986", "#264d73", "#204060", ],
	dodgerBlueColor : ["#e6f5ff", "#ccebff", "#b3e0ff", "#99d6ff", "#80ccff", "#66c2ff", "#4db8ff", "#33adff", "#1aa3ff", "#0099ff", "#008ae6", "#007acc", "#006bb3", "#005c99", "#004d80"],
	purpleColor : ["#f5e6ff", "#ebccff", "#e0b3ff", "#d699ff", "#cc80ff", "#c266ff", "#b84dff", "#ad33ff", "#a31aff", "#9900ff", "#8a00e6", "#7a00cc", "#6b00b3", "#5c0099", "#4c0080"],
	brownColor : ["#f9f2ec", "#f2e6d9", "#ecd9c6", "#e6ccb3", "#dfbf9f", "#d9b38c", "#d2a679", "#cc9966", "#c68c53", "#bf8040", "#ac7339", "#996633", "#86592d", "#734d26", "#604020"],
	grayColor : ["#f2f2f2", "#e6e6e6", "#d9d9d9", "#cccccc", "#bfbfbf", "#b3b3b3", "#a6a6a6", "#999999", "#8c8c8c", "#808080", "#737373", "#666666", "#595959", "#4d4d4d", "#404040"],
	darkSlateGrey : ["#ffffff", "#eff5f5", "#e0ebeb", "#d0e1e1", "#c1d7d7", "#b1cdcd", "#a2c3c3", "#92b9b9", "#83afaf", "#73a5a5", "#639c9c", "#5a8c8c", "#507c7c", "#466d6d", "#3c5d5d"],
	goldColor : "#FFD700",
	blackColor : "#000",
	whiteColor : "#FFF",
	aquamarine: ["#e9fcfa", "#d3f8f5", "#bdf5f0", "#a7f1eb", "#91eee6", "#7beae1", "#65e7dc", "#4fe3d7", "#38e0d2", "#22ddcd", "#20cec0", "#1fc7b9", "#1cb0a4", "#189a90", "#15847b"]
}


export default globalStyle