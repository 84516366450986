import React, {Fragment, useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {format, parseISO} from "date-fns"
import PropTypes from 'prop-types'

// MUI
import {styled, alpha} from "@mui/material/styles"
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import Pagination from '@mui/material/Pagination'

// MUI utils
import {visuallyHidden} from '@mui/utils'

// MUI Icon
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

// Custom Icon
import noticeBoardIcon from "../../img/icon-noticeboard.png"

// Custom Components
import Alert from "../Alert/Alert"
import ConfirmDialog from "../Dialog/ConfirmDialog"
import FullScreenDialog from "../Dialog/FullScreenDialog"
import FileDropzone from "../FileDropzone/FileDropzone"
import DropzoneContentExist from "../FileDropzone/DropzoneContentExist"
import TabMenu from "../TabMenu/TabMenu"
import NoticeEdit from "./NoticeEdit"

// Custom Style
import globalStyle from "../../styles/globalStyle"
import noticeDetailStyle from './styles/noticeDetailStyle'

// Fetch Data
import {getNoticeDetail, getNoticeUser, attachmentDownload, createNotice, updateNotice, removeNotice} from "../../data_source/noticeboard"


// Define Custom TableCell Style
const StyledTableCell = styled(TableCell)(({theme}) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: globalStyle.dodgerBlueColor[8],
		color: theme.palette.common.white,
		fontSize: 16,
		fontWeight: "bold"
	},

	[`&.${tableCellClasses.body}`]: {
	  fontSize: 14
	}
}))

// Define Custom TableRow Style
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: alpha(globalStyle.dodgerBlueColor[0], 0.5)
	},
  
	"td, th": {
	  border: 0
	}
}))


const attachmentDownloadAction = atthSeq => () => {
	attachmentDownload(atthSeq).then(res => {
		console.log("Download Attachment : ", res)
	})
}



// Define Notice Content Screen
const NoticeDisplay = props => {
	const {noticeSeq, noticeDetail, edit, remove, detailScreenClose, setRefresh} = props
	const dispatch = useDispatch()

	// Define Redux
	const newNoticeContentRedux = useSelector(state => state.newNoticeContentRedux)
	const newUploadAttachmentRedux = useSelector(state => state.newUploadAttachmentRedux)
	const existUploadAttachmentRedux = useSelector(state => state.existUploadAttachmentRedux)

	// Define Dialog State
	const [editDialogOpen, setEditDialogOpen] = useState(false)

	// Define Alert
	const [alertOpen, setAlertOpen] = useState(false)
	const [alertData, setAlertData] = useState({
		type: "",
		title: "",
		content: ""
	})

	// Define Confirm Dialog
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
	const [confirmDialogData, setConfirmDialogData] = useState({
		dialogTitle: "",
		dialogContent: "",
		confirmAction: "",
		cancelAction: ""
	})


	// Build Alert
	const buildAlert = (data) => {
		setAlertData({
			type: data.type,
			title: data.title,
			content: data.content
		})

		setAlertOpen(true)
	}

	const alertHandleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setAlertOpen(false)
	}


	// Handle Dialog open and close
	const confirmDialogHandleOpen = (data) => {
		setConfirmDialogData({
			dialogTitle: data.dialogTitle,
			dialogContent: data.dialogContent,
			confirmAction: data.confirmAction,
			cancelAction: data.cancelAction
		})

		setConfirmDialogOpen(true)
	}

	const confirmDialogHandleClose = () => {
		setConfirmDialogOpen(false)
	}



	const editDialogHandleOpen = () => {
		setEditDialogOpen(true)
	}
	
	const editDialogHandleClose = () => {
		setEditDialogOpen(false)

		dispatch({
			type: "NEW_UPLOAD_ATTACHMENT",
			data: []
		})

		dispatch({
			type: "EXIST_UPLOAD_ATTACHMENT",
			data: []
		})

		dispatch({
			type: "RECIPIENT_USER_RECORD",
			data: []
		})

		dispatch({
			type: "RECIPIENT_USER_SEQ",
			data: []
		})
	}


	const saveNoticeConfirmHandleOpen = () => {
		confirmDialogHandleOpen({
			dialogTitle: "Edit Notice",
			dialogContent: "Are you sure to save change.",
			confirmAction: editNoticeAction,
			cancelAction: saveNoticeConfirmHandleClose
		})
	}

	const saveNoticeConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}


	const postDraftNoticeConfirmHandleOpen = () => {
		confirmDialogHandleOpen({
			dialogTitle: "Post Notice",
			dialogContent: "Are you sure post the draft to notice board.",
			confirmAction: postDraftNoticeAction,
			cancelAction: postDraftNoticeConfirmHandleClose
		})
	}

	const postDraftNoticeConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}



	const removeNoticeConfirmHandleOpen = () => {
		confirmDialogHandleOpen({
			dialogTitle: "Remove Notice",
			dialogContent: "Are you sure to remove the Notice.",
			confirmAction: removeNoticeAction,
			cancelAction: removeNoticeConfirmHandleClose
		})
	}

	const removeNoticeConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}



	// Action
	const editNoticeAction = () => {
		saveNoticeConfirmHandleClose()

		updateNotice(noticeSeq, newNoticeContentRedux, newUploadAttachmentRedux, existUploadAttachmentRedux).then(res => {
			buildAlert({
				// Alert Type: success, info, warning, error
				type: "success",
				title: "Success Alert",
				content: "Notice update successful."
			})

			setRefresh(res.code)

			dispatch({
				type: "RECIPIENT_USER_SEQ",
				data: null
			})
	
			dispatch({
				type: "RECIPIENT_USER_RECORD",
				data: null
			})

			dispatch({
				type: "NEW_NOTICE_CONTENT",
				data: null
			})

			dispatch({
				type: "NEW_UPLOAD_ATTACHMENT",
				data: []
			})

			dispatch({
				type: "EXIST_UPLOAD_ATTACHMENT",
				data: []
			})

			editDialogHandleClose()
			detailScreenClose()
		})
	}



	const postDraftNoticeAction = () => {
		postDraftNoticeConfirmHandleClose()

		const tempNoticeContent = {...newNoticeContentRedux}
		tempNoticeContent.status = "Y"

		createNotice(tempNoticeContent, newUploadAttachmentRedux, existUploadAttachmentRedux).then(res => {
			buildAlert({
				// Alert Type: success, info, warning, error
				type: "success",
				title: "Success Alert",
				content: "Create new notice successful."
			})

			setRefresh(res.code)

			dispatch({
				type: "NEW_NOTICE_CONTENT",
				data: null
			})

			dispatch({
				type: "RECIPIENT_USER_SEQ",
				data: null
			})
	
			dispatch({
				type: "RECIPIENT_USER_RECORD",
				data: null
			})

			dispatch({
				type: "NEW_UPLOAD_ATTACHMENT",
				data: []
			})

			dispatch({
				type: "EXIST_UPLOAD_ATTACHMENT",
				data: []
			})
		

			editDialogHandleClose()
			detailScreenClose()
		})

	}


	const removeNoticeAction = () => {
		removeNoticeConfirmHandleClose()

		removeNotice(noticeSeq).then(res => {
			buildAlert({
				// Alert Type: success, info, warning, error
				type: "success",
				title: "Success Alert",
				content: "Notice remove successful."
			})

			setRefresh(res.code)

			detailScreenClose()
		})
	}


	// Define Full Screen Dialog Button
	const noticeEditDialogBtn = [
		{
			buttonName: "Save",
			buttonFunction: saveNoticeConfirmHandleOpen,
		}
	]

	const draftEditDialogBtn = [
		{
			buttonName: "Save",
			buttonFunction: saveNoticeConfirmHandleOpen,
		},
		{
			buttonName: "Post",
			buttonFunction: postDraftNoticeConfirmHandleOpen,
		}
	]


	return(
		// console.log("--------------------------------------------------------"),
		// console.log("noticeSeq : ", noticeSeq),
		// console.log("newNoticeContentRedux : ", newNoticeContentRedux),
		// console.log("newUploadAttachmentRedux : ", newUploadAttachmentRedux),
		// console.log("existUploadAttachmentRedux : ", existUploadAttachmentRedux),
		// console.log("--------------------------------------------------------"),


		noticeDetail ?
			<Fragment>
				<Box>
					<Alert
						open={alertOpen}
						alertType={alertData.type}
						alertTitle={alertData.title}
						alertContent={alertData.content}
						handleClose={alertHandleClose}
					/>

					<ConfirmDialog
						open={confirmDialogOpen}
						dialogTitle={confirmDialogData.dialogTitle}
						dialogContent={confirmDialogData.dialogContent}
						confirmAction={confirmDialogData.confirmAction}
						cancelAction={confirmDialogData.cancelAction}
					/>

					<FullScreenDialog
						open={editDialogOpen}
						dialogTitle={`Edit Notice ${noticeDetail.status === "DRAFT" ? "(Draft)" : ""}`}
						formContent={
							<NoticeEdit 
								noticeDetail={noticeDetail}
							/>
						}
						button={noticeDetail.status === "Y" ? noticeEditDialogBtn : noticeDetail.status === "DRAFT" ? draftEditDialogBtn : ""}
						handleClose={editDialogHandleClose}
					/>
				</Box>

				<Box sx={noticeDetailStyle.titleArea}>
					<Grid container spacing={0}>
						<Grid item xs={12} sm={4} lg={2}>
							<Box sx={noticeDetailStyle.title}>From</Box>
						</Grid>
						<Grid item xs={12} sm={8} lg={10}>
							<Box sx={noticeDetailStyle.titleContent}>{noticeDetail.createuser}</Box>
						</Grid>

						<Grid item xs={12} sm={4} lg={2}>
							<Box sx={noticeDetailStyle.title}>Date</Box>
						</Grid>
						<Grid item xs={12} sm={8} lg={10}>
							<Box sx={noticeDetailStyle.titleContent}>{format(parseISO(noticeDetail.createdate), "yyyy-MM-dd")}</Box>
						</Grid>

						<Grid item xs={12} sm={4} lg={2}>
							<Box sx={noticeDetailStyle.title}>Subject</Box>
						</Grid>
						<Grid item xs={12} sm={8} lg={10}>
							<Box sx={noticeDetailStyle.titleContent}>{noticeDetail.subject}</Box>
						</Grid>

						<Grid item xs={12} sm={4} lg={2}>
							<Box sx={noticeDetailStyle.title}>Request Read</Box>
						</Grid>
						<Grid item xs={12} sm={8} lg={10}>
							<Box sx={noticeDetailStyle.titleContent}>{noticeDetail.requestRead ? "YES" : "NO"}</Box>
						</Grid>

						<FormControl component="fieldset" variant="standard" sx={noticeDetailStyle.fieldset}>
							<FormLabel component="legend">Attachment</FormLabel>
								<FileDropzone
									acceptFileType={null}
									maxFiles={0}
									DropzoneContent={DropzoneContentExist}

									enablePreview={false}
									enableUpload={false}
									enableDownload={true}
									enableDelete={false}

									onAcceptedAction={null}
									onRejectedAction={null}
									onPreviewAction={null}
									onDownloadAction={attachmentDownloadAction}
									onRemoveAction={null}
								/>
						</FormControl>
					</Grid>

					<Box sx={noticeDetailStyle.content}>
						<div dangerouslySetInnerHTML={{__html:noticeDetail.content}}></div>
					</Box>
				</Box>

				<Box sx={noticeDetailStyle.bottomToolbar}>
					<Stack direction="row" spacing={2}>
					{
						edit ?
							<Tooltip title="Edit">
								<Fab color="primary" size="small" aria-label="add" onClick={editDialogHandleOpen} sx={noticeDetailStyle.normalBtnStyle}>
									<EditIcon />
								</Fab>
							</Tooltip>
						: ""
					}

					{
						remove ?
							<Tooltip title="Remove">
								<Fab color="primary" size="small" aria-label="add" onClick={removeNoticeConfirmHandleOpen} sx={noticeDetailStyle.normalBtnStyle}>
									<DeleteOutlineOutlinedIcon />
								</Fab>
							</Tooltip>
						: ""
					}
					</Stack>
				</Box>

			</Fragment>
		: ""
	)
}



// Define MyNotice Content Screen
const MyNoticeDisplay = props => {
	const {noticeSeq, noticeDetail, noticeUser, edit, remove, detailScreenClose, setRefresh} = props

	const tabMenuProperty = [
		{
			tabIndex: 0,
			name: "Notice Content",
			form: <NoticeDisplay noticeSeq={noticeSeq} noticeDetail={noticeDetail} edit={edit} remove={remove} detailScreenClose={detailScreenClose} setRefresh={setRefresh} />
			
		},
		{
			tabIndex: 1,
			name: "Users Detail",
			form: <UserDetailDispaly noticeUser={noticeUser} />
		},
	]

	return(
		<Fragment>
			<TabMenu
				property={tabMenuProperty}
			/>
		</Fragment>
	)
}



// Define User Detail Screen
const UserDetailDispaly = props => {
	const {noticeUser} = props

	// Define State for Table
	const [order, setOrder] = useState('desc')   // asc, desc
	const [orderBy, setOrderBy] = useState('date')
	const [page, setPage] = useState(1)
	const [rowsPerPage, setRowsPerPage] = useState(20)


	// Table Title
	const title = "Post to User List"

	// Table Toolbar
	const EnhancedTableToolbar = props => {
		return (
			<Fragment>
				<Toolbar>
					<Box sx={{flexGrow: 1}}>
						<Typography	variant="body1"	sx={noticeDetailStyle.tableTitle}>
							<Box component='span' sx={noticeDetailStyle.tableTitleImage}>
								<img src={noticeBoardIcon} height="28" width="28"/>	
							</Box>
							{title}
						</Typography>
					</Box>
				</Toolbar>
			</Fragment>
		)
	}

	// Table Head Cell
	const headCells = [
		{
			id: "name_eng",
			numeric: false,
			disablePadding: true,
			label: "English Name",
		},
		{
			id: 'name_chi',
			numeric: false,
			disablePadding: false,
			label: 'Chinese Name',
		},
		{
			id: 'request_read',
			numeric: false,
			disablePadding: false,
			label: 'Request Read',
		},
		{
			id: 'read_status',
			numeric: false,
			disablePadding: false,
			label: 'Read Status',
		}
	]

	// Table Header
	const EnhancedTableHead = props => {
		const {order, orderBy, onRequestSort} = props

		const createSortHandler = property => event => {
			onRequestSort(event, property)
		}

		return (
			<TableHead>
				<TableRow>
					{headCells.map(headCell => (
						<StyledTableCell
							key={headCell.id}
							align={headCell.numeric ? 'right' : 'left'}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</Box>
								) : null}
							</TableSortLabel>
						</StyledTableCell>
					))}
				</TableRow>
			</TableHead>
		)
	}
	EnhancedTableHead.propTypes = {
		order: PropTypes.oneOf(['asc', 'desc']).isRequired,
		orderBy: PropTypes.string.isRequired,
		onRequestSort: PropTypes.func.isRequired
	}

	const descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}

		if (b[orderBy] > a[orderBy]) {
			return 1
		}

		return 0
	}
	
	const getComparator = (order, orderBy) => {
		return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
	}

	const sortRequestHandle = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

  	const pageChangeHandle = (event, newPage) => {
		setPage(newPage);
	}

	return(
		<Fragment>
			{
				noticeUser ?
					<Box>
						<Box sx={noticeDetailStyle.tableBody}>
							{/* <EnhancedTableToolbar /> */}

							<TableContainer>
								<Table
									aria-labelledby="tableTitle"
									size={"small"}
								>
									<colgroup>
										<col style={{width:'25%'}}/>
										<col style={{width:'25%'}}/>
										<col style={{width:'25%'}}/>
										<col style={{width:'25%'}}/>
									</colgroup>

									<EnhancedTableHead
										order={order}
										orderBy={orderBy}
										onRequestSort={sortRequestHandle}
									/>

									<TableBody>
										{/* <Box sx={noticeDetailStyle.tableBody}> */}
									{
										noticeUser.slice().sort(getComparator(order, orderBy)).slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row, index) => {
											return (
												<StyledTableRow
													hover
													key={index}
												>
													<StyledTableCell align="left">{row.nameEng}</StyledTableCell>
													<StyledTableCell align="left">{row.nameChi}</StyledTableCell>
													<StyledTableCell align="left">{row.requestRead ? "YES" : "No"}</StyledTableCell>
													<StyledTableCell align="left">{row.readStatus ? "Read" : "Not Read"}</StyledTableCell>
												</StyledTableRow>
											)
										})
									}
										{/* </Box> */}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>

						<Pagination count={Math.ceil(noticeUser.length / rowsPerPage)} variant="outlined" shape="rounded" onChange={pageChangeHandle} page={page} sx={noticeDetailStyle.tablePagination} />
					</Box>
				: ""
			}
		</Fragment>
	)
}


const NoticeDetail = props => {
	const {type, noticeSeq, edit, remove, detailScreenClose, setRefresh} = props
	const dispatch = useDispatch()

	// Define state
	const [noticeDetail, setNoticeDetail] = useState(null)
	const [noticeUser, setNoticeUser] = useState(null)

	const createData = (id, nameEng, nameChi, requestRead, readStatus) => {
		return {id, nameEng, nameChi, requestRead, readStatus}
	}


	useEffect(() => {
		getNoticeDetail(noticeSeq).then(res => {
			setNoticeDetail(res.data)

			dispatch({
				type: "EXIST_UPLOAD_ATTACHMENT",
				data: res.data.files
			})
		})

		if (type === "MY_NOTICE") {
			getNoticeUser(noticeSeq).then(lists => {
				let createDataList = []

				lists.data.map(list => {
					createDataList = [...createDataList, createData(list.user_seq, list.employee_name_eng, list.employee_name_chi, list.request_read, list.is_read)]
				})

				setNoticeUser(createDataList)
			})
		}
	}, [])


	return (
		// console.log("--------------------------------------------------------"),
		// console.log("[NoticeDetail.js]"),
		// console.log("noticeDetail : ", noticeDetail),
		// console.log("noticeUser : ", noticeUser),
		// console.log("--------------------------------------------------------"),


		<Fragment>
			{
				type === "NOTICEBOARD" ?
					<NoticeDisplay 
						noticeSeq={noticeSeq}
						noticeDetail={noticeDetail}
						edit={edit}
						remove={remove}
						detailScreenClose={detailScreenClose}
						setRefresh={null}
					/>
				: type === "MY_NOTICE" ?
					<MyNoticeDisplay
						noticeSeq={noticeSeq}
						noticeDetail={noticeDetail}
						noticeUser={noticeUser}
						edit={edit}
						remove={remove}
						detailScreenClose={detailScreenClose}
						setRefresh={setRefresh}
					/>
				: ""
			}
		</Fragment>
	)
}

export default NoticeDetail
