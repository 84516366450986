import React, {Fragment, useState} from "react"

// Material UI
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'

// Custom Style
import windowsStyle from './styles/windowsStyle'

const Windows = props => {
	const {icon, title, content, loading} = props

	return (
		<Fragment>
			<Paper elevation={2} sx={windowsStyle.windows}>
				<Stack direction="row" spacing={1} sx={windowsStyle.header}>
					<img src={icon} height="25" width="25"/>
					<Box sx={windowsStyle.headerText}>
						{title}
					</Box>
				</Stack>
				<Paper elevation={0} square sx={windowsStyle.headerDivider} />

				<Box sx={windowsStyle.content}>
					{loading}
					{content}
				</Box>
			</Paper>
		</Fragment>
	)
}

export default Windows
