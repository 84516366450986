import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Divider,
  Checkbox,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Typography,
} from "@mui/material";
import RoomBookingContext from "../../RoomBookingContext";
import SectionItem from "../../SectionItem";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { getToday, getWeekdays, dateToDisplay } from "../../utilFunc";
import InfoSection from "./InfoSection";
import { insertRoomBookingRecord } from "../../../../data_source/roombook";
import CustomBackdrop from "../../CustomBackdrop";
import Alert from "../../../Alert/Alert";
import ActionResponse from "../../ActionResponse";
import { dateStyles } from "../../dateStyles";

const ScheduleForm = ({ setFlag, handleClose }) => {
  const { bookingRoom, timePeriod } = useContext(RoomBookingContext);
  const { selectedRoom } = bookingRoom;
  const { timePeriodConfig } = timePeriod;
  const [fromDate, setFromDate] = useState(getToday());
  const [toDate, setToDate] = useState(getToday());
  const [timeFrom, setTimeFrom] = useState("");
  const [timeTo, setTimeTo] = useState("");
  const [weekdays, setWeekdays] = useState([]);
  const [loading, setLoading] = useState(false);
  // Define Alert dialog state
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    title: "",
    content: "",
  });
  const [responseOpen, setResponseOpen] = useState(false);
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    const init = () => {
      const data = getWeekdays().map((weekday) => ({
        ...weekday,
        checked: false,
      }));
      setWeekdays(data);
    };
    init();
  }, []);

  const handleTimeFrom = (e) => {
    setTimeFrom(e.target.value);
  };
  const handleTimeTo = (e) => {
    setTimeTo(e.target.value);
  };

  const handleCheckbox = (e) => {
    const tmpWeekdays = weekdays.map((w) => {
      if (w.day === e.target.name) {
        w.checked = !w.checked;
      }
      return w;
    });
    setWeekdays(tmpWeekdays);
  };

  const handleSave = async (extension, remark) => {
    const dateRange = getDateRange(fromDate, toDate, weekdays);
    const timeRange = getTimeRange(timeFrom, timeTo);
    const records = getRecords(dateRange, timeRange, remark, extension);

    const data = {
      roombook_seq: selectedRoom.roombook_seq,
      records: records,
    };

    setLoading(true);
    await insertRoomBookingRecord(data)
      .then((res) => {
        setResponseOpen(true);
        setResponseData(res.data);
      })
      .catch((error) => {
        // buildAlert({
        //   // Alert Type: success, info, warning, error
        //   type: "error",
        //   title: "Error Alert",
        //   content: `${error.message}`,
        // });
        setResponseOpen(true);
        setResponseData({
          success: [],
          fail: [
            {
              message:
                "Some selected time periods have been reserved. Please try again.",
            },
          ],
        });
      });
    setLoading(false);
    setFlag((prev) => !prev);
  };

  const getDateRange = (start, end, weekdays) => {
    let dateArray = [];
    let current = new Date(start.getTime());
    let targetWeekdays = weekdays
      .filter((w) => w.checked)
      .map((obj) => Number(obj.day));

    while (current <= end) {
      if (targetWeekdays.includes(current.getDay())) {
        dateArray.push(current);
      }
      const tmp = new Date(current.getTime());
      current = new Date(tmp.setDate(tmp.getDate() + 1));
    }
    return dateArray;
  };

  const getTimeRange = (start, end) => {
    const start24 = timeStrTo24Format(start);
    const end24 = timeStrTo24Format(end);

    const timeRange = timePeriodConfig.filter((tp) => {
      return (
        timeStrTo24Format(tp.time_from) >= start24 &&
        timeStrTo24Format(tp.time_to) <= end24
      );
    });

    return timeRange;
  };

  const getRecords = (dateRange, timeRange, remark, extension) => {
    let records = dateRange
      .map((d) => {
        const tmp = timeRange.map((t) => ({
          ...t,
          book_date: dateToDisplay(d),
          english_name: "",
          chinese_name: "",
          extension: extension,
          remark: remark,
        }));
        return tmp;
      })
      .flat();
    return records;
  };

  const timeStrTo24Format = (timeStr) => {
    const pm =
      timeStr.slice(-2).toUpperCase() === "PM" && timeStr.slice(0, 2) !== "12";
    if (pm) {
      return (
        (Number(timeStr.slice(0, 2)) + 12).toString() + timeStr.slice(2, 5)
      );
    }
    return timeStr;
  };

  // Build Alert
  const buildAlert = (data) => {
    setAlertData({
      type: data.type,
      title: data.title,
      content: data.content,
    });
    setAlertOpen(true);
  };

  const alertHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <Box>
      <Alert
        open={alertOpen}
        alertType={alertData.type}
        alertTitle={alertData.title}
        alertContent={alertData.content}
        handleClose={alertHandleClose}
      />
      <ActionResponse
        responseOpen={responseOpen}
        setResponseOpen={setResponseOpen}
        responseData={responseData}
        setResponseData={setResponseData}
        callbackFunc={handleClose}
      />
      <SectionItem header="Booking Information">
        {loading && <CustomBackdrop text="Processing" />}
        <Box>
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={12} lg={8} xl={6}>
              <Grid container>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{ color: "#00000099", pl: 3, textAlign: "center" }}
                    >
                      Period From ({dateToDisplay(fromDate)})
                    </Typography>
                    <Box sx={dateStyles}>
                      <StaticDatePicker
                        orientation="landscape"
                        openTo="day"
                        disableOpenPicker
                        showToolbar={false}
                        value={fromDate}
                        onChange={(newValue) => {
                          setFromDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        componentsProps={{
                          actionBar: {
                            actions: [],
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{ color: "#00000099", pl: 3, textAlign: "center" }}
                    >
                      Period To ({dateToDisplay(toDate)})
                    </Typography>
                    <Box sx={dateStyles}>
                      <StaticDatePicker
                        orientation="landscape"
                        openTo="day"
                        disableOpenPicker
                        showToolbar={false}
                        value={toDate}
                        onChange={(newValue) => {
                          setToDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        shouldDisableDate={(date) => {
                          return date < fromDate;
                        }}
                        componentsProps={{
                          actionBar: {
                            actions: [],
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} xl={6}>
              <Grid container sx={{ px: 3, py: 1 }} spacing={2}>
                <Grid item xs={12} sm={6} lg={12} xl={6}>
                  <FormControl size="small" sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Time Period (From)
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={timeFrom}
                      label="Time Period (From)"
                      onChange={handleTimeFrom}
                      size="small"
                    >
                      {timePeriodConfig.map((obj, index) => (
                        <MenuItem key={index} value={obj.time_from}>
                          {obj.time_from}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={12} xl={6}>
                  <FormControl size="small" sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Time Period (To)
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={timeTo}
                      label="Time Period (To)"
                      onChange={handleTimeTo}
                      size="small"
                    >
                      {timePeriodConfig.map((obj, index) => (
                        <MenuItem key={index} value={obj.time_to}>
                          {obj.time_to}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    sx={{ mt: 2 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel component="legend" focused={false}>
                      For each
                    </FormLabel>
                    <FormGroup row>
                      {weekdays.map((w, i) => (
                        <FormControlLabel
                          key={i}
                          control={
                            <Checkbox
                              checked={w.checked}
                              name={w.day}
                              onChange={handleCheckbox}
                            />
                          }
                          label={w.label}
                          sx={{ width: 80 }}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Divider />
        <InfoSection
          handleSave={handleSave}
          period={`${dateToDisplay(fromDate)} - ${dateToDisplay(toDate)}`}
        />
      </SectionItem>
    </Box>
  );
};

export default ScheduleForm;
