import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ActionButton = ({
  color = "primary",
  title,
  content,
  actionFunc,
  validateFunc = null,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleValidate = () => {
    if (validateFunc === null) {
      setConfirmOpen(true);
    } else {
      validateFunc() && setConfirmOpen(true);
    }
  };

  const handleAction = () => {
    setConfirmOpen(false);
    actionFunc();
  };

  return (
    <div>
      <Button color={color} onClick={handleValidate}>
        {title}
      </Button>
      <Dialog
        open={confirmOpen}
        maxWidth={"sm"}
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableEnforceFocus
      >
        <DialogTitle id="alert-dialog-slide-title">{content}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActionButton;
