import React, {Fragment, useState} from "react"

// Material UI
// import Box from '@mui/material/Box'
// import Tooltip from '@mui/material/Tooltip'
// import IconButton from '@mui/material/IconButton'
// import Button from '@mui/material/Button'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'
// import ListItemIcon from '@mui/material/ListItemIcon'
// import Typography from '@mui/material/Typography'
// import Stack from '@mui/material/Stack'
// import Divider from '@mui/material/Divider'
// import Paper from '@mui/material/Paper'
// import Grid from '@mui/material/Grid'


// Custom Components

// Custom Style
import knowHowStyle from './styles/knowHowStyle'


const KnowHow = () => {

	return (
		<Fragment>
			<div>Coming Soon</div>		
		</Fragment>
	)
}

export default KnowHow
