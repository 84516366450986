import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from "react-router-dom"
import {Provider} from "react-redux"
import store from './store/store'
import reportWebVitals from './reportWebVitals'

// Custom Component
import App from './App'
import MainApp from './components/Main/MainApp'


ReactDOM.render(
	<div>
		<BrowserRouter>
			<Provider store={store}>
				<App />
			</Provider>
		</BrowserRouter>

		<BrowserRouter basename="/main">
			<Provider store={store}>
				<MainApp />
			</Provider>
		</BrowserRouter>
	</div>,

	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
