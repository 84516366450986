import React, {Fragment, useState} from "react"
import PropTypes from 'prop-types'

// MUI
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'


const TabPanel = props => {
	const {children, value, index, ...other} = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}


const a11yProps = index => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

const TabMenu = props => {
	const {property} = props

  	const [value, setValue] = useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<Fragment>
			<AppBar position="static" color="default">
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
					{
						property.map((prop, index) => {
							return (
								<Tab label={prop.name} {...a11yProps(prop.tabIndex)} key={index} />
							)
						})
					}
				</Tabs>
			</AppBar>

			{
				property.map((prop, index) => {
					return (
						<TabPanel value={value} index={prop.tabIndex} key={index}>
							{prop.form}
						</TabPanel>
					)
				})
			}
		</Fragment>
	)
}


export default TabMenu