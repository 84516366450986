import React from "react";
import { Box, Typography, Chip } from "@mui/material";

const EquipmentSection = ({ equipment, capacity }) => {
  return (
    <Box>
      <Typography sx={{ fontSize: "1.125rem", fontWeight: "bold", py: 1 }}>
        Equipments:
      </Typography>
      {equipment.map((equip, index) => (
        <Typography key={index} sx={{ textTransform: "capitalize" }}>{`${
          index + 1
        }. ${equip}`}</Typography>
      ))}
      <Typography
        sx={{ textTransform: "capitalize", mt: 0.5 }}
      >{`Capacity: ${capacity}`}</Typography>
    </Box>
  );
};

export default EquipmentSection;
