const Signout = () => {
	localStorage.removeItem("LOGGED_IN")
	localStorage.removeItem("TOKEN_TYPE")
	localStorage.removeItem("ACCESS_TOKEN")
	localStorage.removeItem("REFRESH_TOKEN")
	localStorage.removeItem("USER_SEQ")
	localStorage.removeItem("USERNAME")
	localStorage.removeItem("EMPLOYEE_ID")
	localStorage.removeItem("FULLNAME_ENG")
	localStorage.removeItem("MENU")
	localStorage.removeItem("ROLE")


	console.log("Sign Out Successful")
	console.log("LOGGED_IN : ", localStorage.getItem("LOGGED_IN"))
	console.log("TOKEN_TYPE : ", localStorage.getItem("TOKEN_TYPE"))
	console.log("ACCESS_TOKEN : ", localStorage.getItem("ACCESS_TOKEN"))
	console.log("REFRESH_TOKEN : ", localStorage.getItem("REFRESH_TOKEN"))
	console.log("USER_SEQ : ", localStorage.getItem("USER_SEQ"))
	console.log("USERNAME : ", localStorage.getItem("USERNAME"))
	console.log("EMPLOYEE_ID : ", localStorage.getItem("EMPLOYEE_ID"))
	console.log("FULLNAME_ENG : ", localStorage.getItem("FULLNAME_ENG"))
	console.log("MENU : ", localStorage.getItem("MENU"))
	console.log("ROLE : ", localStorage.getItem("ROLE"))
	console.log("=========================================================")
}

export default Signout