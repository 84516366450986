import React, {useState} from "react"
import Loading from "./Loading"

const UseLoading = () => {
	const [visible, setVisible] = useState(false)
	const loading = visible ? <Loading /> : null

	const showLoading = () => setVisible(true)
	const hideLoading = () => setVisible(false)
	
	return [loading, showLoading, hideLoading]
}


export default UseLoading