import axios from "axios"
import urlencode from "urlencode"

const getInfoBoardLayer = async () => {
	const getInfoBoardLayer_api = "/infoboard/layer/list"
	// let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
			// "content-type": "multipart/form-data",
		}

		// params.append("user_seq", localStorage.getItem("USER_SEQ"))

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_INFORMATION_API_URL + getInfoBoardLayer_api,
			// params: params,
		})

		return result.data
	}
	catch (error) {
		if (error.response.status === 401) {
			window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
		}
		else {
			throw new Error(error)
		}
	}
}


const getLatestInfoList = async () => {
	const getLatestInfoList_api = "/infoboard/info/latest"
	// let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
			// "content-type": "multipart/form-data",
		}

		// params.append("user_seq", localStorage.getItem("USER_SEQ"))

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_INFORMATION_API_URL + getLatestInfoList_api,
			// params: params,
		})

		return result.data
	}
	catch (error) {
		if (error.response.status === 401) {
			window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
		}
		else {
			throw new Error(error)
		}
	}
}


const getInfoContent = async layerSeq => {
	const getInfoContent_api = "/infoboard/info/list"
	let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
			// "content-type": "multipart/form-data",
		}

		params.append("layer_seq", layerSeq)

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_INFORMATION_API_URL + getInfoContent_api,
			params: params,
		})

		return result.data
	}
	catch (error) {
		if (error.response.status === 401) {
			window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
		}
		else {
			throw new Error(error)
		}
	}
}


const getInfoContentDetail = async infoSeq => {
	const getInfoContentDetail_api = "/infoboard/info/details"
	let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
			// "content-type": "multipart/form-data",
		}

		params.append("info_seq", infoSeq)

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_INFORMATION_API_URL + getInfoContentDetail_api,
			params: params,
		})

		return result.data
	}
	catch (error) {
		if (error.response.status === 401) {
			window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
		}
		else {
			throw new Error(error)
		}
	}
}


const createInformation = async (infoContent, files) => {
	const createInformation_api = "/infoboard/info/create"
	const formData = new FormData()

	formData.append("json", JSON.stringify(infoContent))

	if (files) {
		files.map(file => {
			formData.append("files", file)
		})
	}

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
			"content-type": "multipart/form-data",
		}

		const result = await axios({
			headers: headers,
			method: "post",
			url: process.env.REACT_APP_INFORMATION_API_URL + createInformation_api,
			data: formData
			
		})

		return result.data
	}
	catch (error) {
		if (error.response.status === 401) {
			window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
		}
		else {
			return error.response.data
		}
	}
}


const createDraftInformation = async (infoContent, files) => {
	const createInformation_api = "/infoboard/info/save"
	const formData = new FormData()

	formData.append("json", JSON.stringify(infoContent))

	if (files) {
		files.map(file => {
			formData.append("files", file)
		})
	}

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
			"content-type": "multipart/form-data",
		}

		const result = await axios({
			headers: headers,
			method: "post",
			url: process.env.REACT_APP_INFORMATION_API_URL + createInformation_api,
			data: formData
			
		})

		return result.data
	}
	catch (error) {
		if (error.response.status === 401) {
			window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
		}
		else {
			return error.response.data
		}
	}
}


const saveEditInformation = async (infoContent, newFiles, existFiles) => {
	const createInformation_api = "/infoboard/info/edit"
	const formData = new FormData()

	infoContent.files = existFiles
	formData.append("json", JSON.stringify(infoContent))

	if (newFiles) {
		newFiles.map(file => {
			formData.append("files", file)
		})
	}

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
			"content-type": "multipart/form-data",
		}

		const result = await axios({
			headers: headers,
			method: "post",
			url: process.env.REACT_APP_INFORMATION_API_URL + createInformation_api,
			data: formData
			
		})

		return result.data
	}
	catch (error) {
		if (error.response.status === 401) {
			window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
		}
		else {
			return error.response.data
		}
	}
}


const publishDraftInformation = async (infoContent, newFiles, existFiles) => {
	const createInformation_api = "/infoboard/info/publish"
	const formData = new FormData()

	infoContent.files = existFiles
	formData.append("json", JSON.stringify(infoContent))

	if (newFiles) {
		newFiles.map(file => {
			formData.append("files", file)
		})
	}

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
			"content-type": "multipart/form-data",
		}

		const result = await axios({
			headers: headers,
			method: "post",
			url: process.env.REACT_APP_INFORMATION_API_URL + createInformation_api,
			data: formData
			
		})

		return result.data
	}
	catch (error) {
		if (error.response.status === 401) {
			window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
		}
		else {
			return error.response.data
		}
	}
}


const removeInformation = async params => {
	const removeInformation_api = "/infoboard/info/remove"
	// let params = new URLSearchParams()

	// params.append("info_seq", infoSeq)

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		const result = await axios({
			headers: headers,
			method: "delete",
			url: process.env.REACT_APP_INFORMATION_API_URL + removeInformation_api,
			data: params,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


const attachmentDownload = async (atthSeq) => {
	const attachmentDownload_api = "/infoboard/attachment/download"
	let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}
		
		params.append("atth_seq", atthSeq)

		const response = await axios({
			method: "get",
			// url: parameter,
			url: process.env.REACT_APP_INFORMATION_API_URL + attachmentDownload_api,
			headers: headers,
			params: params,
			responseType: "blob",
		})

		const blob = new Blob([response.data], { type: response.data.type })
		const downloadUrl = window.URL.createObjectURL(blob)
		const link = document.createElement("a")
		link.href = downloadUrl
		const header_filename = response.headers["download-filename"]
		let filename
		if (header_filename) {
			filename = header_filename
		}
		link.setAttribute("download", urlencode.decode(filename))
		document.body.appendChild(link)
		link.click()
		link.remove()
	}
	catch (error) {
		throw new Error(error)
	}
}


export {
	getInfoBoardLayer,
	getLatestInfoList,
	getInfoContent,
	getInfoContentDetail,
	
	createInformation,
	createDraftInformation,
	saveEditInformation,
	publishDraftInformation,

	removeInformation,
	attachmentDownload
}