import React, {Fragment, useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {format, parseISO} from "date-fns"
import PropTypes from 'prop-types'

// Material UI
import {styled, alpha} from "@mui/material/styles"
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Fab from '@mui/material/Fab'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'

// Material utils
import {visuallyHidden} from '@mui/utils'

// Material Icon
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

// Customer Components
import Alert from "../Alert/Alert"
import ConfirmDialog from "../Dialog/ConfirmDialog"
import FullScreenDialog from "../Dialog/FullScreenDialog"
import UseLoading from "../Loading/UseLoading"
import NoticeCreate from "./NoticeCreate"
import NoticeDetail from "./NoticeDetail"

// Customer Icon
import noticeBoardIcon from "../../img/icon-noticeboard.png"

import newMsgIcon from "../../img/icon-news.png"
import reqReadIcon from "../../img/icon-read.png"

// Custom Style
import globalStyle from "../../styles/globalStyle"
import noticeboardListStyle from './styles/noticeboardListStyle'

// Fetch Data
import {getNoticeList, setNoticeRead, createNotice, saveDraftNotice} from "../../data_source/noticeboard"


// Define Custom TableCell Style
const StyledTableCell = styled(TableCell)(({theme}) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: globalStyle.dodgerBlueColor[8],
		color: theme.palette.common.white,
		fontSize: 16,
		fontWeight: "bold"
	},

	[`&.${tableCellClasses.body}`]: {
	  fontSize: 14
	}
}))

// Define Custom TableRow Style
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: alpha(globalStyle.dodgerBlueColor[0], 0.5)
	},
  
	"td, th": {
		border: 0,
		cursor: "pointer"
	}
}))


const NoticeboardList = () => {
	const dispatch = useDispatch()

	// Define Loading
	const [getNoticeListLoading, getNoticeListShowLoading, getNoticeListHiddenLoading] = UseLoading()

	// Define Redux
	const newNoticeContentRedux = useSelector(state => state.newNoticeContentRedux)
	const newUploadAttachmentRedux = useSelector(state => state.newUploadAttachmentRedux)

	// Define State
	const [allList, setAllList] = useState([])
	const [noticeSeq, setNoticeSeq] = useState("")
	const [noticeboardReload, setNoticeboardReload] = useState("")
	const [createNoticeDialogOpen, setCreateNoticeDialogOpen] = useState(false)
	const [detailNoticeDialogOpen, setDetailNoticeDialogOpen] = useState(false)

	// Define State for MUI Table
	const [order, setOrder] = useState('desc')   // asc, desc
	const [orderBy, setOrderBy] = useState('date')
	const [page, setPage] = useState(1)
	const [rowsPerPage, setRowsPerPage] = useState(20)
	
	// Define Alert
	const [alertOpen, setAlertOpen] = useState(false)
	const [alertData, setAlertData] = useState({
		type: "",
		title: "",
		content: ""
	})

	// Build Alert 
	const buildAlert = (data) => {
		setAlertData({
			type: data.type,
			title: data.title,
			content: data.content
		})
		setAlertOpen(true)
	}

	// Define Confirm Dialog
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
	const [confirmDialogData, setConfirmDialogData] = useState({
		dialogTitle: "",
		dialogContent: "",
		confirmAction: "",
		cancelAction: ""
	})


	// Table Title
	const title = "Notice Board"

	const headCells = [
		{
			id: "subject",
			numeric: false,
			disablePadding: true,
			label: "Subject",
		},
		{
			id: 'sender',
			numeric: false,
			disablePadding: false,
			label: 'Sender',
		},
		{
			id: 'date',
			numeric: false,
			disablePadding: false,
			label: 'Date',
		}
	]

	const createData = (id, newMsg, reqRead, subject, sender, date) => {
		return {id, newMsg, reqRead, subject, sender, date,}
	}

	// Table Toolbar
	const EnhancedTableToolbar = props => {
		return (
			<Fragment>
				<Toolbar>
					<Box sx={{flexGrow: 1}}>
						<Typography	variant="body1"	sx={noticeboardListStyle.title}>
							<Box component='span' sx={noticeboardListStyle.titleImage}>
								<img src={noticeBoardIcon} height="28" width="28"/>	
							</Box>
							{title}
						</Typography>
					</Box>

					<Tooltip title="Create Notice">
						<Fab color="primary" size="small" aria-label="add" sx={noticeboardListStyle.icon} onClick={createNoticeAction}>
							<AddOutlinedIcon />
						</Fab>
					</Tooltip>
				</Toolbar>
			</Fragment>
		)
	}

	// Table Header
	const EnhancedTableHead = props => {
		const {order, orderBy, onRequestSort} = props

		const createSortHandler = property => event => {
			onRequestSort(event, property)
		}

		return (
			<TableHead>
				<TableRow>
					{headCells.map(headCell => (
						<StyledTableCell
							key={headCell.id}
							align={headCell.numeric ? 'right' : 'left'}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</Box>
								) : null}
							</TableSortLabel>
						</StyledTableCell>
					))}
				</TableRow>
			</TableHead>
		)
	}

	EnhancedTableHead.propTypes = {
		order: PropTypes.oneOf(['asc', 'desc']).isRequired,
		orderBy: PropTypes.string.isRequired,
		onRequestSort: PropTypes.func.isRequired
	}


	const descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}

		if (b[orderBy] > a[orderBy]) {
			return 1
		}

		return 0
	}

	const getComparator = (order, orderBy) => {
		return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
	}

	const sortRequestHandle = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}
  
	const pageChangeHandle = (event, newPage) => {
		setPage(newPage);
	}


	
	// Define confirm Dialog Open and Close
	const confirmDialogHandleOpen = (data) => {
		setConfirmDialogData({
			dialogTitle: data.dialogTitle,
			dialogContent: data.dialogContent,
			confirmAction: data.confirmAction,
			cancelAction: data.cancelAction
		})

		setConfirmDialogOpen(true)
	}

	const confirmDialogHandleClose = () => {
		setConfirmDialogOpen(false)
	}


	// Handle full screen dialog open and close
	const noticeCreateDialogHandleOpen = () => {
		setCreateNoticeDialogOpen(true)
	}
	
	const noticeCreateDialogHandleClose = () => {
		setCreateNoticeDialogOpen(false)

		dispatch({
			type: "NEW_UPLOAD_ATTACHMENT",
			data: []
		})

		dispatch({
			type: "EXIST_UPLOAD_ATTACHMENT",
			data: []
		})

		dispatch({
			type: "RECIPIENT_USER_RECORD",
			data: []
		})

		dispatch({
			type: "RECIPIENT_USER_SEQ",
			data: []
		})
	}


	const noticeDetailDialogHandleOpen = () => {
		setDetailNoticeDialogOpen(true)
	}
	
	const noticeDetailDialogHandleClose = () => {
		setDetailNoticeDialogOpen(false)

		dispatch({
			type: "NEW_UPLOAD_ATTACHMENT",
			data: []
		})

		dispatch({
			type: "EXIST_UPLOAD_ATTACHMENT",
			data: []
		})
	}


	// Handle confirm Dialog Open and Close
	const noticePostConfirmHandleOpen = () => {
		confirmDialogHandleOpen({
			dialogTitle: "Post Notice",
			dialogContent: "Are you sure to post the notice.",
			confirmAction: postNoticeAction,
			cancelAction: noticePostConfirmHandleClose
		})
	}

	const noticePostConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}


	const noticeSaveDraftConfirmHandleOpen = () => {
		confirmDialogHandleOpen({
			dialogTitle: "Save Draft",
			dialogContent: "Are you sure to save the notice to Draft.",
			confirmAction: saveDraftNoticeAction,
			cancelAction: noticeSaveDraftConfirmHandleClose
		})
	}

	const noticeSaveDraftConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}


	const selectedHandleClick = (event, id) => {
		setNoticeSeq(id)
		readDetailNoticeAction(id)
	}



	// Action
	const postNoticeAction = () => {
		noticePostConfirmHandleClose()

		createNotice(newNoticeContentRedux, newUploadAttachmentRedux).then(res => {
			setNoticeboardReload(res.code)

			buildAlert({
				// Alert Type: success, info, warning, error
				type: "success",
				title: "Success Alert",
				content: "Create new notice successful."
			})

			dispatch({
				type: "NEW_NOTICE_CONTENT",
				data: null
			})

			dispatch({
				type: "RECIPIENT_USER_SEQ",
				data: null
			})
	
			dispatch({
				type: "RECIPIENT_USER_RECORD",
				data: null
			})

			dispatch({
				type: "NEW_UPLOAD_ATTACHMENT",
				data: []
			})

			dispatch({
				type: "EXIST_UPLOAD_ATTACHMENT",
				data: []
			})

			noticeCreateDialogHandleClose()
		})
	}


	const saveDraftNoticeAction = () => {
		noticeSaveDraftConfirmHandleClose()

		saveDraftNotice(newNoticeContentRedux, newUploadAttachmentRedux).then(res => {
			setNoticeboardReload(res.code)

			buildAlert({
				// Alert Type: success, info, warning, error
				type: "success",
				title: "Success Alert",
				content: "Save the notice to draft successful."
			})

			dispatch({
				type: "NEW_NOTICE_CONTENT",
				data: null
			})

			dispatch({
				type: "RECIPIENT_USER_SEQ",
				data: null
			})
	
			dispatch({
				type: "RECIPIENT_USER_RECORD",
				data: null
			})

			dispatch({
				type: "NEW_UPLOAD_ATTACHMENT",
				data: []
			})

			dispatch({
				type: "EXIST_UPLOAD_ATTACHMENT",
				data: []
			})

			noticeCreateDialogHandleClose()
		})
	}


	const createNoticeAction = () => {
		noticeCreateDialogHandleOpen()
	}


	const readDetailNoticeAction = (selectNoticeSeq) => {
		noticeDetailDialogHandleOpen()

		const params = {
			notice_seq: selectNoticeSeq,
			user_seq: localStorage.getItem("USER_SEQ")
		}


		setNoticeRead(params).then(res => {
			console.log("Set Notice Read : ", res)
		})
	}



	// Define Full Screen Dialog Button
	const createNoticeDialogBtn = [
		{
			buttonName: "Save As Draft",
			buttonFunction: noticeSaveDraftConfirmHandleOpen,
		},
		{
			buttonName: "POST",
			buttonFunction: noticePostConfirmHandleOpen,
		}
	]

	const detailNoticeDialogBtn = []


	useEffect(() => {
		getNoticeListShowLoading()
		getNoticeList().then(lists => {
			let createDataList = []

			lists.data.map(list => {
				createDataList = [...createDataList, createData(list.notice_seq, list.isNew, list.requestRead, list.subject, list.createuser, format(parseISO(list.createdate), "yyyy-MM-dd"))]
			})

			setAllList(createDataList)
			getNoticeListHiddenLoading()
		})
	}, [noticeboardReload])

	
	return (
		<Fragment>
			<Box>
				<Alert
					open={alertOpen}
					alertType={alertData.type}
					alertTitle={alertData.title}
					alertContent={alertData.content}
					handleClose={() => setAlertOpen(false)}
				/>

				<ConfirmDialog
					open={confirmDialogOpen}
					dialogTitle={confirmDialogData.dialogTitle}
					dialogContent={confirmDialogData.dialogContent}
					confirmAction={confirmDialogData.confirmAction}
					cancelAction={confirmDialogData.cancelAction}
				/>

				<FullScreenDialog
					open={createNoticeDialogOpen}
					dialogTitle={"Create Notice"}
					formContent={
						<NoticeCreate 
							formType={"CREATE"}
						/>
					}
					button={createNoticeDialogBtn}
					handleClose={noticeCreateDialogHandleClose}
				/>

				<FullScreenDialog
					open={detailNoticeDialogOpen}
					dialogTitle={"Notice Detail"}
					formContent={
						<NoticeDetail
							type={"NOTICEBOARD"}
							noticeSeq={noticeSeq}
							edit={false}
						/>
					}
					button={detailNoticeDialogBtn}
					handleClose={noticeDetailDialogHandleClose}
				/>
			</Box>

			<Box>
				<EnhancedTableToolbar />
		  		<TableContainer sx={noticeboardListStyle.container}>
					<Table
						aria-label="tableTitle"
						size={"small"}
					>
						<colgroup>
							<col style={{width:'75%'}}/>
							<col style={{width:'15%'}}/>
							<col style={{width:'10%'}}/>
						</colgroup>
						
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={sortRequestHandle}
						/>

						<TableBody>
							{getNoticeListLoading}
							{
								allList ?
									allList.slice().sort(getComparator(order, orderBy)).slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row, index) => {
										return (
											<StyledTableRow
												hover
												key={index}
												onClick={event => selectedHandleClick(event, row.id)}
											>
												<StyledTableCell align="left">
													<Stack direction="row" spacing={2}>
														<Box sx={{pr:1}}>{row["subject"]}</Box>

														<Tooltip title="Request Read">
															<Box>{row.reqRead ? <img src={reqReadIcon} height="16" width="16" /> : ""}</Box>
														</Tooltip>

														<Tooltip title="The post within 7 days">
															<Box>{row.newMsg ? <img src={newMsgIcon} height="16" width="25" /> : ""}</Box>
														</Tooltip>
													</Stack>
												</StyledTableCell>
												
												<StyledTableCell align="left">{row.sender}</StyledTableCell>
												<StyledTableCell align="left">{row.date}</StyledTableCell>
											</StyledTableRow>
										)
									})
								: ""
							}
						</TableBody>
					</Table>
		  		</TableContainer>
		
				<Pagination count={Math.ceil(allList.length / rowsPerPage)} variant="outlined" shape="rounded" onChange={pageChangeHandle} page={page} sx={noticeboardListStyle.pagination} />
			</Box>
		</Fragment>
	)

}

export default NoticeboardList
