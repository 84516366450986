import globalStyle from "../../../styles/globalStyle"

const noticeDetailStyle = {
	titleArea: {
		minHeight: 500,
		marginBottom: 5,
		borderTop: 1,
		borderBottom: 1,
		borderColor: globalStyle.grayColor[2],
	},

	title: {
		fontFamily: "Arial, Helvetica, sans-serif",
		background: globalStyle.aquamarine[0],
		paddingTop: 0.5,
		paddingBottom: 0.5,
		paddingLeft: 2,
		paddingRight: 2,
		borderBottom: 1,
		borderColor: globalStyle.grayColor[2],
	},

	titleContent: {
		fontFamily: "Arial, Helvetica, sans-serif",
		paddingTop: 0.5,
		paddingBottom: 0.5,
		paddingLeft: 2,
		paddingRight: 2,
		borderBottom: 1,
		borderColor: globalStyle.grayColor[2],
	},

	content: {
		fontFamily: "Arial, Helvetica, sans-serif",
		paddingTop: 2,
		paddingBottom: 2,
		paddingLeft: 4,
		paddingRight: 4,
	},



	tableTitle: {
		display: "flex",
		alignItems: "center",
		color: globalStyle.grayColor[10],
		fontSize: 20,
	},

	TableTitleImage: {
		display: "flex",
		alignItems: "center",
		paddingRight: 1
	},

	tableBody: {
		height: 700,
	},

	tableIcon: {
		background: globalStyle.oceanBlueColor[8],

		'&:hover': {
			backgroundColor: globalStyle.oceanBlueColor[12],
		},
	},

	TablePagination: {
		paddingTop: 1,
		paddingBottom: 1,
		paddingLeft: 1,
		paddingRight: 1
	},

	fieldset: {

		width: "100%",
		minHeight: 100,
		marginTop: 1,
		paddingTop: 1,
		paddingBottom: 1,
		paddingLeft: 2,
		paddingRight: 2,

		border: 1,
		borderRadius: 0,
		borderColor: globalStyle.grayColor[4]
	},

	fileContent: {
		fontFamily: "Arial, Helvetica, sans-serif",

		cursor: "pointer",
		paddingTop: 0.5,
		paddingBottom: 0.5,
		paddingLeft: 2,
		paddingRight: 2,
	},


	normalBtnStyle: {
		background: globalStyle.aquamarine[12],
		'&:hover': {
			backgroundColor: globalStyle.aquamarine[10],
		},
	},

	toolArea: {
		paddingBottom: 1,
		paddingLeft: 2
	},


	bottomToolbar: {
		display: "flex",
		flexDirection: "row",
		position: "fixed",
		bottom: 20,
		right: 50,
		zIndex: 200
	}

}


export default noticeDetailStyle  