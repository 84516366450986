import React, {Fragment, forwardRef} from 'react'

// Material-UI Core
// import {makeStyles} from '@material-ui/core/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'

// Custom Style
import globalStyle from "../../styles/globalStyle"
import confirmDialogStyle from './styles/confirmDialogStyle'
// import {oceanBlueColor} from '../../styles/jss/cmsMainStyle'
// import {confirmDialogStyle} from '../../styles/jss/components/dialog/confirmDialogStyle'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

// const useStyles = makeStyles(theme => confirmDialogStyle(theme))

const ConfirmDialog = props => {
	// const classes = useStyles()
	const {open, dialogTitle, dialogContent, attentionContent, confirmAction, cancelAction} = props

	return (
		<Fragment>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				fullWidth={true}
				// onClose={handleClose}
				aria-labelledby="confirmDialogTitle"
				aria-describedby="confirmDialogDescription"
				PaperProps={{
					style: {
						// background:	"linear-gradient(0deg, " + globalStyle.aquamarine[8] + ", " + globalStyle.whiteColor + ")",
						background:	"linear-gradient(0deg, " + globalStyle.whiteColor + ", " + globalStyle.aquamarine[5] + ")",
					},
				}}
			>
				<DialogTitle id="confilmDialogTitle">
					{dialogTitle}
				</DialogTitle>

				<DialogContent>
					<DialogContentText id="confirmDialogContent" sx={confirmDialogStyle.content}>
						{dialogContent}
					</DialogContentText>

					<DialogContentText id="confirmDialogContent" sx={confirmDialogStyle.attentionContent}>
						{attentionContent ? "Attention: " + attentionContent : ""}
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button onClick={cancelAction} sx={confirmDialogStyle.actionButton} autoFocus>
						Cancel
					</Button>

					<Button onClick={confirmAction} sx={confirmDialogStyle.actionButton}>
						Confirm
					</Button>
				</DialogActions>

			</Dialog>
		</Fragment>
	)
}


export default ConfirmDialog
