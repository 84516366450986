const initalState = null

const createInfoDataReducer = (state = initalState, action) => {
	const {type, data} = action

	switch (type) {
		case "NEW_INFORMATION_CONTENT":
			return data
		
		default:
			return state
	}
}

// const infoItemResultReducer = (state = initalState, action) => {
// 	const {type, data} = action

// 	switch (type) {
// 		case "INFO_ITEM_RESULT":
// 			return data
		
// 		default:
// 			return state
// 	}
// }


export {
	createInfoDataReducer,

	// infoItemResultReducer
}