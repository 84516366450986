import globalStyle from "../../../styles/globalStyle"

const noticeboardListStyle = {
	title: {
		display: "flex",
		alignItems: "center",
		color: globalStyle.grayColor[10],
		fontSize: 20,
	},

	titleImage: {
		display: "flex",
		alignItems: "center",
		paddingRight: 1
	},

	container: {
		height: "73vh"
	},

	icon: {
		background: globalStyle.oceanBlueColor[8],

		'&:hover': {
			backgroundColor: globalStyle.oceanBlueColor[12],
		},
	},

	pagination: {
		paddingTop: 1,
		paddingBottom: 1,
		paddingLeft: 1,
		paddingRight: 1
	},

}


export default noticeboardListStyle  