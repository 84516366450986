import React, {Fragment} from "react"

// MUI Core
// import {withStyles} from '@mui/styles'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack';

import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";


import LaunchIcon from '@mui/icons-material/Launch';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from "@mui/icons-material/Delete";


// Custom Style
import fileChipStyle from "./styles/fileChipStyle"


// Customer List
// const FileListItem = withStyles({
// 	root: {
// 		height: 4,
// 		paddingTop: 0,
// 		paddingBottom: 0,
// 	},

// 	secondaryAction: {
// 		paddingRight: 150
// 	},
// }) (ListItem)


const FileClip = props => {
	const {key, filename, fileSize, fileExtension, enablePreview, enableDownload, enableDelete, onPreviewAction, onDownloadAction, onRemoveAction} = props

	return (

		// console.log("---------------------------------------------------------------------------------"),
		// console.log("FileChip"),
		// console.log("filename : ", filename),
		// console.log("fileSize : ", fileSize),
		// console.log("fileExtension : ", fileExtension),
		// console.log("enablePreview : ", enablePreview),
		// console.log("enableDownload : ", enableDownload),
		// console.log("enableDelete : ", enableDelete),
		// console.log("---------------------------------------------------------------------------------"),

		<Fragment>
			<Box key={key} sx={fileChipStyle.attachmentLabel}>
				<List>
					<ListItem sx={{height: 30}}>

						<ListItemAvatar>
							{/* <img src={require('../../img/file/pdf.png')} height="35" width="35" alt="" sx={fileChipStyle.fileTypeImage} /> */}
							<img src={require(`../../img/file/${fileExtension}.png`)} height="35" width="35" alt="" sx={fileChipStyle.fileTypeImage} />
						</ListItemAvatar>

						<ListItemText
							primary={filename}
							secondary={fileSize}
						/>

						<ListItemSecondaryAction>
							<Stack direction="row" spacing={2}>
								{
									enablePreview ?
										<IconButton edge="end" aria-label="Preview" onClick={onPreviewAction}>
											<LaunchIcon />
										</IconButton>
									: ""
								}
								{
									enableDownload ?
										<IconButton edge="end" aria-label="download" onClick={onDownloadAction}>
											<DownloadIcon />
										</IconButton>
									: ""
								}
								{
									enableDelete ? 
										<IconButton edge="end" aria-label="delete" onClick={onRemoveAction}>
											<DeleteIcon />
										</IconButton>
									: ""
								}
							</Stack>
						</ListItemSecondaryAction>

					</ListItem>
				</List>
			</Box>
		</Fragment>
	)
}


export default FileClip