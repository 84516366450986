import React, {Fragment, useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// Material UI
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';

// Custom Components
import Alert from "../Alert/Alert"
import ConfirmDialog from "../Dialog/ConfirmDialog"
import ckEditorToolbar from "../../config/components/ckeditor"
import FileDropzone from "../FileDropzone/FileDropzone"
import DropzoneContent from "../FileDropzone/DropzoneContent"

// Custom Style
// import globalStyle from "../../styles/globalStyle"
import informationFormStyle from './styles/informationFormStyle'

// Fetch Data
import {getInfoContentDetail} from "../../data_source/information"


const InformationForm = props => {
	const {formType, layerSelected, infoSeq} = props
	const dispatch = useDispatch()

	// Define Redux
	const createInfoDataRedux = useSelector(state => state.createInfoDataRedux)
	const newUploadAttachmentRedux = useSelector(state => state.newUploadAttachmentRedux)
	const existUploadAttachmentRedux = useSelector(state => state.existUploadAttachmentRedux)

	// Define State
	const [newInfoContent, setNewInfoContent] = useState(null)
	const [infoType, setInfoType] = useState("")

	// Define Alert Dialog State
	const [alertOpen, setAlertOpen] = useState(false)
	const [alertData, setAlertData] = useState({
		type: "",
		title: "",
		content: ""
	})

	// Define Confirm Dialog State
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
	const [confirmDialogData, setConfirmDialogData] = useState({
		dialogTitle: "",
		dialogContent: "",
		confirmAction: "",
		cancelAction: ""
	})


	// Build Alert
	const buildAlert = (data) => {
		setAlertData({
			type: data.type,
			title: data.title,
			content: data.content
		})

		setAlertOpen(true)
	}

	const alertHandleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setAlertOpen(false)
	}


	// Buile Confirm Dialog open and close
	const confirmDialogHandleOpen = (data) => {
		setConfirmDialogData({
			dialogTitle: data.dialogTitle,
			dialogContent: data.dialogContent,
			confirmAction: data.confirmAction,
			cancelAction: data.cancelAction
		})

		setConfirmDialogOpen(true)
	}

	const confirmDialogHandleClose = () => {
		setConfirmDialogOpen(false)
	}


	// CKEditor Config
	const editorConfiguration = {
		toolbar: ckEditorToolbar,
	}


	// Define Template
	const infoContentTemplate = {
		"layer_seq": layerSelected ? layerSelected : "",
		"info_type": "",
		"info_title": "",
		"info_content": ""
	}


	// Handle confirm dialog open and close
	const removeFileConfirmHandleOpen = file => () => {
		confirmDialogHandleOpen({
			dialogTitle: "Remove File Attachment",
			dialogContent: "Are you sure to remove the file.",
			confirmAction: removeFileAction(file),
			cancelAction: removeFileConfirmHandleClose
		})
	}

	const removeFileConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}


	// Handle information item type select change
	const infoTypeHandleChange = (event) => {
		const {value} = event.target
		// const res = {...infoContentTemplate}
		const res = {...newInfoContent}
		res.info_type = value

		setInfoType(value)
		setNewInfoContent(res)
		dispatch({
			type: "NEW_INFORMATION_CONTENT",
			data: res
		})
	}


	// Handle Form Input
	const textFieldHandleChange = event => {
		const {name, value} = event.target
		const res = {...newInfoContent}
		res[name] = value

		setNewInfoContent(res)
		dispatch({
			type: "NEW_INFORMATION_CONTENT",
			data: res
		})

		// validate({...submissionCreateValue, values: res})
	}


	// CKEditor content change action
	const ckeditorHandleChange = (event, editor) => {
		const inputContent = editor.getData()
		const res = {...newInfoContent}

		res.info_content = inputContent
		
		setNewInfoContent(res)
		dispatch({
			type: "NEW_INFORMATION_CONTENT",
			data: res
		})

		// setCkeditorContent(inputContent)

		// const inputData = editor.getData()

		// const data = { ...circulationData };
	
		// setEditorValue(inputData);
		// data.messages.content = inputData;
		// setCirculationData({ ...circulationData, ...data });
	
		// if (
		//   validationErrors.content && validationErrors.content !== "" ? true : false
		// ) {
		//   validate({ ...circulationData, content: inputData });
		// }

	}


	// File drop Action
	const readFileAction = acceptedFiles => {
		dispatch({
			type: "NEW_UPLOAD_ATTACHMENT",
			data: acceptedFiles
		})
	}

	const rejectedFileAction = rejectedFiles => {
		buildAlert({
			// Alert Type: success, info, warning, error
			type: "error",
			title: "Error Alert",
			content: rejectedFiles[0].errors[0].code + " > " + rejectedFiles[0].errors[0].message
		})
	}


	// Action
	const removeFileAction = file => () => {
		const newAttachmentFile = newUploadAttachmentRedux.filter(res => res !== file)
		const existAttachmentFile = existUploadAttachmentRedux.filter(res => res !== file)

		dispatch({
			type: "NEW_UPLOAD_ATTACHMENT",
			data: newAttachmentFile
		})

		dispatch({
			type: "EXIST_UPLOAD_ATTACHMENT",
			data: existAttachmentFile
		})

		removeFileConfirmHandleClose()
	}


	useEffect(() => {
		if (formType === "CREATE") {
			setNewInfoContent(infoContentTemplate)

			dispatch({
				type: "NEW_INFORMATION_CONTENT",
				data: infoContentTemplate
			})
		}
		else if (formType === "EDIT") {
			getInfoContentDetail(infoSeq).then(res => {
				setNewInfoContent({
					"info_seq": res.data.info_seq,
					"info_type": res.data.info_type,
					"info_title": res.data.info_title,
					"info_content": res.data.info_content,
					"info_status": res.data.info_status
				})

				dispatch({
					type: "NEW_INFORMATION_CONTENT",
					data: {
						"info_seq": res.data.info_seq,
						"info_type": res.data.info_type,
						"info_title": res.data.info_title,
						"info_content": res.data.info_content,
						"info_status": res.data.info_status
					}
				})

				dispatch({
					type: "EXIST_UPLOAD_ATTACHMENT",
					data: res.data.files
				})

				setInfoType(res.data.info_type)
			})
		}
	}, [])


	return (
		// console.log("---------------------------------------------------------------------------------"),
		// console.log("InformationForm.js"),
		// console.log("Information Seq : ", infoSeq),
		// console.log("newInfoContent : ", newInfoContent),
		// console.log("createInfoDataRedux (Redux) : ", createInfoDataRedux),
		// console.log("---------------------------------------------------------------------------------"),

		<Fragment>
			<Box>
				<Alert
					open={alertOpen}
					alertType={alertData.type}
					alertTitle={alertData.title}
					alertContent={alertData.content}
					handleClose={alertHandleClose}
				/>

				<ConfirmDialog
					open={confirmDialogOpen}
					dialogTitle={confirmDialogData.dialogTitle}
					dialogContent={confirmDialogData.dialogContent}
					confirmAction={confirmDialogData.confirmAction}
					cancelAction={confirmDialogData.cancelAction}
				/>
			</Box>

			{
				formType === "CREATE" ?
					<Box sx={informationFormStyle.typeArea}>
						<FormControl fullWidth>
							<InputLabel id="info-type">Information Type</InputLabel>
							<Select
								id="info_type"
								labelId="info-type"
								label="Information Type"
								value={newInfoContent ? newInfoContent.info_type : ""}
								onChange={infoTypeHandleChange}
							>
								<MenuItem value={"ARTICLE"}>ARTICLE</MenuItem>
								<MenuItem value={"URL"}>URL</MenuItem>
							</Select>
						</FormControl>
					</Box>
				: ""
			}
			
			{
				infoType ?
					infoType === "ARTICLE" ?
						<Stack direction="column" spacing={2}>
							<TextField 
								id="info_title"
								name="info_title"
								label="Title"
								variant="outlined"
								required
								fullWidth={true}
								size="small"
								value={newInfoContent ? newInfoContent.info_title : ""}
								onChange={textFieldHandleChange}
			
								//   error={
								// 	validationErrors.circ_subject &&
								// 	validationErrors.circ_subject !== ""
								// 	  ? true
								// 	  : false
								//   }
			
								//   helperText={validationErrors.circ_subject}
							/>
			
							<Box>
								<CKEditor
									editor={ClassicEditor}
									config={editorConfiguration}
									// data={ckeditorContent}
									data={newInfoContent.info_content || ""}
									onChange={ckeditorHandleChange}
			
									// onReady={ editor => {
									//     // You can store the "editor" and use when it is needed.
									//     console.log( 'Editor is ready to use!', editor );
									// } }
			
									// onChange={ ( event, editor ) => {
									//     const data = editor.getData();
									//     console.log( { event, editor, data } );
									// } }
			
									// onBlur={ ( event, editor ) => {
									//     console.log( 'Blur.', editor );
									// } }
			
									// onFocus={ ( event, editor ) => {
									//     console.log( 'Focus.', editor );
									// } }
								/>
							</Box>
			
							<FormControl component="fieldset" variant="standard" sx={informationFormStyle.fieldset}>
								<FormLabel component="legend">Attachment</FormLabel>
								<FileDropzone
									acceptFileType={process.env.REACT_APP_INFO_ATTACHMENT_TYPE.split(",")}
									maxFiles={10}
									DropzoneContent={DropzoneContent}

									enablePreview={false}
									enableUpload={true}
									enableDownload={false}
									enableDelete={true}

									onAcceptedAction={readFileAction}
									onRejectedAction={rejectedFileAction}
									onPreviewAction={null}
									onDownloadAction={null}
									onRemoveAction={removeFileConfirmHandleOpen}
								/>
							</FormControl>
						</Stack>

					: infoType === "URL" ?
						<Stack direction="column" spacing={2}>

							<TextField 
								id="info_title"
								name="info_title"
								label="Title"
								variant="outlined"
								required
								fullWidth={true}
								size="small"
								value={newInfoContent ? newInfoContent.info_title : ""}
								onChange={textFieldHandleChange}

								//   error={
								// 	validationErrors.circ_subject &&
								// 	validationErrors.circ_subject !== ""
								// 	  ? true
								// 	  : false
								//   }

								//   helperText={validationErrors.circ_subject}
							/>

							<TextField
								id="info_content"
								name="info_content"
								label="URL"
								variant="outlined"
								required
								fullWidth={true}
								size="small"
								value={newInfoContent ? newInfoContent.info_content : ""}
								onChange={textFieldHandleChange}

								//   error={
								// 	validationErrors.circ_subject &&
								// 	validationErrors.circ_subject !== ""
								// 	  ? true
								// 	  : false
								//   }

								//   helperText={validationErrors.circ_subject}
							/>
						</Stack>
					: ""
				: ""
			}
		</Fragment>
	)
}

export default InformationForm
