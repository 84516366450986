import React, {Fragment} from "react"
import KnowHow from "../../components/KnowHow/KnowHow"

const KnowHowView = () => {
	return (
		<Fragment>
			<KnowHow />
		</Fragment>
	)
}


export default KnowHowView