import globalStyle from "../../../styles/globalStyle"

const appsCardStyle = {

	card: {
		width: '100%',
		background: globalStyle.aquamarine[0],
		border: 1,
		borderColor: globalStyle.aquamarine[2],
	},

	avatar: {
		background: globalStyle.greenColor[6]
	},

	line: {
		marginLeft: 9,
		marginRight: 2,
		borderColor: globalStyle.aquamarine[2]
	},

	icon: {
		marginTop: 0.5,
		marginLeft: 9
	},

	iconArea: {
		display: "flex",
		flexDirection: "row"
	},

	browserIcon: {
		flex: 1,
	},

	openIcon: {
		marginRight: 2
	},

	launchIcon: {
		color: globalStyle.aquamarine[12],
		'&:hover': {
			color: globalStyle.aquamarine[10],
		},
		cursor: "pointer"
	},
}


export default appsCardStyle  