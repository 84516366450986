import React, {Fragment} from 'react'

import Noticeboard from '../../components/Noticeboard/Noticeboard'


const NoticeboardView = () => {

	return (
		<Fragment>
			<Noticeboard />
		</Fragment>
	)
}


export default NoticeboardView