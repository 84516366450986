import React, {Fragment, useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

// MUI
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'

// MUI x-date-pickers
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

// MUI Icon
// import AddIcon from '@mui/icons-material/Add'

// Custom Components
import Alert from "../Alert/Alert"
import ConfirmDialog from "../Dialog/ConfirmDialog"
import FullScreenDialog from "../Dialog/FullScreenDialog"
import ckEditorToolbar from "../../config/components/ckeditor"
import FileDropzone from "../FileDropzone/FileDropzone"
import DropzoneContent from "../FileDropzone/DropzoneContent"
import RecipientForm from "./RecipientForm"

// Custom Style 
import noticeCreateStyle from './styles/noticeCreateStyle'


const NoticeCreate = props => {
	const {formType} = props
	const dispatch = useDispatch()

	// Define Redux
	const recipientUserSeqRedux = useSelector(state => state.recipientUserSeqRedux)
	const newUploadAttachmentRedux = useSelector(state => state.newUploadAttachmentRedux)

	// Define Template
	const noticeContentTemplate = {
		"subject": "",
		"content": "",
		"emailNotification": false,
		"requestRead": false,
		"requestExpiryDate": null,
		"toAll": true,
		"user_seq": recipientUserSeqRedux ? recipientUserSeqRedux.length > 0 ? recipientUserSeqRedux : [] : [],
		"status": "Y"
	}

	// Define State
	const [newNoticeContent, setNewNoticeContent] = useState(noticeContentTemplate)
	const [recipientDialogOpen, setRecipientDialogOpen] = useState(false)
	const [alertOpen, setAlertOpen] = useState(false)
	const [alertData, setAlertData] = useState({
		type: "",
		title: "",
		content: ""
	})

	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
	const [confirmDialogData, setConfirmDialogData] = useState({
		dialogTitle: "",
		dialogContent: "",
		confirmAction: "",
		cancelAction: ""
	})


	// CKEditor Config
	const editorConfiguration = {
		toolbar: ckEditorToolbar,
	}


	// Define Dialog Button
	const recipientDialogBtn = []


	// Build Alert
	const buildAlert = (data) => {
		setAlertData({
			type: data.type,
			title: data.title,
			content: data.content
		})

		setAlertOpen(true)
	}

	const alertHandleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setAlertOpen(false)
	}

 
	// Build Confirm dialog
	const confirmDialogHandleOpen = (data) => {
		setConfirmDialogData({
			dialogTitle: data.dialogTitle,
			dialogContent: data.dialogContent,
			confirmAction: data.confirmAction,
			cancelAction: data.cancelAction
		})

		setConfirmDialogOpen(true)
	}

	const confirmDialogHandleClose = () => {
		setConfirmDialogOpen(false)
	}


	const removeFileConfirmHandleOpen = file => () => {
		confirmDialogHandleOpen({
			dialogTitle: "Remove File Attachment",
			dialogContent: "Are you sure to remove the file.",
			confirmAction: removeFileAction(file),
			cancelAction: removeFileConfirmHandleClose
		})
	}

	const removeFileConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}


	// Handle Form Input
	const textFieldHandleChange = event => {
		const {name, value} = event.target
		const res = {...newNoticeContent}
		res[name] = value

		setNewNoticeContent(res)
		dispatch({
			type: "NEW_NOTICE_CONTENT",
			data: res
		})

		// validate({...submissionCreateValue, values: res})
	}


	// CKEditor content change action
	const ckeditorHandleChange = (event, editor) => {
		const inputContent = editor.getData()
		const res = {...newNoticeContent}

		res.content = inputContent
		
		setNewNoticeContent(res)
		dispatch({
			type: "NEW_NOTICE_CONTENT",
			data: res
		})
	}


	const switchBoxHandleChange = event => {
		const {name, checked} = event.target
		const res = {...newNoticeContent}

		res[name] = checked

		setNewNoticeContent(res)
		dispatch({
			type: "NEW_NOTICE_CONTENT",
			data: res
		})
	}


	const reqReadSwitchBoxHandleChange = event => {
		const {name, checked} = event.target
		const res = {...newNoticeContent}

		res[name] = checked

		if (!checked) {
			res.requestExpiryDate = null
		}

		setNewNoticeContent(res)
		dispatch({
			type: "NEW_NOTICE_CONTENT",
			data: res
		})
	}


	const dateFieldHandleChange = date => {
		const res = {...newNoticeContent}

		res.requestExpiryDate = !isNaN(Date.parse(date)) ? new Date(date).toISOString() : null

		setNewNoticeContent(res)
		dispatch({
			type: "NEW_NOTICE_CONTENT",
			data: res
		})
	}


	const selectUserHangleChange = () => {
		const res = {...newNoticeContent}

		res.user_seq = recipientUserSeqRedux
		res.toAll = recipientUserSeqRedux ? recipientUserSeqRedux.length > 0 ? false : true : true

		setNewNoticeContent(res)
		dispatch({
			type: "NEW_NOTICE_CONTENT",
			data: res
		})
	}



	// Handle Dialog open and close
	const recipientDialogHandleOpen = () => {
		setRecipientDialogOpen(true)
	}

	const recipientDialogHandleClose = () => {
		setRecipientDialogOpen(false)
	}



	// File Action
	const acceptFileAction = acceptedFiles => {
		dispatch({
			type: "NEW_UPLOAD_ATTACHMENT",
			data: acceptedFiles
		})
	}

	const rejectedFileAction = rejectedFiles => {
		buildAlert({
			// Alert Type: success, info, warning, error
			type: "error",
			title: "Error Alert",
			content: rejectedFiles[0].errors[0].code + " > " + rejectedFiles[0].errors[0].message
		})
	}

	const removeFileAction = file => () => {
		const result = newUploadAttachmentRedux.filter(res => res !== file)

		dispatch({
			type: "NEW_UPLOAD_ATTACHMENT",
			data: result
		})

		removeFileConfirmHandleClose()
	}


	useEffect(() => {
		selectUserHangleChange()
	}, [recipientUserSeqRedux])


	return (

		// console.log("--------------------------------------------------------"),
		// console.log("[NoticeCreate.js]"),
		// console.log("newNoticeContent : ", newNoticeContent),
		// console.log("Accept File Type : ", process.env.REACT_APP_NOTICEBOARD_ATTACHMENT_TYPE.split(",")),
		// console.log("--------------------------------------------------------"),

		<Fragment>
			<div>
				<Alert
					open={alertOpen}
					alertType={alertData.type}
					alertTitle={alertData.title}
					alertContent={alertData.content}
					handleClose={alertHandleClose}
				/>

				<ConfirmDialog
					open={confirmDialogOpen}
					dialogTitle={confirmDialogData.dialogTitle}
					dialogContent={confirmDialogData.dialogContent}
					confirmAction={confirmDialogData.confirmAction}
					cancelAction={confirmDialogData.cancelAction}
				/>

				<FullScreenDialog
					open={recipientDialogOpen}
					dialogTitle={"Recipient"}
					formContent={
						<RecipientForm />
					}
					button={recipientDialogBtn}
					handleClose={recipientDialogHandleClose}
				/>
			</div>


			<Stack direction="column" spacing={2}>

				<TextField 
					id="subject"
					name="subject"
					label="Subject"
					variant="outlined"
					required
					fullWidth={true}
					size="small"
					onChange={textFieldHandleChange}
					value={newNoticeContent ? newNoticeContent.subject : ""}
				/>

				<Box>
					<CKEditor
						editor={ClassicEditor}
						config={editorConfiguration}
						// data={ckeditorContent}
						data={newNoticeContent.content || ""}
						onChange={ckeditorHandleChange}

						// onReady={ editor => {
						//     // You can store the "editor" and use when it is needed.
						//     console.log( 'Editor is ready to use!', editor );
						// } }

						// onChange={ ( event, editor ) => {
						//     const data = editor.getData();
						//     console.log( { event, editor, data } );
						// } }

						// onBlur={ ( event, editor ) => {
						//     console.log( 'Blur.', editor );
						// } }

						// onFocus={ ( event, editor ) => {
						//     console.log( 'Focus.', editor );
						// } }
					/>
				</Box>

				<FormControl component="fieldset" variant="standard" sx={noticeCreateStyle.fieldset}>
					<FormLabel component="legend">Attachment</FormLabel>
				
					<FileDropzone
						acceptFileType={process.env.REACT_APP_NOTICEBOARD_ATTACHMENT_TYPE.split(",")}
						maxFiles={10}
						DropzoneContent={DropzoneContent}

						enablePreview={false}
						enableUpload={true}
						enableDownload={false}
						enableDelete={true}

						onAcceptedAction={acceptFileAction}
						onRejectedAction={rejectedFileAction}
						onPreviewAction={null}
						onDownloadAction={null}
						onRemoveAction={removeFileConfirmHandleOpen}
					/>
				</FormControl>

				<FormControl component="fieldset" variant="standard" sx={noticeCreateStyle.fieldset}>
					<FormLabel component="legend">Post noticeboard to user</FormLabel>
				
					<FormGroup row>
						<Stack direction="column" spacing={1}>
							<Box sx={noticeCreateStyle.postUserText}>
								{
									recipientUserSeqRedux ? recipientUserSeqRedux.length > 0 ? `Post Notice to ${recipientUserSeqRedux.length} User(s)` : "Post to All Users" : "Post to All Users"
								}
							</Box>

							<Box sx={noticeCreateStyle.addUserBtnArea}>
								<Button variant="contained" size="small" onClick={recipientDialogHandleOpen}>Select User</Button>
							</Box>
						</Stack>
					</FormGroup>
				</FormControl>

				<FormControl component="fieldset" variant="standard" sx={noticeCreateStyle.fieldset}>
					<FormLabel component="legend">Email to notify</FormLabel>
				
					<FormGroup row>
						<FormControlLabel label="Yes" sx={noticeCreateStyle.label} control={
							<Switch
								name="emailNotification"
								size="small"
								checked={newNoticeContent.emailNotification}
								onChange={switchBoxHandleChange}
							/>
						} />
					</FormGroup>
				</FormControl>

				<FormControl component="fieldset" variant="standard" sx={noticeCreateStyle.fieldset}>
					<FormLabel component="legend">Request Read</FormLabel>
				
					<FormGroup row>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={2} lg={1} sx={noticeCreateStyle.labelArea}>
								<FormControlLabel label="Yes" sx={noticeCreateStyle.label} control={
									<Switch
										name="requestRead"
										size="small"
										checked={newNoticeContent.requestRead}
										onChange={reqReadSwitchBoxHandleChange}
									/>
								} />
							</Grid>

							{
								newNoticeContent.requestRead ?
									<Grid item xs={12} sm={10} lg={11}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DatePicker
												label="Expiry Date"
												value={newNoticeContent.requestExpiryDate || null}
												inputFormat="dd/MM/yyyy"
												renderInput={(params) => <TextField size="small"  {...params} />}
												onChange={date => dateFieldHandleChange(date)}
											/>
										</LocalizationProvider>
									</Grid>
								: ""
							}
						</Grid>
					</FormGroup>
				</FormControl>

			</Stack>
		</Fragment>
	)
}

export default NoticeCreate
