import {alpha} from "@mui/material/styles"
import globalStyle from "../../../styles/globalStyle"

const reciptentFormStyle = {

	listArea: {
		// height: 600,

		// overflowY: "auto",
    	// overflowX: "hidden",
	},

	resultArea: {
		background: alpha(globalStyle.aquamarine[1], 0.5),

		// height: 400,
		padding: 2,
	}

}


export default reciptentFormStyle  