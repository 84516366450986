const initalState = null

const newNoticeContentReducer = (state = initalState, action) => {
	const {type, data} = action

	switch (type) {
		case "NEW_NOTICE_CONTENT":
			return data
		
		default:
			return state
	}
}


export {
	newNoticeContentReducer
}