import React, {Fragment} from "react"

// Material UI
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'

// Custom Component
import LaunchApps from "../Public/LaunchApps"

// Custom Style
import appIconStyle from './styles/appIconStyle'


const AppIcon = props => {
	const {appId, name, icon, background, hoverBackground} = props

	const launchAppAction = appId => () => {
		LaunchApps(appId)
	}


	return (
		<Fragment>
			<Paper elevation={2} sx={appIconStyle.icon} style={{'--bg-color': background, '--hover-bg-color': hoverBackground}} onClick={launchAppAction(appId)}>
				<Stack direction="column" spacing={0.5} justifyContent="center" alignItems="center">
					<Box>
						<img src={icon} width="45" />
					</Box>
					<Box sx={appIconStyle.name}>
						{name}
					</Box>
				</Stack>
			</Paper>
		</Fragment>
	)
}

export default AppIcon
