import React, {Fragment, useEffect} from "react"

// Custom Components
import isLoggedIn from "./isLoggedIn";


const SignIn = () => {

	useEffect(() => {
		if (isLoggedIn()) {
			window.location.href = "/main"
		}
		else {
			window.location.href = `${process.env.REACT_APP_OIDC_LOGIN_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGIN_REDIRECT_URI}`
		}
	}, [])
	

	return (
		<Fragment></Fragment>
	)
}

export default SignIn
