import React, {Fragment} from "react"
import Information from "../../components/Information/Information"

const InformationView = () => {
	return (
		<Fragment>
			<Information />
		</Fragment>
	)
}


export default InformationView