import React, {Fragment, useState} from 'react'

// MUI
import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import TreeView from '@mui/lab/TreeView'
import TreeItem, {TreeItemProps, treeItemClasses} from '@mui/lab/TreeItem'
import Typography from '@mui/material/Typography'

// MUI Icon
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'


const StyledTreeItemRoot = styled(TreeItem)(({theme}) => ({
	color: theme.palette.text.secondary, [`& .${treeItemClasses.content}`]: {
		color: theme.palette.text.secondary,
		borderTopRightRadius: theme.spacing(2),
		borderBottomRightRadius: theme.spacing(2),
		paddingRight: theme.spacing(1),
		fontWeight: theme.typography.fontWeightMedium,
		'&.Mui-expanded': {
			fontWeight: theme.typography.fontWeightRegular,
		},
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
		},
		'&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
			backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
			color: 'var(--tree-view-color)',
		},
		[`& .${treeItemClasses.label}`]: {
			fontWeight: 'inherit',
			color: 'inherit',
		},
	},

	[`& .${treeItemClasses.group}`]: {
		marginLeft: 0,

		[`& .${treeItemClasses.content}`]: {
			paddingLeft: theme.spacing(3),
		},
	},
}))


const StyledTreeItem = props => {
	const {bgColor,	color, labelIcon: LabelIcon, labelText, labelInfo, ...other} = props

	return (
		<StyledTreeItemRoot
			label={
				<Box sx={{ display: 'flex', alignItems: 'center', p: 1, pr: 0 }}>
					<Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
					
					<Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
						{labelText}
					</Typography>

					<Typography variant="caption" color="inherit">
						{labelInfo > 0 ? labelInfo : ""}
					</Typography>
				</Box>
			}
			style={{
				'--tree-view-color': color,
				'--tree-view-bg-color': bgColor,
			}}

			{...other}
		/>
	)
}



const InformationGroup = props => {
	const {listData, layerSelected, layerSelectedSetter, layerExpanded, layerExpandedSetter} = props

	const getTreeItem = treeItem => {
		if (treeItem) {
			return treeItem.map(item => {
				let children 

				if (item.childs && item.childs.length > 0) {
					children = getTreeItem(item.childs)
				}

				return (
					<CustomTreeItem
						key={item.layer_seq}
						nodeId={item.layer_seq.toString()}
						item={item}


						children={children}
					/>
				)
			})
		}
	}

	const CustomTreeView = props => {
		const {listData, expanded} = props

		return(
			<TreeView
				aria-label="information"
				defaultCollapseIcon={<ArrowDropDownIcon />}
				defaultExpandIcon={<ArrowRightIcon />}
				defaultEndIcon={<div style={{width: 10}} />}

				expanded={expanded}
				onNodeToggle={handleTreeViewToggle}
				onNodeSelect={handleTreeViewSelect}

				sx={{flexGrow: 1, maxWidth: 410, overflowY: 'none'}}
			>
				{getTreeItem(listData)}
			</TreeView>
		)
	}

	const CustomTreeItem = props => {
		const {item, ...other} = props

		return (
			<StyledTreeItem
				nodeId={item.layer_seq}
				labelIcon={ArticleOutlinedIcon}
				labelText={item.layer_name}
				labelInfo={item.info_count}
				{...other}

				onClick={readInformationContentAction(item.layer_seq)}
			/>
		)
	}


	const handleTreeViewToggle = (event, nodeId) => {
		layerExpandedSetter(nodeId)
	}

	const handleTreeViewSelect = (event, nodeId) => {
		layerSelectedSetter(nodeId)
	}

	// Action
	const readInformationContentAction = layerSeq => () => {
		layerSelectedSetter(layerSeq)
	}


	return (
		<Fragment>
			<CustomTreeView 
				listData={listData}
				expanded={layerExpanded}
			/>
		</Fragment>
	)
}


export default InformationGroup