import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import SectionItem from "../../SectionItem";
import { dateToDisplay } from "../../utilFunc";
import { dateStyles } from "../../dateStyles";

const DateSelect = ({ dateValue, setDateValue }) => {
  return (
    <SectionItem header={`Select Date (${dateToDisplay(dateValue)})`}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={dateStyles}>
          <StaticDatePicker
            orientation="landscape"
            openTo="day"
            disableOpenPicker
            showToolbar={false}
            value={dateValue}
            onChange={(newValue) => {
              setDateValue(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
            componentsProps={{
              actionBar: {
                actions: [],
              },
            }}
          />
        </Box>
      </LocalizationProvider>
    </SectionItem>
  );
};

export default DateSelect;
