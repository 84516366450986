import React, {Fragment, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useNavigate} from "react-router-dom"
import {format, parseISO} from "date-fns"

// MUI
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// Custom Icon
import noticeBoardIcon from "../../img/icon-noticeboard.png"
import informationIcon from "../../img/icon-information.png"
import eformIcon from "../../img/icon-eform.png"
import knowHowForumIcon from "../../img/icon-knowhow.png"
// import systemAppsIcon from "../../img/icon-systemapps.png"
// import trainingIcon from "../../img/icon-training.png"
// import weatherIcon from "../../img/icon-weather.png"
import quickLinkIcon from "../../img/quick-link.png"
import frequentLinkIcon from "../../img/frequent-link.png"
// import misAppIcon from "../../img/icon-mis.png"

// Custom Components
import FullScreenDialog from "../Dialog/FullScreenDialog"
import UseLoading from "../Loading/UseLoading"
import Windows from "../Windows/Windows"
import QuickLinksContent from "../WindowsContent/QuickLinksContent"
import NoticeDetail from "../Noticeboard/NoticeDetail"
import InformationContent from "../Information/InformationContent"

// Custom Style
import dashboardStyle from './styles/dashboardStyle'

// Fetch Data
import {getLatestNoticeList, setNoticeRead} from "../../data_source/noticeboard"
import {getLatestInfoList} from "../../data_source/information"
import {getLatestEformList} from "../../data_source/eform"

// Custom Typography
// const UserNameTypography = withStyles({
// 	root: {
// 		"fontSize": 16,
// 		"fontWeight": 600,
// 		// "color": "#0000b3",
// 	},
// }) (Typography)


const Dashboard = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	// Define state
	const [latestNoticeList, setLatestNoticeList] = useState(null)
	const [infoLatestList, setInfoLatestList] = useState(null)
	const [eformLatestList, setEformLatestList] = useState(null)
	const [noticeSeq, setNoticeSeq] = useState(null)
	const [infoSeq, setInfoSeq] = useState(null)

	// Define Dialog State
	const [detailNoticeDialogOpen, setDetailNoticeDialogOpen] = useState(false)
	const [detailInfoDialogOpen, setDetailInfoDialogOpen] = useState(false)

	// Define Loading
	const [getAppsLoading, getAppsShowLoading, getAppsHiddenLoading] = UseLoading()

	const [getNoticeLoading, getNoticeShowLoading, getNoticeHiddenLoading] = UseLoading()
	const [getInfoLoading, getInfoShowLoading, getInfoHiddenLoading] = UseLoading()
	const [getEformLoading, getEformShowLoading, getEformHiddenLoading] = UseLoading()


	// Define open and close
	const noticeDetailDialogHandleOpen = () => {
		setDetailNoticeDialogOpen(true)
	}
	
	const noticeDetailDialogHandleClose = () => {
		dispatch({
			type: "EXIST_UPLOAD_ATTACHMENT",
			data: []
		})

		setDetailNoticeDialogOpen(false)
	}


	const infoDetailDialogHandleOpen = () => {
		setDetailInfoDialogOpen(true)
	}
	
	const infoDetailDialogHandleClose = () => {
		dispatch({
			type: "EXIST_UPLOAD_ATTACHMENT",
			data: []
		})

		setDetailInfoDialogOpen(false)
	}

	
	// Action
	const noticeHandleClick = selectedNoticeSeq => () => {
		setNoticeSeq(selectedNoticeSeq)
		readDetailNoticeAction(selectedNoticeSeq)

		// noticeDetailDialogHandleOpen()
	}

	const infoHandleClick = selectedInfoSeq => () => {
		setInfoSeq(selectedInfoSeq)

		infoDetailDialogHandleOpen()
	}
	
	const eformHandleClick = (formTypeSeq, formSeq) => () => {
		window.open(`${process.env.REACT_APP_EFORM_URL}/form/${formTypeSeq}/${formSeq}`, '_blank')
	}


	const readDetailNoticeAction = (selectNoticeSeq) => {
		noticeDetailDialogHandleOpen()

		const params = {
			notice_seq: selectNoticeSeq,
			user_seq: localStorage.getItem("USER_SEQ")
		}

		setNoticeRead(params).then(res => {
			console.log("Set Notice Read : ", res)
		})
	}


	useEffect(() => {
		getNoticeShowLoading()
		getLatestNoticeList().then(lists => {
			setLatestNoticeList(lists.data)
			getNoticeHiddenLoading()
		})
		
		getInfoShowLoading()
		getLatestInfoList().then(lists => {
			setInfoLatestList(lists.data)
			getInfoHiddenLoading()
		})
		
		getEformShowLoading()
		getLatestEformList().then(lists => {
			setEformLatestList(lists.data)
			getEformHiddenLoading()
		})
	}, [])

	return (

		// console.log("--------------------------------------------------------------"),
		// console.log("Dashboard.js"),
		// console.log("latestNoticeList : ", latestNoticeList),
		// console.log("infoLatestList : ", infoLatestList),
		// console.log("eformLatestList : ", eformLatestList),
		// console.log("--------------------------------------------------------------"),

		<Fragment>
			<Box>
				<FullScreenDialog
					open={detailNoticeDialogOpen}
					dialogTitle={"Notice Detail"}
					formContent={
						<NoticeDetail
							type={"NOTICEBOARD"}
							noticeSeq={noticeSeq}
							edit={false}
							remove={false}
						/>
					}
					button={[]}
					handleClose={noticeDetailDialogHandleClose}
				/>

				<FullScreenDialog
					open={detailInfoDialogOpen}
					dialogTitle={"Information Content"}
					formContent={
						<InformationContent
							infoSeq={infoSeq}
						/>
					}
					button={[]}
					handleClose={infoDetailDialogHandleClose}
				/>
			</Box>

			<Grid container spacing={2}>
				<Grid item xs={12} sm={4} lg={3}>
					<Stack direction="column" spacing={2}>
						<Windows
							icon={frequentLinkIcon}
							title={"Frequent Use"}
							content={<QuickLinksContent 
								showLoading={getAppsShowLoading}
								hiddenLoading={getAppsHiddenLoading}
							/>}
							loading={getAppsLoading}
						/>
					</Stack>
				</Grid>

				<Grid item xs={12} sm={8} lg={9}>
					<Grid container spacing={2}>
						<Grid item xs={12} lg={6}>
							<Windows
								icon={noticeBoardIcon}
								title={"Notice Board"}
								content={
									latestNoticeList ? 
										latestNoticeList.map(list => {
											return (
												<Box key={list.notice_seq}>
													<Stack direction="row" spacing={2}>
														<Typography variant="string" component="span" align="left"  sx={dashboardStyle.date} >
															{/* <Box sx={dashboardStyle.date}>{format(parseISO(list.createdate), "yyyy-MM-dd")}</Box> */}
															<Box sx={dashboardStyle.date}>{format(parseISO(list.createdate), "yyyy-MM-dd")}</Box>
														</Typography>

														<Typography variant="string" component="span" align="left" noWrap>
															<Box sx={dashboardStyle.RecordTitle} onClick={noticeHandleClick(list.notice_seq)}>{list.subject}</Box>
														</Typography>
													</Stack>
												</Box>
											)
										})
									: ""
								}
								loading={getNoticeLoading}
							/>
						</Grid>


						<Grid item xs={12} lg={6}>
							<Windows
								icon={informationIcon}
								title={"Information"}
								content={
									infoLatestList ? 
										infoLatestList.map(list => {
											return (
												<Box key={list.info_seq}>
													<Stack direction="row" spacing={2}>
														<Typography variant="string" component="span" align="left"  sx={dashboardStyle.date} >
															<Box sx={dashboardStyle.date}>{format(parseISO(list.createdate), "yyyy-MM-dd")}</Box>
														</Typography>

														<Typography variant="string" component="span" align="left" noWrap>
															<Box sx={dashboardStyle.RecordTitle} onClick={infoHandleClick(list.info_seq)}>{list.info_title}</Box>
														</Typography>
													</Stack>
												</Box>
											)
										})
									: ""
								}
								loading={getInfoLoading}
							/>
						</Grid>

						<Grid item xs={12} lg={6}>
							<Windows
								icon={eformIcon}
								title={"eForm"}
								content={
									eformLatestList ? 
									eformLatestList.map(list => {

											return (
												<Box key={list.form_id}>
													<Stack direction="row" spacing={2}>
														<Typography variant="string" component="span" align="left"  sx={dashboardStyle.date} >
															<Box sx={dashboardStyle.date}>{list.date.replaceAll("/", "-")}</Box>
														</Typography>

														<Typography variant="string" component="span" align="left" noWrap>
															<Box sx={dashboardStyle.RecordTitle} onClick={eformHandleClick(list.form_type_seq, list.form_seq)}>
																{list.form_name} 
																<Box component={"span"} sx={dashboardStyle.stage}>
																	({list.stage})
																</Box>
															</Box>
														</Typography>
													</Stack>
												</Box>
											)
										})
									: ""

								}
								loading={getEformLoading}
							/>
						</Grid>

						<Grid item xs={12} lg={6}>
							<Windows
								icon={knowHowForumIcon}
								title={"Know-How Forum"}
								content={null}
							/>
						</Grid>

						{/* <Grid item xs={12} lg={6}>
							<Windows
								icon={trainingIcon}
								title={"Training"}
								content={null}
							/>
						</Grid> */}

						{/* <Grid item xs={12} lg={6}>
							<Windows
								icon={weatherIcon}
								title={"Current Weather"}
								content={null}
							/>
						</Grid> */}
					</Grid>	
				</Grid>
			</Grid>
		</Fragment>
	)
}

export default Dashboard
