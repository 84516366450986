import globalStyle from "../../../styles/globalStyle"

const mainStyle = {
	root: {
		height: "100vh",
		overflowY: "hidden",
		overflowX: "hidden",
	},

	appBar: {
		background: globalStyle.aquamarine[10]
	},

	logo: {
		cursor: "pointer"
	},

	appBarIcon: {
		color: globalStyle.whiteColor,
	},

	appBarUserFullName: {
		display: globalStyle.desktopDisplay,
		paddingLeft: 1
	},

	appBtnMenuUserFullName: {
		display: globalStyle.mobileDisplay,
		justifyContent: "center",
		paddingTop: 1,
		paddingBottom: 1,
		marginBottom: 2
	},

	menuBarIcon: {
		display: globalStyle.mobileDisplay
	},

	menuIcon: {
		color: globalStyle.aquamarine[10]
	},
	
	menu: {
		marginTop: 8.3
	},

	menuBar: {
		display: globalStyle.desktopDisplay,
		height: 40, 
		alignItems: "center",
		paddingLeft: 2,
		paddingRight: 2,
	},

	mobileMenuBar: {
		display: globalStyle.mobileDisplay,
		height: 40, 
		alignItems: "center",
		paddingLeft: 2,
		paddingRight: 2,
		
	},

	menuBarBtn: {
		color: globalStyle.blackColor,
		fontSize: "12px",

		'&:hover': {
			background: globalStyle.aquamarine[1]
		},
	},

	mainContent: {
		height: "85vh",
		// height: window.outerHeight - (window.outerHeight - window.innerHeight + 320),
		overflowY: "auto",
		overflowX: "hidden",

		paddingTop: 1,
		paddingBottom: 1,
		paddingLeft: 1,
		paddingRight: 1
	},

}


export default mainStyle  