import globalStyle from "../../../styles/globalStyle"

const dashboardStyle = {
	date: {
		width: 90,
		fontFamily: "Arial, Helvetica, sans-serif",
		fontSize: "95%",
		paddingBottom: 0.3
	},

	RecordTitle: {
		fontFamily: "Arial, Helvetica, sans-serif",
		fontSize: "95%",
		paddingBottom: 0.3,
		cursor: "pointer",

		color: globalStyle.blackColor,
		'&:hover': {
			color: globalStyle.aquamarine[14],
		},
	},

	stage: {
		fontFamily: "Arial, Helvetica, sans-serif",
		fontSize: "95%",
		paddingLeft: 1,
		color: globalStyle.dodgerBlueColor[10],
	}

}


export default dashboardStyle  