import globalStyle from "../../../styles/globalStyle"

const loadingStyle = {
	loading: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: 'center',
		paddingTop: 2,
		paddingTop: 2,
	}
}


export default loadingStyle  