const initalState = null
const initalStateArray = []

const newUploadAttachmentReducer = (state = initalStateArray, action) => {
	const {type, data} = action

	switch (type) {
		case "NEW_UPLOAD_ATTACHMENT":
			return data
		
		default:
			return state
	}
}


const existUploadAttachmentReducer = (state = initalStateArray, action) => {
	const {type, data} = action

	switch (type) {
		case "EXIST_UPLOAD_ATTACHMENT":
			return data
		
		default:
			return state
	}
}


export {
	newUploadAttachmentReducer,
	existUploadAttachmentReducer,
}