import axios from "axios";

const getRoomListDetails = async () => {
  const roombook_api = "/roombook";

  try {
    let headers = {
      Authorization:
        localStorage.getItem("TOKEN_TYPE") +
        " " +
        localStorage.getItem("ACCESS_TOKEN"),
      // "content-type": "multipart/form-data",
    };

    const result = await axios({
      headers: headers,
      method: "get",
      url: process.env.REACT_APP_ROOMBOOKING_API_URL + roombook_api,
    });

    return result.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`;
    } else {
      throw new Error(error);
    }
  }
};

const getRoomOccupiedStatus = async (params) => {
  const roombook_api = "/roombook/status?";

  try {
    let headers = {
      Authorization:
        localStorage.getItem("TOKEN_TYPE") +
        " " +
        localStorage.getItem("ACCESS_TOKEN"),
      // "content-type": "multipart/form-data",
    };

    const result = await axios({
      headers: headers,
      method: "get",
      url:
        process.env.REACT_APP_ROOMBOOKING_API_URL +
        roombook_api +
        `roombook_seq=${params.roombook_seq}&book_date=${params.book_date}`,
    });

    return result.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`;
    } else {
      throw new Error(error);
    }
  }
};

const insertRoomBookingRecord = async (params) => {
  const roombook_api = "/roombook";

  try {
    let headers = {
      Authorization:
        localStorage.getItem("TOKEN_TYPE") +
        " " +
        localStorage.getItem("ACCESS_TOKEN"),
      // "content-type": "multipart/form-data",
    };

    const result = await axios({
      headers: headers,
      method: "post",
      url: process.env.REACT_APP_ROOMBOOKING_API_URL + roombook_api,
      data: params,
    });

    return result.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`;
    } else {
      throw new Error(error);
    }
  }
};
const deleteBookedRoom = async (params) => {
  const roombook_api = "/roombook";

  try {
    let headers = {
      Authorization:
        localStorage.getItem("TOKEN_TYPE") +
        " " +
        localStorage.getItem("ACCESS_TOKEN"),
      // "content-type": "multipart/form-data",
    };

    const result = await axios({
      headers: headers,
      method: "delete",
      url: process.env.REACT_APP_ROOMBOOKING_API_URL + roombook_api,
      data: params,
    });

    return result.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`;
    } else {
      throw new Error(error);
    }
  }
};

export {
  getRoomListDetails,
  getRoomOccupiedStatus,
  insertRoomBookingRecord,
  deleteBookedRoom,
};
