import React, {useState, useMemo, Fragment} from "react"
import {useDropzone} from "react-dropzone"

// Custom Component
// import {Alert} from '../Alert/Alert'

// Custom Style
import globalStyle from "../../styles/globalStyle"
// import {defaultContentFont, defaultContentHeaderFont, defaultMainHeaderFont, whiteColor, grayColor, blueColor, nightOrangeColor, oceanBlueColor, dodgerBlueColor} from '../../styles/jss/mainStyle'

const FileDropzone = props => {
	const {acceptFileType, maxFiles, DropzoneContent, enablePreview, enableUpload, enableDownload, enableDelete, onAcceptedAction, onRejectedAction, onPreviewAction, onDownloadAction, onRemoveAction} = props

	const baseStyle = {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		borderWidth: 2,
		borderRadius: 2,
		borderColor: globalStyle.whiteColor,
		borderStyle: "solid",
		backgroundColor: "#FFFFFF",
		color: "#000000",
		outline: "none",
		transition: "border .24s ease-in-out",
		// minHeight: theme.spacing(30)
	}

	const activeStyle = {
		borderColor: "blue",
	}

	const {
		fileRejections,
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
		open,
	} = useDropzone({
		accept: acceptFileType,
		maxFiles: maxFiles,
		maxSize: 524288000,
		noClick: true,
		disabled: !enableUpload,

		onDropAccepted: acceptedFiles => {
			onAcceptedAction(acceptedFiles)
		},

		onDropRejected: rejectedFiles => {
			onRejectedAction(rejectedFiles)
		}
	})

	const acceptStyle = {
		borderColor: "#00e676",
	}

	const rejectStyle = {
		borderColor: "#ff1744",
	}

	const style = useMemo(() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),

		[isDragActive, isDragReject, isDragAccept]
	)


	// fileRejections.map(({file, errors}) => {


	// 	// console.log(errors)

	// 	// if (error) {
			
	// 		buildAlert({
	// 			// Alert Type: success, info, warning, error
	// 			type: "success",
	// 			title: "Success Alert",
	// 			content: errors[0].code + " > " + errors[0].message
	// 		})

	// 	// }


		


	// 	// <li key={file.path}>
	// 	//   {file.path} - {file.size} bytes
	// 	//   <ul>
	// 	// 	{errors.map(e => (
	// 	// 	  <li key={e.code}>{e.message}</li>
	// 	// 	))}
	// 	//   </ul>
	// 	// </li>
	// 	})
	



	return (
		<Fragment>
			{/* <div>
				<Alert
					open={alertOpen}
					alertType={alertData.type}
					alertTitle={alertData.title}
					alertContent={alertData.content}
					handleClose={() => setAlertOpen(false)}
				/>
			</div> */}

			<div className="container">
				<div {...getRootProps({style})}>
					<input {...getInputProps()} />
					<DropzoneContent
						open={open}

						enablePreview={enablePreview}
						enableUpload={enableUpload}
						enableDownload={enableDownload}
						enableDelete={enableDelete}
						
						onPreviewAction={onPreviewAction}
						onDownloadAction={onDownloadAction}
						onRemoveAction={onRemoveAction}
					/>
				</div>
			</div>
		</Fragment>
	)
}

export default FileDropzone