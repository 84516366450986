import axios from "axios"

// const datetime = separator => {
// 	const today = new Date()

// 	const date = today.getDate() + '-' + (today.getMonth()+1) + '-' + today.getFullYear()
// 	const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
	
// 	return date + separator + time
// }


const getNoticeList = async () => {
	const getNoticeList_api = "/notice/list"
	let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		// params.append("user_seq", localStorage.getItem("USER_SEQ"))

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + getNoticeList_api,
			params: params,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


const getLatestNoticeList = async () => {
	const getLatestNoticeList_api = "/notice/latest"

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + getLatestNoticeList_api,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


const getMyNoticeList = async () => {
	const getMyNoticeList_api = "/notice/mylist"
	let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + getMyNoticeList_api,
			params: params,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


const getNoticeUser = async noticeSeq => {
	const getMyNoticeUser_api = "/notice/recipients"
	let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		params.append("notice_seq", noticeSeq)

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + getMyNoticeUser_api,
			params: params,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


const getNoticeDetail = async noticeSeq => {
	const getNoticeDetail_api = "/notice/details"
	let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		params.append("noticeSeq", noticeSeq)

		const result = await axios({
			headers: headers,
			method: "get",
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + getNoticeDetail_api,
			params: params,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


const setNoticeRead = async params => {
	const setNoticeRead_api = "/notice/read"

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		const result = await axios({
			headers: headers,
			method: "post",
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + setNoticeRead_api,
			data: params,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


const createNotice = async (noticeContent, newFiles, existFiles) => {
	const createNotice_api = "/notice"
	const formData = new FormData()

	noticeContent.files = existFiles
	formData.append("json", JSON.stringify(noticeContent))

	if (newFiles) {
		newFiles.map(file => {
			formData.append("files", file)
		})
	}

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
			"content-type": "multipart/form-data",
		}

		const result = await axios({
			headers: headers,
			method: "post",
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + createNotice_api,
			data: formData
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}



const saveDraftNotice = async (noticeContent, files) => {
	const saveDraftNotice_api = "/notice/draft"
	const formData = new FormData()

	formData.append("json", JSON.stringify(noticeContent))

	if (files) {
		files.map(file => {
			formData.append("files", file)
		})
	}

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
			"content-type": "multipart/form-data",
		}

		const result = await axios({
			headers: headers,
			method: "post",
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + saveDraftNotice_api,
			data: formData
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}



const updateNotice = async (noticeSeq, noticeContent, newFiles, existFiles) => {
	const updateNotice_api = "/notice"
	let params = new URLSearchParams()
	const formData = new FormData()

	params.append("noticeSeq", noticeSeq)
	noticeContent.files = existFiles
	formData.append("json", JSON.stringify(noticeContent))

	if (newFiles) {
		newFiles.map(file => {
			formData.append("files", file)
		})
	}

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
			"content-type": "multipart/form-data",
		}

		const result = await axios({
			headers: headers,
			method: "put",
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + updateNotice_api,
			params: params,
			data: formData
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


const removeNotice = async noticeSeq => {
	const removeNotice_api = "/notice"
	let params = new URLSearchParams()

	params.append("notice_seq", noticeSeq)

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}

		const result = await axios({
			headers: headers,
			method: "delete",
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + removeNotice_api,
			params: params,
		})

		return result.data
	}
	catch (error) {
		try {
			if (error.response.status === 401) {
				window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
			}
			else {
				throw new Error(error)
			}
		}
		catch(error) {
			throw new Error(error)
		}
	}
}


const attachmentDownload = async (atthSeq) => {
	const attachmentDownload_api = "/notice/attachment/download"
	let params = new URLSearchParams()

	try {
		let headers = {
			"Authorization": localStorage.getItem("TOKEN_TYPE") + " " + localStorage.getItem("ACCESS_TOKEN"),
		}
		
		params.append("atth_seq", atthSeq)

		const response = await axios({
			method: "get",
			// url: parameter,
			url: process.env.REACT_APP_NOTICEBOARD_API_URL + attachmentDownload_api,
			headers: headers,
			params: params,
			responseType: "blob",
		})

		const blob = new Blob([response.data], { type: response.data.type })
		const downloadUrl = window.URL.createObjectURL(blob)
		const link = document.createElement("a")
		link.href = downloadUrl
		const header_filename = response.headers["download-filename"]
		let filename
		if (header_filename) {
			filename = header_filename
		}
		link.setAttribute("download", filename)
		document.body.appendChild(link)
		link.click()
		link.remove()
	}
	catch (error) {
		throw new Error(error)
	}
}



export {
	getNoticeList,
	getLatestNoticeList,
	getMyNoticeList,
	getNoticeUser,
	getNoticeDetail,
	setNoticeRead,

	createNotice,
	saveDraftNotice,
	updateNotice,
	removeNotice,

	attachmentDownload
}