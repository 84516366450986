import React, {Fragment, useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {format, parseISO} from "date-fns"
import PropTypes from 'prop-types'

// Material UI
import {styled, alpha} from "@mui/material/styles"
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Pagination from '@mui/material/Pagination'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'

// Material utils
import {visuallyHidden} from '@mui/utils'

// Custom Icon
import noticeBoardIcon from "../../img/icon-noticeboard.png"

// Custom Component
import FullScreenDialog from "../Dialog/FullScreenDialog"
import NoticeDetail from "./NoticeDetail"
import UseLoading from "../Loading/UseLoading"

// Custom Style
import globalStyle from "../../styles/globalStyle"
import myNoticeListStyle from './styles/myNoticeListStyle'

// Fetch Data
import {getMyNoticeList} from "../../data_source/noticeboard"


// Define Custom TableCell Style
const StyledTableCell = styled(TableCell)(({theme}) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: globalStyle.dodgerBlueColor[8],
		color: theme.palette.common.white,
		fontSize: 16,
		fontWeight: "bold"
	},

	[`&.${tableCellClasses.body}`]: {
	  fontSize: 14
	}
}))

// Define Custom TableRow Style
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: alpha(globalStyle.dodgerBlueColor[0], 0.5)
	},
  
	"td, th": {
		border: 0,
		cursor: "pointer"
	}
}))


const MyNoticeList = () => {
	// Define Loading
	const [getMyNoticeListLoading, getMyNoticeListShowLoading, getMyNoticeListHiddenLoading] = UseLoading()
	const dispatch = useDispatch()

	// Define State
	const [noticeList, setNoticeList] = useState([])
	const [noticeSeq, setNoticeSeq] = useState("")
	const [detailNoticeDialogOpen, setDetailNoticeDialogOpen] = useState(false)
	const [refresh, setRefresh] = useState("")

	// Define MUI Table State
	const [order, setOrder] = useState('desc')   // asc, desc
	const [orderBy, setOrderBy] = useState('date')
	const [page, setPage] = useState(1)
	const [rowsPerPage, setRowsPerPage] = useState(20)

	// Table Title
	const title = "My Notice"

	const createData = (id, subject, date, sendToUsers, readStatus, status) => {
		return {id, subject, date, sendToUsers, readStatus, status}
	}

	const headCells = [
		{
			id: "subject",
			numeric: false,
			disablePadding: true,
			label: "Subject",
		},
		{
			id: 'date',
			numeric: false,
			disablePadding: false,
			label: 'Date',
		},
	
		{
			id: 'sendToUsers',
			numeric: false,
			disablePadding: false,
			label: 'Send To Users',
		},
		{
			id: 'readStatus',
			numeric: false,
			disablePadding: false,
			label: 'Read Status',
		}
	]


	// Table Toolbar
	const EnhancedTableToolbar = props => {
		return (
			<Fragment>
				<Toolbar>
					<Box sx={{flexGrow: 1}}>
						<Typography	variant="body1"	sx={myNoticeListStyle.title}>
							<Box component='span' sx={myNoticeListStyle.titleImage}>
								<img src={noticeBoardIcon} height="28" width="28"/>
							</Box>
							{title}
						</Typography>
					</Box>
				</Toolbar>
			</Fragment>
		)
	}

	// Table Header
	const EnhancedTableHead = props => {
		const {order, orderBy, onRequestSort} = props
		const createSortHandler = property => event => {
			onRequestSort(event, property)
		}
	  
		return (
			<TableHead>
				<TableRow>
					{headCells.map(headCell => (
						<StyledTableCell
							key={headCell.id}
							align={headCell.numeric ? 'right' : 'left'}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</Box>
								) : null}
							</TableSortLabel>
						</StyledTableCell>
					))}
				</TableRow>
			</TableHead>
		)
	}

	EnhancedTableHead.propTypes = {
		order: PropTypes.oneOf(['asc', 'desc']).isRequired,
		orderBy: PropTypes.string.isRequired,
		onRequestSort: PropTypes.func.isRequired
	}

	const descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}

		if (b[orderBy] > a[orderBy]) {
			return 1
		}

		return 0
	}
	
	const getComparator = (order, orderBy) => {
		return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
	}

	const sortRequestHandle = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}
  
	const pageChangeHandle = (event, newPage) => {
		setPage(newPage);
	}


	// Handle Dialog Open and Close
	const noticeDetailDialogHandleOpen = () => {
		setDetailNoticeDialogOpen(true);
	}
	
	const noticeDetailDialogHandleClose = () => {
		setDetailNoticeDialogOpen(false)

		dispatch({
			type: "NEW_UPLOAD_ATTACHMENT",
			data: []
		})

		dispatch({
			type: "EXIST_UPLOAD_ATTACHMENT",
			data: []
		})
	}


	const selectedHandleClick = (event, id) => {
		setNoticeSeq(id)
		noticeDetailDialogHandleOpen()
	}



	// Define Full Screen Dialog Button
	const detailNoticeDialogBtn = []



	useEffect(() => {
		getMyNoticeListShowLoading()
		getMyNoticeList().then(lists => {
			let createDataList = []

			lists.data.map(list => {
				createDataList = [...createDataList, createData(list.notice_seq, list.subject, format(parseISO(list.createdate), "yyyy-MM-dd"), list.toAll ? "ALL" : "List", list.requestRead ? "LIST" : "--", list.status)]
			})

			setNoticeList(createDataList)
			getMyNoticeListHiddenLoading()
		})
	}, [refresh])
	
	return (
		// console.log("--------------------------------------------------------"),
		// console.log("[MyNoticeList.js]"),
		// console.log("noticeList : ", noticeList),
		// console.log("--------------------------------------------------------"),

		<Fragment>
			<div>
				<FullScreenDialog
					open={detailNoticeDialogOpen}
					dialogTitle={"My Notice Detail"}
					formContent={
						<NoticeDetail
							type={"MY_NOTICE"}
							noticeSeq={noticeSeq}
							edit={true}
							remove={true}
							detailScreenClose={noticeDetailDialogHandleClose}
							setRefresh={setRefresh}
						/>
					}
					button={detailNoticeDialogBtn}
					handleClose={noticeDetailDialogHandleClose}
				/>
			</div>

			<Box>
				<EnhancedTableToolbar />
		  		<TableContainer sx={myNoticeListStyle.container}>
					<Table
						aria-labelledby="tableTitle"
						size={"small"}
					>
						<colgroup>
							<col style={{width:'60%'}}/>
							<col style={{width:'15%'}}/>
							<col style={{width:'15%'}}/>
							<col style={{width:'10%'}}/>
							
						</colgroup>
				
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={sortRequestHandle}
						/>

						<TableBody>
						{getMyNoticeListLoading}
						{
							noticeList.slice().sort(getComparator(order, orderBy)).slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row, index) => {
								return (
									<StyledTableRow
										hover
										key={index}
										onClick={(event) => selectedHandleClick(event, row.id)}
									>
										<StyledTableCell>{row.status === "DRAFT" ? "(DRAFT)" : ""} {row.subject}</StyledTableCell>
										<StyledTableCell align="left">{row.date}</StyledTableCell>
										<StyledTableCell align="left">{row.sendToUsers}</StyledTableCell>
										<StyledTableCell align="left">{row.readStatus}</StyledTableCell>
									</StyledTableRow>
								)
							})
						}
						</TableBody>
					</Table>
		  		</TableContainer>
		
				<Pagination count={Math.ceil(noticeList.length / rowsPerPage)} variant="outlined" shape="rounded" onChange={pageChangeHandle} page={page} sx={myNoticeListStyle.pagination} />
			</Box>
		</Fragment>
	)

}

export default MyNoticeList
