import globalStyle from "../../../styles/globalStyle"

const fileChipStyle = {
	attachmentLabel: {
		width: "100%",
		border: '1px solid',
		borderColor: globalStyle.grayColor[2],
		borderRadius: 1,
		background:	"linear-gradient(45deg, " + globalStyle.whiteColor + ", " + globalStyle.oceanBlueColor[2] + ")",

		
	},

	fileTypeImage: {
		width: "32px",
		height: "32px"
	},

	attachmentNameText: {
		fontSize: 14,
		fontWeight: 400,
		color: globalStyle.oceanBlueColor[12],
		// marginRight: theme.spacing(5),
	},

	attachmentSizeText: {
		fontSize: 12,
		color: globalStyle.grayColor[8],
		// marginRight: theme.spacing(0),
	},

	attachmentLabelBtn: {
		'&:hover': {
			color: globalStyle.blueColor[10]
		},
	}

	
}

export default fileChipStyle


