import globalStyle from "../../../styles/globalStyle";

const forgotPasswordStyle = {
  inputBoxes: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 300,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },

  formTitle: {
    color: globalStyle.aquamarine[10],
  },

  btn: {
    background: globalStyle.aquamarine[12],

    "&:hover": {
      background: globalStyle.aquamarine[10],
    },
  },
};

export default forgotPasswordStyle;
