import React, { useState } from "react";
import {
  AppBar,
  Box,
  Stack,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  Tooltip,
  Fab,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ScheduleForm from "./ScheduleForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BookingSchedule = ({ setFlag }) => {
  const matches = useMediaQuery("(max-width:600px)");
  const [scheduleOpen, setScheduleOpen] = useState(false);

  const handleOpen = () => {
    setScheduleOpen(true);
  };
  const handleClose = () => {
    setScheduleOpen(false);
  };

  const fabSize = matches ? "medium" : "large";
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={(theme) => ({
        position: "fixed",
        bottom: "20px",
        right: "25px",
        zIndex: 20,
      })}
    >
      <Tooltip title="Schedule Booking" placement="top">
        <Fab
          onClick={handleOpen}
          sx={{
            backgroundColor: "#20cec0",
            color: "#fff",
            ":hover": {
              color: "#20cec0",
            },
          }}
          size={fabSize}
        >
          <DateRangeIcon />
        </Fab>
      </Tooltip>
      {scheduleOpen && (
        <Dialog
          fullScreen
          open={true}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative", backgroundColor: "#20cec0" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Schedule Booking
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ p: 3, overflow: "auto" }}>
            <ScheduleForm setFlag={setFlag} handleClose={handleClose} />
          </Box>
        </Dialog>
      )}
    </Stack>
  );
};

export default BookingSchedule;
