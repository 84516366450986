import React, {Fragment, useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import PropTypes from 'prop-types'

// MUI
import {styled, alpha} from "@mui/material/styles"
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import Pagination from '@mui/material/Pagination'

// MUI utils
import {visuallyHidden} from '@mui/utils'

// MUI Icon
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

// Custom Icon
import noticeBoardIcon from "../../img/icon-noticeboard.png"

// Custom Component
import FullScreenDialog from "../Dialog/FullScreenDialog"
import UseLoading from "../Loading/UseLoading"
import MyListForm from "../Users/MyListForm"

// Custom Style
import globalStyle from "../../styles/globalStyle"
import myListListStyle from './styles/myListListStyle'

// Fetch Data
import {getMyList} from "../../data_source/users"


// Define Custom TableCell Style
const StyledTableCell = styled(TableCell)(({theme}) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: globalStyle.dodgerBlueColor[8],
		color: theme.palette.common.white,
		fontSize: 16,
		fontWeight: "bold"
	},

	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	}
}))

// Define Custom TableRow Style
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: alpha(globalStyle.dodgerBlueColor[0], 0.5)
	},
  
	"td, th": {
		border: 0,
		cursor: "pointer"
	}
}))


const MyListList = () => {
	const dispatch = useDispatch()

	// Define Loading
	const [getMyListLoading, getMyListShowLoading, getMyListHiddenLoading] = UseLoading()

	// Define State
	const [myList, setMyList] = useState([])
	const [tableMyList, setTableMyList] = useState([])
	const [selectedListSeq, setSelectedListSeq] = useState("")
	const [selectedListName, setSelectedListName] = useState("")
	const [refresh, setRefresh] = useState("")

	// Dinfine State for MUI Table
	const [order, setOrder] = useState('desc')   // asc, desc
	const [orderBy, setOrderBy] = useState('date')
	const [page, setPage] = useState(1)
	const [rowsPerPage, setRowsPerPage] = useState(20)

	// Defien Dialog open and close
	const [myListDetailDialogOpen, setMyListDetailDialogOpen] = useState(false)


	// Table Title
	const title = "My List"

	const createData = (id, listName, numOfUsers) => {
		return {id, listName, numOfUsers}
	}

	const headCells = [
		{
			id: "listName",
			numeric: false,
			disablePadding: true,
			label: "List Name",
		},
		{
			id: 'NumofUsers',
			numeric: false,
			disablePadding: false,
			label: 'Number of Users',
		},
	]

	// Table Toolbar
	const EnhancedTableToolbar = props => {
		return (
			<Fragment>
				<Toolbar>
					<Box sx={{flexGrow: 1}}>
						<Typography	variant="body1"	sx={myListListStyle.title}>
							<Box component='span' sx={myListListStyle.titleImage}>
								<img src={noticeBoardIcon} height="28" width="28"/>	
							</Box>
							{title}
						</Typography>
					</Box>
				</Toolbar>
			</Fragment>
		)
	}

	// Table Header
	const EnhancedTableHead = props => {
		const {order, orderBy, onRequestSort} = props
		const createSortHandler = property => event => {
			onRequestSort(event, property)
		}
	  
		return (
			<TableHead>
				<TableRow>
					{headCells.map(headCell => (
						<StyledTableCell
							key={headCell.id}
							align={headCell.numeric ? 'right' : 'left'}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</Box>
								) : null}
							</TableSortLabel>
						</StyledTableCell>
					))}
				</TableRow>
			</TableHead>
		)
	}

	EnhancedTableHead.propTypes = {
		order: PropTypes.oneOf(['asc', 'desc']).isRequired,
		orderBy: PropTypes.string.isRequired,
		onRequestSort: PropTypes.func.isRequired
	}


	const descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}

		if (b[orderBy] > a[orderBy]) {
			return 1
		}

		return 0
	}
	
	const getComparator = (order, orderBy) => {
		return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
	}

	const sortRequestHandle = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const pageChangeHandle = (event, newPage) => {
		setPage(newPage);
	}


	const selectedHandleClick = (event, id) => {
		let name = ""
		
		myList.map(res => {
			if (res.list_seq === id) {
				name = res.name
			}
		})

		setSelectedListSeq(id)
		setSelectedListName(name)

		myListDetailDialogHandleOpen()
	}


	// Handle Dialog Open and Close
	const myListDetailDialogHandleOpen = () => {
		setMyListDetailDialogOpen(true)
	}
	
	const myListDetailDialogHandleClose = () => {
		dispatch({
			type: "RECIPIENT_USER_RECORD",
			data: []
		})

		dispatch({
			type: "RECIPIENT_USER_SEQ",
			data: []
		})

		setMyListDetailDialogOpen(false)
	}


	// Define Full Screen Dialog Button
	const myListDetailDialogBtn = []


	useEffect(() => {
		getMyListShowLoading()
		getMyList().then(lists => {
			let createDataList = []

			lists.data.map(list => {
				createDataList = [...createDataList, createData(list.list_seq, list.name, list.totalStaffCount)]
			})

			setMyList(lists.data)
			setTableMyList(createDataList)
			getMyListHiddenLoading()
		})
	}, [refresh])

	return (

		// console.log("--------------------------------------------------------"),
		// console.log("[MyListList.js]"),
		// console.log("myList : ", myList),
		// console.log("tableMyList : ", tableMyList),
		// console.log("selectedListSeq : ", selectedListSeq),
		// console.log("selectedListName : ", selectedListName),
		// console.log("--------------------------------------------------------"),
		
		<Fragment>
			<div>
				<FullScreenDialog
					open={myListDetailDialogOpen}
					dialogTitle={"My List Detail"}
					formContent={
						<MyListForm
							selectedListSeq={selectedListSeq}
							selectedMyListName={selectedListName}
							myListDetaildialogClose={myListDetailDialogHandleClose}
							setRefresh={setRefresh}
						/>
					}
					button={myListDetailDialogBtn}
					handleClose={myListDetailDialogHandleClose}
				/>
			</div>


			<Box>
				<EnhancedTableToolbar />
		  		<TableContainer sx={myListListStyle.container}>
					<Table
						aria-labelledby="tableTitle"
						size={"small"}
					>
						<colgroup>
							<col style={{width:'80%'}}/>
							<col style={{width:'20%'}}/>
						</colgroup>
				
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={sortRequestHandle}
						/>

						<TableBody>
						{getMyListLoading}
						{
							tableMyList.slice().sort(getComparator(order, orderBy)).slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row, index) => {
								return (
									<StyledTableRow
										hover
										key={index}
										onClick={(event) => selectedHandleClick(event, row.id)}
									>
										<StyledTableCell align="left">{row.listName}</StyledTableCell>
										<StyledTableCell align="left">{row.numOfUsers}</StyledTableCell>
									</StyledTableRow>
								)
							})
						}
						</TableBody>
					</Table>
		  		</TableContainer>
		
				<Pagination count={Math.ceil(tableMyList.length / rowsPerPage)} variant="outlined" shape="rounded" onChange={pageChangeHandle} page={page} sx={myListListStyle.pagination} />
			</Box>
		</Fragment>
	)
}

export default MyListList
