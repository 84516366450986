import globalStyle from "../../../styles/globalStyle"

const informationListStyle = {
	title: {
		display: "flex",
		alignItems: "center",
		color: globalStyle.grayColor[10],
		fontSize: 20,
	},

	titleImage: {
		display: "flex",
		alignItems: "center",
		paddingRight: 1
	},

	container: {
		height: "73vh"
	},

	icon: {
		background: globalStyle.oceanBlueColor[8],

		'&:hover': {
			backgroundColor: globalStyle.oceanBlueColor[12],
		},
	},

	pagination: {
		paddingTop: 1,
		paddingBottom: 1,
		paddingLeft: 1,
		paddingRight: 1
	},


	toolbarBtn: {
		color: globalStyle.aquamarine[13],
		'&:hover': {
			color: globalStyle.aquamarine[11],
		},

		cursor: "pointer"
	},

	noRecordMsg: {
		width: "100%",

		fontSize: 18,
		fontWeight: 700,
		fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',

		background: 'yellow',
		// display: "flex",
		// justifyContent: "center"
		textAlign: 'center'
	},


	tableRow: {
		cursor: "pointer"
	},


	infoType: {
		color: globalStyle.oceanBlueColor[8],
		fontSize: "large"
	}



}


export default informationListStyle  