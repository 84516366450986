import React, {Fragment, useState} from "react"

// Material-UI Core
import {useTheme} from "@mui/material/styles"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Dialog from "@mui/material/Dialog"
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import useMediaQuery from "@mui/material/useMediaQuery"

// Material-UI Icon
import CloseIcon from "@mui/icons-material/Close"

// Custom Style
import globalStyle from "../../styles/globalStyle"
import normalDialogStyle from './styles/normalDialogStyle'


const NormalDialog = props => {
	const {open, dialogTitle, formContent, size, height, button, handleClose} = props
	
	// Define Statue	
	const [fullWidth, setFullWidth] = useState(true)
	const [maxWidth, setMaxWidth] = useState(size)

	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

	return (
		<Fragment>
			<Dialog
				aria-labelledby="search-dialog"
				fullScreen={fullScreen}
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={open}
				onClose={handleClose}
				disableEscapeKeyDown={true}
			>
				<DialogTitle id="search-dialog-title" sx={normalDialogStyle.headerFooter}>
					{dialogTitle}
					<IconButton aria-label="close" sx={{position: 'absolute', right: 20, top: 15, color: globalStyle.whiteColor}} onClick={handleClose}>
	 					<CloseIcon />
	 				</IconButton>
				</DialogTitle>

				<DialogContent dividers >
					<DialogContentText component={"div"}>
						<div style={{"height": height}}>
							{formContent}
						</div>
					</DialogContentText>
				</DialogContent>

				<DialogActions sx={normalDialogStyle.headerFooter}>
					{
						button.map((x, key) => {
							return (
								<Button component="span" color="inherit" variant="outlined" size="small" key={key} onClick={x.buttonFunction != null ? x.buttonFunction : null} sx={normalDialogStyle.searchBtn}>
									{x.buttonName}
								</Button>
							)
						})
					}
				</DialogActions>
			</Dialog>
		</Fragment>
	)
}


export default NormalDialog