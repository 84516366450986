import React, { useState } from "react";
import RoomBookingContext from "./RoomBookingContext";

const RoomBookingProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [availableRoom, setAvailableRoom] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [timePeriodConfig, setTimePeriodConfig] = useState([]);

  return (
    <RoomBookingContext.Provider
      value={{
        bookingDialog: { dialogOpen, setDialogOpen },
        bookingRoom: { selectedRoom, setSelectedRoom },
        bookingAll: { availableRoom, setAvailableRoom },
        timePeriod: { timePeriodConfig, setTimePeriodConfig },
      }}
    >
      {children}
    </RoomBookingContext.Provider>
  );
};

export default RoomBookingProvider;
