import React, {useState, useEffect, Fragment} from 'react'
import {useDispatch, useSelector} from "react-redux"

// Material-UI Core
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'

// Material-UI Icon
import FolderIcon from '@mui/icons-material/Folder'

// Custom Component
import FileChip from './FileChip'

// Custom Style
import dropzoneContentStyle from "./styles/dropzoneContentStyle"


const DropzoneContent = props => {
	const {open, enablePreview, enableUpload, enableDownload, enableDelete, onPreviewAction, onDownloadAction, onRemoveAction} = props
	const dispatch = useDispatch()
	
	// Redux Data
	const newUploadAttachmentRedux = useSelector(state => state.newUploadAttachmentRedux)
	const existUploadAttachmentRedux = useSelector(state => state.existUploadAttachmentRedux)

	useEffect(() => {
	})


	return (

		// console.log("---------------------------------------------------------------------------------"),
		// console.log("DropzoneContent"),
		// console.log("newUploadAttachmentRedux : ", newUploadAttachmentRedux),
		// console.log("existUploadAttachmentRedux : ", existUploadAttachmentRedux),
		// console.log("---------------------------------------------------------------------------------"),

		<Fragment>
			<Box sx={dropzoneContentStyle.dropzoneArea}>
				<Grid container spacing={1}>
					{
						existUploadAttachmentRedux !== null ?
							existUploadAttachmentRedux.map((file, index) => {
								return (
									<Grid item xs={12} sm={12} lg={6}>
										<FileChip 
											key={index}
											filename={file.filename}
											fileSize={file.file_size}
											fileExtension={file.extension}
											
											enablePreview={enablePreview}
											enableDownload={enableDownload}
											enableDelete={enableDelete}

											onPreviewAction={onPreviewAction}
											onDownloadAction={enableDownload ? onDownloadAction(file.atth_seq) : null}
											onRemoveAction={enableDelete ? onRemoveAction(file) : null}
										/>
									</Grid>
								)
							})
						: ""
					}

					{
						newUploadAttachmentRedux !== null ?
							newUploadAttachmentRedux.map((file, index) => {
								return (
									<Grid item xs={12} sm={12} lg={6}>
										<FileChip 
											key={index}
											filename={file.name}
											fileSize={file.size}
											fileExtension={file.name.split('.').pop()}
											
											enablePreview={enablePreview}
											enableDownload={enableDownload}
											enableDelete={enableDelete}

											onPreviewAction={onPreviewAction}
											onDownloadAction={enableDownload ? onDownloadAction(file.atth_seq) : null}
											onRemoveAction={enableDelete ? onRemoveAction(file) : null}
										/>
									</Grid>
								)
							})
						: ""
					}
				</Grid>

				{
					enableUpload ? 
						<Box sx={dropzoneContentStyle.buttonArea}>
							<Tooltip title="Choose File">
								<Fab color="primary" size="small" aria-label="add_recipient" sx={dropzoneContentStyle.icon} onClick={open}>
									<FolderIcon />
								</Fab>
							</Tooltip>
						</Box>
					: ""
				}
				
			</Box>
		</Fragment>
	)
}

export default DropzoneContent
