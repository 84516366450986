import React, { Fragment } from "react";
import RoomBook from "../../components/RoomBook";

const RoomBookView = () => {
  return (
    <Fragment>
      <RoomBook />
    </Fragment>
  );
};

export default RoomBookView;
