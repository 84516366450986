import React, {Fragment, useEffect} from 'react'

// Custom Component
import isLoggedIn from '../../components/SignIn/isLoggedIn'
import Main from '../../components/Main/Main'


const MainView = () => {

	useEffect(() => {
		if (!isLoggedIn()) {
			window.location.href = `${process.env.REACT_APP_OIDC_LOGIN_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGIN_REDIRECT_URI}`
		}
	}, [])


	return (
		<Fragment>
			<Main />
		</Fragment>
	)
}


export default MainView