import React from "react";
import { Box, Paper, Typography } from "@mui/material";

const SectionItem = ({ header, children }) => {
  return (
    <Paper elevation={5} sx={{ height: "100%" }}>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          height: 28,
          px: 2,
          py: 1,
          borderBottom: "2px solid #b3b0b099",
          textTransform: "capitalize",
          letterSpacing: 2,
        }}
      >
        {header}
      </Typography>
      {children}
    </Paper>
  );
};

export default SectionItem;
