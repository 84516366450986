import React, {Fragment, useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import DateFnsUtils from "@date-io/date-fns"


import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import DocumentEditor from "@ckeditor/ckeditor5-build-decoupled-document"

// MUI
import {withStyles} from '@mui/styles'
import { alpha, styled } from "@mui/material/styles";
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

import Fab from '@mui/material/Fab';



import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Material-UI Element
// import {MuiPickersUtilsProvider, KeyboardDatePicker} from "@material-ui/pickers"

// Material Icon
import AddIcon from '@mui/icons-material/Add'


// Custom Components
import Alert from "../Alert/Alert"
import ConfirmDialog from "../Dialog/ConfirmDialog"
import FullScreenDialog from "../Dialog/FullScreenDialog"
import ckEditorToolbar from "../../config/components/ckeditor"
import FileDropzone from "../FileDropzone/FileDropzone"
import DropzoneContent from "../FileDropzone/DropzoneContent"
// import DropzoneContentEdit from "../FileDropzone/DropzoneContentEdit";
import RecipientForm from "./RecipientForm"


// import ConfirmDialog from "../Dialog/ConfirmDialog"


// Custom Style
import globalStyle from "../../styles/globalStyle"
import noticeEditStyle from './styles/noticeEditStyle'


// Custom Typography
// const FieldTitleTypography = withStyles({
// 	root: {
// 		"fontSize": 16,
// 		// "fontWeight": 600,
// 		"color": globalStyle.grayColor[8],
// 	},
// }) (Typography)


// const RecipientSwitch = styled(Switch)(({ theme }) => ({
// 	"& .MuiSwitch-switchBase": {
// 	  color: globalStyle.greenColor[10]
// 	},
  
// 	"& .MuiSwitch-switchBase + .MuiSwitch-track": {
// 	  backgroundColor: globalStyle.greenColor[10],
// 	},
  

// 	"& .MuiSwitch-switchBase.Mui-checked": {
// 	  color: globalStyle.oceanBlueColor[8],
// 	},

// 	"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
// 	  backgroundColor: globalStyle.oceanBlueColor[8]
// 	},
  
	
//   }))



const NoticeEdit = props => {
	const {noticeDetail} = props
	const dispatch = useDispatch()

	// Define Redux
	const recipientUserSeqRedux = useSelector(state => state.recipientUserSeqRedux)
	const recipientUserRecordRedux = useSelector(state => state.recipientUserRecordRedux)
	const newNoticeContentRedux = useSelector(state => state.newNoticeContentRedux)
	// const noticeboardAttachmentRedux = useSelector(state => state.noticeboardAttachmentRedux)
	const newUploadAttachmentRedux = useSelector(state => state.newUploadAttachmentRedux)
	// const noticeboardEditAttachmentRedux = useSelector(state => state.noticeboardEditAttachmentRedux)
	const existUploadAttachmentRedux = useSelector(state => state.existUploadAttachmentRedux)
	
	// Upload Accept File Type
	// const acceptFileType = ["application/pdf"]

	// CKEditor Config
	const editorConfiguration = {
		toolbar: ckEditorToolbar,
	}


	// Define State
	const [newNoticeContent, setNewNoticeContent] = useState({
		"notice_seq": noticeDetail.notice_seq,
		"subject": noticeDetail.subject,
		"content": noticeDetail.content,
		"emailNotification": noticeDetail.emailNotification,
		"requestRead": noticeDetail.requestRead,
		"requestExpiryDate": noticeDetail.requestExpiryDate,
		"toAll": noticeDetail.toAll,
		"user_seq": noticeDetail.user_seq,
		// "files": noticeDetail.files,
		"status": noticeDetail.status
	})

	// Define state for Dialog Open
	const [recipientDialogOpen, setRecipientDialogOpen] = useState(false)

	// Define Alert Dialog
	const [alertOpen, setAlertOpen] = useState(false)
	const [alertData, setAlertData] = useState({
		type: "",
		title: "",
		content: ""
	})

	// Define Confirm Dialog
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
	const [confirmDialogData, setConfirmDialogData] = useState({
		dialogTitle: "",
		dialogContent: "",
		confirmAction: "",
		cancelAction: ""
	})


	// Build Alert
	const buildAlert = (data) => {
		setAlertData({
			type: data.type,
			title: data.title,
			content: data.content
		})

		setAlertOpen(true)
	}

	const alertHandleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setAlertOpen(false)
	}


	// Build Confirm dialog
	const confirmDialogHandleOpen = (data) => {
		setConfirmDialogData({
			dialogTitle: data.dialogTitle,
			dialogContent: data.dialogContent,
			confirmAction: data.confirmAction,
			cancelAction: data.cancelAction
		})

		setConfirmDialogOpen(true)
	}

	const confirmDialogHandleClose = () => {
		setConfirmDialogOpen(false)
	}
	


	const removeFileConfirmHandleOpen = file => () => {
		confirmDialogHandleOpen({
			dialogTitle: "Remove File Attachment",
			dialogContent: "Are you sure to remove the file.",
			confirmAction: removeFileAction(file),
			cancelAction: removeFileConfirmHandleClose
		})
	}

	const removeFileConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}



	const removeExistFileConfirmHandleOpen = file => {
		confirmDialogHandleOpen({
			dialogTitle: "Remove File Attachment",
			dialogContent: "Are you sure to remove the file.",
			confirmAction: () => removeExistFileAction(file),
			cancelAction: removeExistFileConfirmHandleClose
		})
	}

	const removeExistFileConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}



	// Handle Form Input
	const textFieldHandleChange = event => {
		const {name, value} = event.target
		const res = {...newNoticeContent}
		res[name] = value

		setNewNoticeContent(res)
		dispatch({
			type: "NEW_NOTICE_CONTENT",
			data: res
		})

		// validate({...submissionCreateValue, values: res})
	}

	// CKEditor content change action
	const ckeditorHandleChange = (event, editor) => {
		const inputContent = editor.getData()
		const res = {...newNoticeContent}

		res.content = inputContent
		
		setNewNoticeContent(res)
		dispatch({
			type: "NEW_NOTICE_CONTENT",
			data: res
		})
	}

	// Handle Form Input
	const switchBoxHandleChange = event => {
		const {name, checked} = event.target
		const res = {...newNoticeContent}

		res[name] = checked

		setNewNoticeContent(res)
		dispatch({
			type: "NEW_NOTICE_CONTENT",
			data: res
		})
	}

	const reqReadSwitchBoxHandleChange = event => {
		const {name, checked} = event.target
		const res = {...newNoticeContent}

		res[name] = checked

		if (!checked) {
			res.requestExpiryDate = null
		}

		setNewNoticeContent(res)
		dispatch({
			type: "NEW_NOTICE_CONTENT",
			data: res
		})
	}

	// Handle Form Input
	const dateFieldHandleChange = date => {
		const res = {...newNoticeContent}

		res.requestExpiryDate = !isNaN(Date.parse(date)) ? new Date(date).toISOString() : null

		setNewNoticeContent(res)
		dispatch({
			type: "NEW_NOTICE_CONTENT",
			data: res
		})
	}

	// Handle Form Input
	const selectUserHangleChange = () => {
		const res = {...newNoticeContent}

		res.user_seq = recipientUserSeqRedux
		res.toAll = recipientUserSeqRedux ? recipientUserSeqRedux.length > 0 ? false : true : true

		setNewNoticeContent(res)
		dispatch({
			type: "NEW_NOTICE_CONTENT",
			data: res
		})
	}


	// Handle Dialog open and close
	const recipientDialogHandleOpen = () => {
		setRecipientDialogOpen(true)
	}
	
	const recipientDialogHandleClose = () => {
		setRecipientDialogOpen(false)
	}


	// File Action
	const acceptFileAction = acceptedFiles => {
		const files = [...newUploadAttachmentRedux, ...acceptedFiles]

		dispatch({
			type: "NEW_UPLOAD_ATTACHMENT",
			data: files
		})
	}

	const rejectedFileAction = rejectedFiles => {
		buildAlert({
			// Alert Type: success, info, warning, error
			type: "error",
			title: "Error Alert",
			content: rejectedFiles[0].errors[0].code + " > " + rejectedFiles[0].errors[0].message
		})
	}


	const removeExistFileAction = file => {
		const result = newUploadAttachmentRedux.filter(res => res !== file)
		let res = {...newNoticeContent}
		res.files = result

		setNewNoticeContent(res)
		dispatch({
			type: "NEW_NOTICE_CONTENT",
			data: res
		})

		dispatch({
			type: "EXIST_UPLOAD_ATTACHMENT",
			data: result
		})

		removeExistFileConfirmHandleClose()
	}


	const removeFileAction = file => () => {
		const newAttachmentFile = newUploadAttachmentRedux.filter(res => res !== file)
		const existAttachmentFile = existUploadAttachmentRedux.filter(res => res !== file)

		dispatch({
			type: "NEW_UPLOAD_ATTACHMENT",
			data: newAttachmentFile
		})

		dispatch({
			type: "EXIST_UPLOAD_ATTACHMENT",
			data: existAttachmentFile
		})

		removeFileConfirmHandleClose()
	}

	
	const createData = (id, user_seq, eng_name, chi_name, username, email, detail) => {
		return {id, user_seq, eng_name, chi_name, username, email, detail}
	}


	// Define Dialog Button
	const recipientDialogBtn = []


	useEffect(() => {
		let createDataList = []

		noticeDetail.rcpts.map(list => {
			// createDataList = [...createDataList, createData(list.user_seq, list.fullname_eng, list.fullname_chi, list.sites, list.section_name, list.grade_name, list.position_name_eng)]
			let detailString = ""

				list.employee_details.map((detail, index) => {
					detailString = detailString + `Site:  ${detail.sas_site_name ? detail.sas_site_name : "--"}, Section: ${detail.section_name ? detail.section_name : "--"}, Grade: ${detail.grade_name ? detail.grade_name : "--"}, Position: ${detail.position_name_eng ? detail.position_name_eng : "--"}\n`
				})

				createDataList = [...createDataList, createData(list.user_seq, list.user_seq, list.fullname_eng, list.fullname_chi, list.username, list.email, detailString)]
		})

		dispatch({
			type: "RECIPIENT_USER_RECORD",
			data: createDataList
		})

		dispatch({
			type: "RECIPIENT_USER_SEQ",
			data: noticeDetail.user_seq
		})

		dispatch({
			type: "EXIST_UPLOAD_ATTACHMENT",
			data: noticeDetail.files
		})
	}, [])


	useEffect(() => {
		selectUserHangleChange()
	}, [recipientUserSeqRedux])



	return (
		// console.log("--------------------------------------------------------"),
		// console.log("[editNotice.js]"),
		// console.log("Notice Detail org : ", noticeDetail),
		// console.log("newNoticeContent : ", newNoticeContent),
		// console.log(""),
		// console.log("recipientUserSeqRedux : ", recipientUserSeqRedux),
		// console.log("recipientUserRecordRedux : ", recipientUserRecordRedux),
		// console.log("newNoticeContentRedux : ", newNoticeContentRedux),
		// console.log("newUploadAttachmentRedux : ", newUploadAttachmentRedux),
		// console.log("existUploadAttachmentRedux : ", existUploadAttachmentRedux),
		// console.log("--------------------------------------------------------"),

		<Fragment>
			<div>
				<Alert
					open={alertOpen}
					alertType={alertData.type}
					alertTitle={alertData.title}
					alertContent={alertData.content}
					handleClose={alertHandleClose}
				/>

				<ConfirmDialog
					open={confirmDialogOpen}
					dialogTitle={confirmDialogData.dialogTitle}
					dialogContent={confirmDialogData.dialogContent}
					confirmAction={confirmDialogData.confirmAction}
					cancelAction={confirmDialogData.cancelAction}
				/>

				<FullScreenDialog
					open={recipientDialogOpen}
					dialogTitle={"Recipient"}
					formContent={
						<RecipientForm />
					}
					button={recipientDialogBtn}
					handleClose={recipientDialogHandleClose}
				/>
			</div>


			<Stack direction="column" spacing={2}>

				<TextField 
					id="subject"
					name="subject"
					label="Subject"
					variant="outlined"
					required
					fullWidth={true}
					size="small"
					onChange={textFieldHandleChange}
					value={newNoticeContent ? newNoticeContent.subject : ""}
				/>

				<Box>
					<CKEditor
						editor={ClassicEditor}
						config={editorConfiguration}
						data={newNoticeContent.content || ""}
						onChange={ckeditorHandleChange}

						// onReady={ editor => {
						//     // You can store the "editor" and use when it is needed.
						//     console.log( 'Editor is ready to use!', editor );
						// } }

						// onChange={ ( event, editor ) => {
						//     const data = editor.getData();
						//     console.log( { event, editor, data } );
						// } }

						// onBlur={ ( event, editor ) => {
						//     console.log( 'Blur.', editor );
						// } }

						// onFocus={ ( event, editor ) => {
						//     console.log( 'Focus.', editor );
						// } }
					/>
				</Box>

				<FormControl component="fieldset" variant="standard" sx={noticeEditStyle.fieldset}>
					<FormLabel component="legend">Attachment</FormLabel>
					<FileDropzone
						acceptFileType={process.env.REACT_APP_NOTICEBOARD_ATTACHMENT_TYPE.split(",")}
						maxFiles={10}
						DropzoneContent={DropzoneContent}

						enablePreview={false}
						enableUpload={true}
						enableDownload={false}
						enableDelete={true}

						onAcceptedAction={acceptFileAction}
						onRejectedAction={rejectedFileAction}
						onPreviewAction={null}
						onDownloadAction={null}
						onRemoveAction={removeFileConfirmHandleOpen}
					/>
				</FormControl>

				<FormControl component="fieldset" variant="standard" sx={noticeEditStyle.fieldset}>
					<FormLabel component="legend">Post noticeboard to user</FormLabel>
					<FormGroup row>
						<Stack direction="column" spacing={1}>
							<Box sx={noticeEditStyle.postUserText}>
								{
									recipientUserSeqRedux ? recipientUserSeqRedux.length > 0 ? `Post Notice to ${recipientUserSeqRedux.length} User(s)` : "Post to All Users" : "Post to All Users"
								}
							</Box>

							<Box sx={noticeEditStyle.addUserBtnArea}>
								<Button variant="contained" size="small" onClick={recipientDialogHandleOpen}>Select User</Button>
							</Box>
						</Stack>
					</FormGroup>
				</FormControl>

				<FormControl component="fieldset" variant="standard" sx={noticeEditStyle.fieldset}>
					<FormLabel component="legend">Email to notify</FormLabel>
					<FormGroup row>
						<FormControlLabel label="Yes" sx={noticeEditStyle.label} control={
							<Switch
								name="emailNotification"
								size="small"
								checked={newNoticeContent.emailNotification}
								onChange={switchBoxHandleChange}
							/>
						} />
					</FormGroup>
				</FormControl>

				<FormControl component="fieldset" variant="standard" sx={noticeEditStyle.fieldset}>
					<FormLabel component="legend">Request Read</FormLabel>
					<FormGroup row>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={2} lg={1} sx={noticeEditStyle.labelArea}>
								<FormControlLabel label="Yes" sx={noticeEditStyle.label} control={
									<Switch
										name="requestRead"
										size="small"
										checked={newNoticeContent.requestRead}
										onChange={reqReadSwitchBoxHandleChange}
									/>
								} />
							</Grid>

							{
								newNoticeContent.requestRead ?
									<Grid item xs={12} sm={10} lg={11}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DatePicker
												label="Expiry Date"
												value={newNoticeContent.requestExpiryDate || null}
												inputFormat="dd/MM/yyyy"
												renderInput={(params) => <TextField size="small"  {...params} />}
												onChange={date => dateFieldHandleChange(date)}
											/>
										</LocalizationProvider>
									</Grid>
								: ""
							}
						</Grid>
					</FormGroup>
				</FormControl>

			</Stack>
		</Fragment>
	)
}

export default NoticeEdit
