import React, {Fragment, useEffect} from "react"
import {useNavigate} from "react-router-dom"

// Custom Component
import Signout from './Signout'


const SignoutCallback = props => {
	const navigate = useNavigate()

	const navigateSign = () => {
		navigate({
			pathname: "/",
		})
	}

	useEffect(() => {
		Signout()
		navigateSign()
	}, [])


	return (
		<Fragment></Fragment>
	)
}


export default SignoutCallback
